import React, {useMemo, useContext} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import context from '../../context'
import type {Language} from "../../types";


export const TabPanel = ({children, value, index, ...other}) =>
  <div
    role="tabpanel"
    hidden={value !== index}
    aria-labelledby={`tab-${index}`}
    style={{overflow:'hidden'}}
    {...other}
  >
    {value === index && children}
  </div>;


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function TabPanels({children, tab, tabs, onChange}) {
  const language: Language = useContext(context.language).language;
  const direction = useMemo(()=>(language.rtl ? 'rtl' : 'ltr'), [language]);

  const handleChangeIndex = (index) =>
    onChange(tabs[index]);

  return (
    <SwipeableViews
      axis={direction === 'rtl' ? 'x-reverse' : 'x'}
      index={tab.index}
      onChangeIndex={handleChangeIndex}
    >
      {React.Children.map(children, (child, index)=>
        <TabPanel value={tab.index} index={index} dir={direction}>
          {child}
        </TabPanel>
      )}
    </SwipeableViews>
  );
}