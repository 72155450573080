import React, {useState, useEffect} from "react";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Map from "@material-ui/icons/Map";
import AddLocation from "@material-ui/icons/AddLocation";
import Place from "@material-ui/icons/Place";

// core components
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import CardIcon from "../Card/CardIcon.jsx";
import CardHeader from "../Card/CardHeader.jsx";

import { cardTitle } from "../../assets/jss/material-dashboard-pro-react.jsx";
import {google_maps_key} from '../../variables/variables'
import useCardStyles from '../../assets/jss/useCardStyles'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

export const SatelliteMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={3}
      mapTypeId={"satellite"}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      defaultOptions={{
        scrollwheel: false
      }}
    >
      <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
    </GoogleMap>
  ))
);

export const RegularMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      defaultOptions={{
        scrollwheel: false
      }}
    >
      <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
    </GoogleMap>
  ))
);

export const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={props.defaultCenter}
      defaultOptions={{
        scrollwheel: false,
        disableDefaultUI: true,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
      {...props}
    >
      <Marker position={props.defaultCenter} />
    </GoogleMap>
  ))
);

export const CustomSkinCardMap = ({title, defaultCenter={ lat: 40.748817, lng: -73.985428 }, ...props}) => {
  const classes = useCardStyles();
  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <Place />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          {title}
        </h4>
      </CardHeader>
      <CardBody>
        <CustomSkinMap
          defaultCenter={defaultCenter}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${google_maps_key}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: `280px`,
                borderRadius: "6px",
                overflow: "hidden"
              }}
              {...props}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </CardBody>
    </Card>
  )
};

class GoogleMaps extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Map />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Satellite Map</h4>
            </CardHeader>
            <CardBody>
              <SatelliteMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `280px`,
                      borderRadius: "6px",
                      overflow: "hidden"
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddLocation />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Regular Map</h4>
            </CardHeader>
            <CardBody>
              <RegularMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `280px`,
                      borderRadius: "6px",
                      overflow: "hidden"
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Custom Skin & Settings Map
              </h4>
            </CardHeader>
            <CardBody>
              <CustomSkinMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `280px`,
                      borderRadius: "6px",
                      overflow: "hidden"
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(GoogleMaps);





const MyMapComponent = ({isMarkerShown=true, defaultCenter, ...props}) => {
  const MapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={8}
      defaultCenter={defaultCenter}
    >
      {props.isMarkerShown && <Marker position={defaultCenter} />}
    </GoogleMap>
  ));

  return (
    <MapComponent
      isMarkerShown={defaultCenter}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${google_maps_key}`}
      loadingElement={<div style={{height: `100%`}}/>}
      containerElement={<div style={{height: `400px`}}/>}
      mapElement={<div style={{height: `100%`}}/>}
      {...props}
    />
  )
};

export const MyFancyComponent = ({defaultCenter={ lat: -34.397, lng: 150.644 }}) => {
  const [isMarkerShown, set_isMarkerShown] = useState(true);

  const handleMarkerClick = () => {
    set_isMarkerShown(false);
  };

  return (
    <MyMapComponent
      isMarkerShown={isMarkerShown}
      onMarkerClick={handleMarkerClick}
    />
  )
};