import {useState, useEffect} from "react";
import {do_nothing_function} from '../utils/do_nothing_function'
import {useLocalStorage} from './useLocalStorage'
import {useAuthLocalStorage} from './useAuthLocalStorage'
import {Function} from '../types'
import {uuid_generator} from '../utils/uuid'
import storage_ids from '../storage_ids'

const index_uuid = new uuid_generator();

export const createStore = (initialState:any) => ({
    data: initialState,
    set: do_nothing_function,
    registry: {},
});


export const store = {
  [storage_ids.assignments]: createStore([]),
  [storage_ids.new_assignment_notes]: createStore(''),
  [storage_ids.new_assignment_steps]: createStore([]),
  [storage_ids.new_assignment_distribution_points]: createStore([]),
  [storage_ids.new_assignment_new_distribution_point]: createStore(''),
};

export const getStore = (uuid: string) => {
  return store[uuid]
};

// use useCallback with no dependencies to send onChange to get best performance!!!
export const useRegister = (uuid: string, onChange) => {
  const [index, set_index] = useState(null);

  useEffect(() => {
    let i;
    if (onChange !== undefined) {
      const i = index_uuid.create();
      store[uuid].registry[i] = onChange;
      set_index(i);
    }
    return () => {
      if (i !== undefined)
        delete store[uuid].registry[i]
    }
  }, []);

  useEffect(() => {
    if (index !== null) {
      store[uuid].registry[index] = onChange;
    }
  }, [onChange]);

  return store[uuid];
};

export const useCreateStor = (uuid: string, initialState: any) => {
  useEffect(() => {
    if (!store[uuid])
      store[uuid] = createStore(initialState);
  }, []);
};

export const useUpdateStor = (uuid: string, state: any, setState: Function) => {
  useEffect(() => {
    store[uuid].data = state;
    store[uuid].set = setState;
    Object.values(store[uuid].registry).forEach(call=>call(state))
  }, [state]);
};


export const useStore = (uuid: string, initialState: any) => {
  const [state, setState] = useState(initialState);
  useUpdateStor(uuid, state, setState);
  return [state, setState]
};

export const useDynamicStore = (uuid: string, initialState: any) => {
  const [state, setState] = useState(initialState);
  useCreateStor(uuid, initialState);
  useUpdateStor(uuid, state, setState);
  return [state, setState]
};

export const useStoreStorage = (uuid: string, initialState: any) => {
  const [state, setState] = useLocalStorage(uuid, initialState);
  useUpdateStor(uuid, state, setState);
  return [state, setState]};

export const useAuthStoreStorage = (uuid: string, initialState: any) => {
  const [state, setState] = useAuthLocalStorage(uuid, initialState);
  useUpdateStor(uuid, state, setState);
  return [state, setState]};

export const useDynamicStoreStorage = (uuid: string, initialState: any) => {
  const [state, setState] = useLocalStorage(uuid, initialState);
  useCreateStor(uuid, initialState);
  useUpdateStor(uuid, state, setState);
  return [state, setState]
};