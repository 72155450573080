import type {AsyncArglessFunction, AsyncFunction, SetState} from "../types";
import {useEffect, useState} from "react";

export type PageMatrix = {[number]:Array};
export type FetchPage = (page_number: number, items_per_page: number) => Promise<Array<Object>>;  // promise returns an empty Array<Objects> in case of failure

export type PaginationProps = {|
  fetchCount: AsyncArglessFunction,
  fetchPage: FetchPage,
|};

export type PaginationHooks = {|
  loading: boolean, set_loading: SetState,
  count_items: null | number, set_count_items: SetState,
  items_per_page: number, set_items_per_page: SetState,
  page_number: number, set_page_number: SetState,
  last_page: number, set_last_page: SetState,
  page_items: Array, set_page_items: SetState,
  page_matrix: PageMatrix, set_page_matrix: SetState,
|};

export const updateCount = async (fetchCount: AsyncFunction, set_count_items: SetState) => {
  const count = await fetchCount();
  count === false
    ? set_count_items(0)
    : set_count_items(count)
};

export const useCountItems = (fetchCount: AsyncFunction, active: boolean) => {
  const [count_items, set_count_items] = useState(null);

  useEffect(()=>{
    if (active && count_items === null)
      updateCount(
        fetchCount,
        set_count_items,
      );
  }, [count_items, active]);

  return [count_items, set_count_items]
};

export const calcLastPage = (count_items:number, items_per_page:number, set_last_page:SetState) => {
  const last_page = count_items === null
    ? 1
    : count_items === 0
      ? 1
      : Math.ceil(count_items / items_per_page);
  set_last_page(last_page);
  return last_page
};

export const usePages = (
  count_items: number,
  items_per_page: number,
) => {
  const [page_number, set_page_number] = useState(1);
  const [last_page, set_last_page] = useState(1);

  useEffect(()=>{
    const calc_last_page = calcLastPage(count_items, items_per_page, set_last_page);
    if (calc_last_page < page_number)
      set_page_number(calc_last_page);
  }, [count_items]);

  useEffect(()=>{
    calcLastPage(count_items, items_per_page, set_last_page);
    set_page_number(1);
  }, [items_per_page]);

  return [
    page_number, set_page_number,
    last_page, set_last_page,
  ]
};

export const update_matrix = async (
  fetchPage: FetchPage,
  set_loading: SetState,
  items_per_page: number,
  page_number: number,
  page_matrix: PageMatrix,
  set_page_matrix: SetState,
  ) => {
  set_loading(true);
  const res = await fetchPage(page_number, items_per_page);
  set_page_matrix({
    ...page_matrix,
    [page_number]: res ? res : [],
  });
  set_loading(false)
};

export const usePageMatrix = (
  count_items: number,
  active: boolean,
  items_per_page: number,
  last_page: number,
  page_number: number,
  fetchPage: FetchPage,
  set_loading: SetState,
) => {
  const [page_matrix, set_page_matrix] = useState({});
  const [ready, set_ready] = useState(false);

  // reset matrix if no count
  useEffect(() => {
    if (count_items === null) {
      set_page_matrix({});
    }
  }, [count_items]);

  // fetch page first time
  useEffect(() => {
    if (active && count_items !== null && ready === false) {
      (async ()=> {
        await update_matrix(
          fetchPage, set_loading, items_per_page,
          page_number, {}, set_page_matrix,
        );
        set_ready(true);
      })()
    }
  }, [count_items, active]);

  // update on items_per_page change
  useEffect(() => {
    if (ready) {
      update_matrix(
        fetchPage, set_loading, items_per_page,
        page_number, {}, set_page_matrix,
      );
    }
  }, [items_per_page]);

  // handle last_page change
  useEffect(() => {
    const new_matrix = {};
    Object.keys(page_matrix).forEach(index=>{
      if (last_page <= index)
        new_matrix[index] = page_matrix[index]
    });
    set_page_matrix(new_matrix)
  }, [last_page]);

  // update on page_number change
  useEffect(() => {
    if (ready && page_matrix[page_number] === undefined) {
      update_matrix(
        fetchPage, set_loading, items_per_page,
        page_number, {}, set_page_matrix,
      );
    }
  }, [page_number]);

  return [page_matrix, set_page_matrix];
};

export const usePageItems = (
  page_matrix: PageMatrix,
  page_number: number,
) => {
  const [page_items, set_page_items] = useState([]);

  useEffect(() => {
    set_page_items(page_matrix[page_number] === undefined ?
      [] : page_matrix[page_number]
    );
  }, [page_number, page_matrix]);

  return [page_items, set_page_items];
};

export const usePagination = ({
  fetchCount,
  fetchPage,
  initial_items_per_page=10,
  active=true,
}: PaginationProps): PaginationHooks => {

  const [loading, set_loading] = useState(false);
  const [count_items, set_count_items] = useCountItems(fetchCount, active);
  const [items_per_page, set_items_per_page] = useState(initial_items_per_page);

  const [
    page_number, set_page_number,
    last_page, set_last_page,
  ] = usePages(count_items, items_per_page);

  const [page_matrix, set_page_matrix] = usePageMatrix(
    count_items,
    active,
    items_per_page,
    last_page,
    page_number,
    fetchPage,
    set_loading,
  );

  const [page_items, set_page_items] = usePageItems(page_matrix, page_number);

  const add_item = (item) => {
    const new_matrix = {...page_matrix};
    set_count_items(count_items+1);
    new_matrix[page_number] = [item, ...new_matrix[page_number]];
    set_page_matrix(new_matrix)
  };

  const remove_item = (item) => {
    const new_matrix = {...page_matrix};
    set_count_items(count_items-1);
    new_matrix[page_number] = new_matrix[page_number].filter(i=>i!==item);
    set_page_matrix(new_matrix)
  };

  const reRenderPage = () => {
    const new_matrix = {...page_matrix};
    new_matrix[page_number] = [...new_matrix[page_number]];
    set_page_matrix(new_matrix)
  };

  return {
    loading, set_loading,
    count_items, set_count_items,
    items_per_page, set_items_per_page,
    page_number, set_page_number,
    last_page, set_last_page,
    page_items, set_page_items,
    page_matrix, set_page_matrix,
    add_item, remove_item,
    reRenderPage,
  }
};
