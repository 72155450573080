import {useLoadDot} from '../../hooks/useLoadDot'
import React, {useContext, useState} from "react";
import {useUsersCount, useGetUsers} from '../../api/auth/users'

import createStore from 'zustand'
import produce from 'immer'
import {usePagination} from "../../hooks/usePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from '@material-ui/core/Grid'
import context from "../../context";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Paginator} from "../../components/Pagination";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {CoolTitle} from "../../components/CoolTitle";
import type {Language} from "../../types";

const sort_by = {
  created: {},
  email: {},
  username: {},
  access_level: {},
};

Object.keys(sort_by).forEach((k, i)=>{
  sort_by[k].value = k;
  sort_by[k].index = i;
});

const sort_list = Object.values(sort_by);

export const useStore = createStore(
  (set, get) => ({
    // data
    data: [],
    count: null,
    loading: false,
    sort: sort_by.created.value,
    search: '',
    projection: [
      '_id',
      'username',
      'created',
      'last_update',
      'email',
      'drivers',
      'vehicles',
      'platforms',
      'freight',
      'passengers',
    ],

    // getting
    get,

    // setting
    set: fn => set(produce(fn)),

    /////////////
    // actions //
    /////////////

    onSearchChange: event => get().set(state => {state.search = event.target.value}),

    usePagination: () => {
      const state = get();

      const fetchCount = useUsersCount();
      const get_users = useGetUsers();

      const fetchPage = async (page_number: number, items_per_page: number) => {
        const res = await get_users({
          page_number,
          items_per_page,
          search: state.search,
          projection: state.projection,
          sort: state.sort,
        });

        set(state => {state.data = res ? res : []});

        return res
      };

      const {
        loading, set_loading,
        count_items, set_count_items,
        items_per_page, set_items_per_page,
        page_number, set_page_number,
        last_page, set_last_page,
        page_items, set_page_items,
        page_matrix, set_page_matrix,
        add_item, remove_item,
        reRenderPage,
      } = usePagination({
        initial_items_per_page,
        fetchCount, fetchPage,
      });

      return {
        loading, set_loading,
        count_items, set_count_items,
        items_per_page, set_items_per_page,
        page_number, set_page_number,
        last_page, set_last_page,
        page_items, set_page_items,
        page_matrix, set_page_matrix,
        add_item, remove_item,
        reRenderPage,
        fetchCount,
        fetchPage,
      };
    }

  })
);

const initial_items_per_page = 30;

const useStyles = makeStyles({
  root: {
    padding: 10,
  },

  count: {
    margin: 15,
    fontSize: 18,
    marginBottom: 15,
  },

  titles: {
    width: 200,
    overflowWrap: 'break-word',
  },

  items: {

  },

  container: {

  },

  item: {
    padding: 10,
    border: '1px solid #b8bde2',
    backgroundColor: '#fff',
    minHeight: 64,
    marginBottom: 10,
  },

  spacer: {
    width: 20,
  },

  flexer: {
    display: 'flex',
  },

  container2: {
    marginTop: 5,
    marginBottom: 5,
  },

  search: {
    marginBottom: 30,
    margintop: 15,
  },
});

export const text = {
  eng: {
    count: 'Total User Count',

    username: 'username',
    created: 'created',
    last_update: 'last_update',
    email: 'email',
    drivers: 'drivers',
    vehicles: 'vehicles',
    platforms: 'platforms',
    passengers: 'passengers',
    freight: 'freight',

    actions: {
      title: 'Select an action',
      edit: 'edit',
      create: 'create',
      block: 'block',
      remove: 'remove',
    },

    search: 'search',
    search_placeholder: 'enter username',
    search_go: 'start search',
  },

  heb: {
    count: 'כמות משתמשים',
    username: 'שם משתמש',
    created: 'תאריך הרשמה',
    last_update: 'עודכן לאחרונה',
    email: 'אימייל',
    drivers: 'נהגים',
    vehicles: 'רכבים',
    platforms: 'עגלות',
    passengers: 'עוזרי נהג',
    freight: 'סחורה',

    actions: {
      title: 'בחר פעולה',
      edit: 'ערוך',
      create: 'צור חדש',
      block: 'חסום',
      remove: 'מחק',
    },

    search: 'חיפוש',
    search_placeholder: 'חפש שם משתמש',
    search_go: 'בצע חיפוש',
  },
};

export const DataItem = ({item, field}) => {
  const classes = useStyles();
  const language: Language = useContext(context.language).language;
  return (
    <Grid item className={classes.titles}>
      <div><b>{text[language.value][field]}:</b></div>
      <div>{item[field]}</div>
    </Grid>
  )
};

export const Action = ({data}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const language: Language = useContext(context.language).language;
  const links = useContext(context.links);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEdit = () => {
    links.go_to_edit_user(data._id);
    handleClose()
  };

  return (
    <div>
      <Tooltip title={text[language.value].actions.title}>
        <IconButton aria-label="actions" color="inherit" onClick={handleClick}>
          <MoreVertIcon/>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={text[language.value].actions.edit} />
        </MenuItem>
      </Menu>
    </div>
  )
};

export const Search = () => {
  const language: Language = useContext(context.language).language;
  const classes = useStyles();
  const value = useStore(state => state.search);
  const onChange = useStore(state => state.onSearchChange);
  const usePagination = useStore(state => state.usePagination);

  const {fetchPage} = usePagination();

  const onSearch = () =>
    fetchPage(1, initial_items_per_page);

  return (
    <div>
      <TextField
        value={value}
        onChange={onChange}
        // label={text[language.value].search}
        // helperText={text[language.value].search_placeholder}
        placeholder={text[language.value].search_placeholder}
        variant="outlined"
        className={classes.search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title={text[language.value].search_go}>
                <IconButton aria-label="start-search" color="inherit" onClick={onSearch}>
                  <SearchIcon/>
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
};

export default () => {
  const language: Language = useContext(context.language).language;
  const usePagination = useStore(state => state.usePagination);
  const data = useStore(state => state.data);
  const {
    loading, count_items,
    page_items, set_page_number,
    page_number, last_page,
  } = usePagination();
  const load_msg = useLoadDot(loading);
  const classes = useStyles();

  return (
 <>
   <div className={classes.root}>
     <CoolTitle style={{marginBottom: 40}}>
       <div className={classes.count}>
         {text[language.value].count}: {loading ? load_msg : count_items}
       </div>
     </CoolTitle>

     <Search/>

     <Grid className={classes.container} container justify={'flex-start'} direction={'column'}>
       {data.map((i, index) =>
         <Grid item key={i._id} className={classes.item} style={data.length===index+1?{marginBottom:0}:{}}>
           <div className={classes.flexer}>
             <Action data={i}/>
             <div className={classes.spacer}/>
             <Grid className={classes.container2} container justify={'space-between'} spacing={2}>
               {['username', 'email', 'created', 'last_update', 'drivers', 'vehicles', 'platforms', 'passengers', 'freight']
                 .map(f=><DataItem item={i} field={f}/>
                 )}
             </Grid>
           </div>
         </Grid>
       )}
     </Grid>
   </div>
   <div style={{flexGrow: 1}}/>
   {data.length ?
     <Paginator
       style={{margin:30}}
       loading={loading}
       set_page_number={set_page_number}
       page_number={page_number}
       last_page={last_page}
     />
     : null}</>
  )
}