import React, {useCallback, useMemo, useState} from "react";
import TextField, {TextFieldProps} from "@material-ui/core/TextField/TextField";
import {text} from "../../../variables/text/UserEdit";
import {UseStore} from "zustand";
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from "@material-ui/core/MenuItem";
import {useGetState, useGetInputState} from '../../../hooks/useGetNestedStore'
import {useGetLabel} from '../../../hooks/useGetText'
import {useSetState} from '../../../hooks/useSetNestedStore'

export interface InputStoreProps extends TextFieldProps {
  /** .*/
  useStore: UseStore,
  /** Object using language context and path property to set up the correct label.*/
  text?: Object,
  /** state path*/
  path: string,

  mandatory?: boolean,

  validate?: Function,

  children?: React.ReactNode,
}

export const useInputChange = (setState:Function) => useCallback((event) => setState(event.target.value), []);

export const useError = (validate, value, mandatory) => useMemo(()=>{
  if (!mandatory && value === '')
    return undefined;

  const validation = validate ? validate(value) : undefined;
  return validation ? validation : undefined;
}, [value, validate, mandatory]);

export const InputTextStore = React.memo((props:InputStoreProps) => {
  const {useStore, text, path, validate, mandatory, ...rest} = props;
  const value = useStore(useGetInputState(path));
  const set = useStore(useSetState(path));
  const onChange = useInputChange(set);
  const label = useGetLabel(path, text, mandatory);
  const error = useError(validate, value, mandatory);
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      error={error}
      label={label}
      value={value}
      onChange={onChange}
      helperText={error}
      fullWidth
      {...rest}
    />
  )
});

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

export const InputPasswordStore = (props:InputStoreProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const container = useMemo(()=>({}), []);
  container.showPassword = showPassword;
  container.setShowPassword = setShowPassword;
  const handleClickShowPassword = useCallback(()=>container.setShowPassword(!container.showPassword),[]);

  return (
    <InputTextStore
      type={showPassword ? 'text' : 'password'}
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockIcon />
          </InputAdornment>
        ),

        endAdornment:  (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
};

export const InputSelectStore = (props:InputStoreProps) => {
  const {useStore, path, options} = props;
  const set = useStore(useSetState(path));
  const onChange = useInputChange(set);
  return (
    <InputTextStore
      select
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option} onClick={onChange}>
          {option}
        </MenuItem>
      ))}
    </InputTextStore>
  )
};