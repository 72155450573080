import createStore, {UseStore} from 'zustand'
import {CreateFetcher} from "../utils/storeFetchFunctions";
import {projection} from "../variables/projections";
import produce from "immer";
import {useCallback, useContext, useEffect, useMemo} from "react";
import {useGetDriver, useGetDrivers, useAdminEditDriver, useAdminEditDriverPhoto} from "../api/auth/drivers";
import {useCreateVehicle, useGetVehicles} from "../api/auth/vehicles";
import context from "../context";
import Swal from "sweetalert2";
import {text} from "../variables/text/UserEdit";
import {useRouteMatch} from 'react-router-dom'
import {register} from "./stores";
import {access_levels_text} from "../variables/text/lists";
import {access_levels} from "../variables/variables";
import {useParams} from 'react-router-dom'
import {Language} from '../types'


// improve performance by fetching state
// from dynamically created functions
// functions are created on store creation time
export const fetchStore = {
  // [state[key]]: state => state[state[key]],
};


export const useEditDriverStore: UseStore = createStore(
  (set, get) => CreateFetcher(fetchStore, {
    // data
    data: null,
    loading: false,
    loaded: false,

    driver: projection.drivers.reduce((a,b)=> (a[b]=undefined, a),{}),
    driver_image: { url:null, file:null },

    updating: false,

    // getting
    get,

    // setting
    set: fn => set(produce(fn)),
    set_field: (field:string, data:any) => get().set(state=>{state.driver[field]=data}),
    useTextField: (field) => useCallback((event) => get().set_field(field, event.target.value), [field]),
    useDriverTextField: (field) => useCallback((event) => get().set(state=>{state.driver[field]=event.target.value}), [field]),
    useVehicleTextField: (field) => useCallback((event) => get().set(state=>{state.vehicle[field]=event.target.value}), [field]),

    /////////////
    // actions //
    /////////////

    onTabChange: tab => get().set(state => {state.tab = tab}),

    useGetDriver: () => {
      const {user_id, driver_id} = useParams();
      const call = useGetDriver();
      const language: Language = useContext(context.language).language;

      useEffect(()=>{(async () => {
        const state = get();

        if (state.loading)
          return;

        state.set(state => {state.loading = true});

        const res = await call({user_id, driver_id, projection: projection.drivers});
        if (res) {
          state.set(state => {state.driver = res});
          state.set(state => {state.loaded = true});
        }

        state.set(state => {state.loading = false});
      })()}, [user_id, driver_id]);

      return call;
    },

    useSetDriver: () => {
      const container = useMemo(()=>({}), []); // useCallback once
      container.params = useParams();
      container.call = useAdminEditDriver();
      container.editDriverPhoto = useAdminEditDriverPhoto();
      container.language = useContext(context.language).language;

      return useCallback(()=>{(async () => {
        const state = get();
        state.set(state => {state.updating = true});

        const res = await container.call({...container.params, data: {...state.driver, _id: undefined, owner: undefined}});
        if (res) {
          Swal.fire({
            type: 'success',
            title: text[container.language.value].add_driver_success.title,
            text: text[container.language.value].update_driver_success,
            confirmButtonText: text[container.language.value].add_driver_success.confirmButtonText,
            timer: 6000,
          });
          if (state.driver_image.file !== null)
            container.editDriverPhoto({
              user_id: container.id,
              driver_id: res._id,
              file: state.driver_image.file,
            });
        }

        state.set(state => {state.updating = false});
      })()}, []);
    },

  })
);

register({fetchStore, useEditDriverStore});