import CustomButton from "./Button";
import React from "react";
import buttonStyles, { small_btn_style, big_btn_style } from "../../../assets/jss/buttonStyles";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { blue, grey } from '@material-ui/core/colors';
import style_vars from '../../../assets/jss/style_vars'


export const SmallBlueButton = withStyles(theme => ({
  root: {
    ...small_btn_style,
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover, &:active, &:focus': {
      backgroundColor: blue[700],
      boxShadow: `0 14px 26px -12px ${blue[500]}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${blue[500]}30`,
      },
  },
}))(Button);

export const BlueButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover, &:active, &:focus': {
      backgroundColor: blue[700],
      boxShadow: `0 14px 26px -12px ${blue[500]}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${blue[500]}30`,
    },
  },
}))(Button);

export const SmallWhiteButton = withStyles({
  root: {
    ...small_btn_style,
    border: style_vars.borders.default,
    color: '#555555',
    backgroundColor: '#fff',
    '&:hover, &:active, &:focus': {
      color: '#222222',
      backgroundColor: grey[200],
      boxShadow: `0 14px 26px -12px ${grey[500]}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${grey[500]}30`,
    },
  },
})(Button);

export const SmallRedButton = withStyles(theme => ({
  root: {
    WebkitTapHighlightColor: 'transparent',
    textAlign: 'left',
    userSelect: 'none',
    WebkitBoxDirection: 'normal',
    margin: '0',
    border: '0',
    font: 'inherit',
    verticalAlign: 'baseline',
    borderRadius: '3px',
    flexShrink: '0',
    fontWeight: '600',
    padding: '5px 12px',
    textDecoration: 'none',
    marginLeft: '10px',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif',
    textTransform: 'none',

    // ...small_btn_style,
    color: theme.palette.getContrastText(style_vars.colors.btn_red),
    backgroundColor: style_vars.colors.btn_red,
    // fontWeight: 0,

    '&:hover, &:active, &:focus': {
      backgroundColor: style_vars.colors.btn_red_focused,
      boxShadow: `0 14px 26px -12px ${style_vars.colors.btn_red_focused}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${style_vars.colors.btn_red_focused}30`,
    },
  },
}))(Button);

export const SmallGreenButton = withStyles(theme => ({
  root: {
    // ...small_btn_style,

    WebkitTapHighlightColor: 'transparent',
    textAlign: 'left',
    userSelect: 'none',
    WebkitBoxDirection: 'normal',
    margin: '0',
    border: '0',
    font: 'inherit',
    verticalAlign: 'baseline',
    borderRadius: '3px',
    flexShrink: '0',
    fontWeight: '600',
    padding: '5px 12px',
    textDecoration: 'none',
    marginLeft: '10px',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif',
    textTransform: 'none',

    color: theme.palette.getContrastText(style_vars.colors.btn_green),
    backgroundColor: style_vars.colors.btn_green,
    '&:hover, &:active, &:focus': {
      backgroundColor: style_vars.colors.btn_green_focused,
      boxShadow: `0 14px 26px -12px ${style_vars.colors.btn_green_focused}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${style_vars.colors.btn_green_focused}30`,
    },
  },
}))(Button);

export const WhiteButton = ({...props}) => {
  const classes = buttonStyles();
  return <CustomButton color="white" className={classes.whiteButton} {...props}/>
};

export const GreyButton = withStyles(theme => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#9e9e9e',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#797777',
      boxShadow: `0 14px 26px -12px #79777777, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px #79777730`,
    },
  },
}))(Button);

export const RedButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(style_vars.colors.btn_red),
    backgroundColor: style_vars.colors.btn_red,
    '&:hover, &:active, &:focus': {
      backgroundColor: style_vars.colors.btn_red_focused,
      boxShadow: `0 14px 26px -12px ${style_vars.colors.btn_red_focused}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${style_vars.colors.btn_red_focused}30`,
    },
  },
}))(Button);

export const PurpleButton = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: style_vars.colors.btn_purple,
    '&:hover, &:active, &:focus': {
      backgroundColor: style_vars.colors.btn_purple_focused,
      boxShadow: `0 14px 26px -12px ${style_vars.colors.btn_purple_focused}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${style_vars.colors.btn_purple_focused}30`,
    },
  },
}))(Button);

export const GreenButton = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: style_vars.colors.btn_green,
    '&:hover, &:active, &:focus': {
      backgroundColor: style_vars.colors.btn_green_focused,
      boxShadow: `0 14px 26px -12px ${style_vars.colors.btn_green_focused}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${style_vars.colors.btn_green_focused}30`,
    },
  },
}))(Button);

export const OrangeButton = withStyles(theme => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#e89030',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#a46622',
      boxShadow: `0 14px 26px -12px #a4662277, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px #a4662230`,
    },
  },
}))(Button);

export const BigRedButton = withStyles(theme => ({
  root: {
    ...big_btn_style,
    boxShadow: `0 2px 2px 0 ${style_vars.colors.btn_red}20, 0 3px 1px -2px ${style_vars.colors.btn_red}32, 0 1px 5px 0 ${style_vars.colors.btn_red}19`,
    color: theme.palette.getContrastText(style_vars.colors.btn_red),
    backgroundColor: style_vars.colors.btn_red,
    '&:hover, &:active, &:focus': {
      backgroundColor: style_vars.colors.btn_red_focused,
      boxShadow: `0 14px 26px -12px ${style_vars.colors.btn_red_focused}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${style_vars.colors.btn_red_focused}30`,
    },
  },
}))(Button);

export const BigGreenButton = withStyles(theme => ({
  root: {
    ...big_btn_style,
    boxShadow: `0 2px 2px 0 ${style_vars.colors.btn_green}20, 0 3px 1px -2px ${style_vars.colors.btn_green}32, 0 1px 5px 0 ${style_vars.colors.btn_green}19`,
    color: theme.palette.getContrastText(style_vars.colors.btn_green),
    backgroundColor: style_vars.colors.btn_green,
    '&:hover, &:active, &:focus': {
      backgroundColor: style_vars.colors.btn_green_focused,
      boxShadow: `0 14px 26px -12px ${style_vars.colors.btn_green_focused}77, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${style_vars.colors.btn_green_focused}30`,
    },
  },
}))(Button);