import {withStyles} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    margin: 3,
    borderRadius: 3,
    '&:hover': {
      backgroundColor: 'rgba(34,152,247,0.69)!important',
      color: 'white',
    },
    '&:focus': {
      backgroundColor: '#2196f3',
      color: 'white',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '& span': {
      textAlign: 'right',
    }
  },
}))(MenuItem);