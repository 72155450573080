import React, {useContext} from 'react';
import Share from '@material-ui/icons/Share'
import {
  FacebookShareCount,
  PinterestShareCount,
  VKShareCount,
  OKShareCount,
  RedditShareCount,
  TumblrShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
} from 'react-share'
import {app_name as title, facebook_client_id} from '../../variables/variables'
import big_logo from '../../assets/images/logo.png';
import shareStyles from '../../assets/jss/shareStyles'
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import context from '../../context'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export const ShareButtons = ({...props}) => {
  const shareUrl  = window.location.href;
  const classes = shareStyles();
  const {share_buttons} = useContext(context.share_buttons);

  return (
    <div style={{position: 'relative'}}>
      <Zoom in={share_buttons} timeout={700}>
        <Grid container className={classes.container} {...props}>
          <div className={classes.network}>
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className={classes.network_share_button}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <div>
              <FacebookShareCount url={shareUrl} className={classes.network_share_count}>
                {count => count}
              </FacebookShareCount>
            </div>
          </div>

          <div className={classes.network}>
            <FacebookMessengerShareButton
              url={shareUrl}
              appId={facebook_client_id}
              className={classes.network_share_button}
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
          </div>

          <div className={classes.network}>
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className={classes.network_share_button}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <div className={classes.network_share_count}>&nbsp;</div>
          </div>

          <div className={classes.network}>
            <TelegramShareButton
              url={shareUrl}
              title={title}
              className={classes.network_share_button}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>

            <div className={classes.network_share_count}>&nbsp;</div>
          </div>

          <div className={classes.network}>
            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className={classes.network_share_button}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <div className={classes.network_share_count}>&nbsp;</div>
          </div>

          <div className={classes.network}>
            <LinkedinShareButton url={shareUrl} className={classes.network_share_button}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>

          <div className={classes.network}>
            <PinterestShareButton
              url={String(window.location)}
              media={`${String(window.location)}/${big_logo}`}
              className={classes.network_share_button}
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>

            <div>
              <PinterestShareCount url={shareUrl} className={classes.network_share_count} />
            </div>
          </div>

          <div className={classes.network}>
            <VKShareButton
              url={shareUrl}
              image={`${String(window.location)}/${big_logo}`}
              className={classes.network_share_button}
            >
              <VKIcon size={32} round />
            </VKShareButton>

            <div>
              <VKShareCount url={shareUrl} className={classes.network_share_count} />
            </div>
          </div>

          <div className={classes.network}>
            <OKShareButton
              url={shareUrl}
              image={`${String(window.location)}/${big_logo}`}
              className={classes.network_share_button}
            >
              <OKIcon size={32} round />
            </OKShareButton>

            <div>
              <OKShareCount url={shareUrl} className={classes.network_share_count} />
            </div>
          </div>

          <div className={classes.network}>
            <RedditShareButton
              url={shareUrl}
              title={title}
              windowWidth={660}
              windowHeight={460}
              className={classes.network_share_button}
            >
              <RedditIcon size={32} round />
            </RedditShareButton>

            <div>
              <RedditShareCount url={shareUrl} className={classes.network_share_count} />
            </div>
          </div>

          <div className={classes.network}>
            <TumblrShareButton
              url={shareUrl}
              title={title}
              className={classes.network_share_button}
            >
              <TumblrIcon size={32} round />
            </TumblrShareButton>

            <div>
              <TumblrShareCount url={shareUrl} className={classes.network_share_count} />
            </div>
          </div>

          <div className={classes.network}>
            <LivejournalShareButton
              url={shareUrl}
              title={title}
              description={shareUrl}
              className={classes.network_share_button}
            >
              <LivejournalIcon size={32} round />
            </LivejournalShareButton>
          </div>

          <div className={classes.network}>
            <MailruShareButton
              url={shareUrl}
              title={title}
              className={classes.network_share_button}
            >
              <MailruIcon size={32} round />
            </MailruShareButton>
          </div>

          <div className={classes.network}>
            <EmailShareButton
              url={shareUrl}
              subject={title}
              body="body"
              className={classes.network_share_button}
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
          <div className={classes.network}>
            <ViberShareButton
              url={shareUrl}
              title={title}
              className={classes.network_share_button}
            >
              <ViberIcon size={32} round />
            </ViberShareButton>
          </div>

          <div className={classes.network}>
            <WorkplaceShareButton
              url={shareUrl}
              quote={title}
              className={classes.network_share_button}
            >
              <WorkplaceIcon size={32} round />
            </WorkplaceShareButton>
          </div>

          <div className={classes.network}>
            <LineShareButton
              url={shareUrl}
              title={title}
              className={classes.network_share_button}
            >
              <LineIcon size={32} round />
            </LineShareButton>
          </div>

          <div className={classes.network}>
            <WeiboShareButton
              url={shareUrl}
              title={title}
              image={`${String(window.location)}/${big_logo}`}
              className={classes.network_share_button}
            >
              <WeiboIcon size={32} round />
            </WeiboShareButton>
          </div>

          <div className={classes.network}>
            <PocketShareButton
              url={shareUrl}
              title={title}
              className={classes.network_share_button}
            >
              <PocketIcon size={32} round />
            </PocketShareButton>
          </div>

          <div className={classes.network}>
            <InstapaperShareButton
              url={shareUrl}
              title={title}
              className={classes.network_share_button}
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </div>
        </Grid>
      </Zoom>
    </div>
  )
};

export default ({...props}) => {
  const classes = shareStyles();
  const {set_share_buttons} = useContext(context.share_buttons);

  const onClick = () => set_share_buttons(share_buttons=>!share_buttons);
  const handleClickAway = () => set_share_buttons(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Share
        className={classes.sharebtn}
        onClick={onClick}
        {...props}
      />
    </ClickAwayListener>
  )
}
