import React, {useState, useContext} from "react";
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import {LinearProgress} from '@material-ui/core';
import context from '../context'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import FaceIcon from '@material-ui/icons/Face';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Chip from '@material-ui/core/Chip';
import type {User} from "../types";
import beautifulNumber from '../utils/beautifulNumber'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid'
import {DriverCard} from './cards/DriverCard'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const [open, set_open] = useState(false);

  return (
      <div>
        <Grid container justify='center' style={{marginTop:35}}>
          <Button variant="contained" color="primary" onClick={()=>set_open(true)}>
            New Vehicle
          </Button>
        </Grid>

        <Grid container justify='center' className={classes.root} spacing={2} style={{marginTop:35}}>
          <Grid item>
            <DriverCard/>
          </Grid>
          <Grid item>
            <DriverCard/>
          </Grid>
          <Grid item>
            <DriverCard/>
          </Grid>
          <Grid item>
            <DriverCard/>
          </Grid>
          <Grid item>
            <DriverCard/>
          </Grid>
          <Grid item>
            <DriverCard/>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={()=>set_open(false)}>
          poop
        </Dialog>

      </div>
  )
};