import React, {useState, useContext, useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import context from '../../context'
import {signinStyles} from '../../assets/jss/signin'
import ExternalSignIn from './ExternalSignIn'
import NormalSignIn from './NormalSignIn'
import ExpansionPanel from '../custom/CustomExpansion/ExpansionPanel'
import SignUp from "./SignUp";
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import {SlideDialogDown} from '../transitions/DialogTransitions'

export default () => {
  const classes = signinStyles();
  const width: number = useContext(context.size).size.width;
  const { signin, set_signin } = useContext(context.signin);
  const is_logged: boolean = useContext(context.is_logged);

  const [openPasswordRecovery, setOpenPasswordRecovery] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose        = () => set_signin({...signin, open: false});
  const toggleRegistration = () => setOpenRegistration(!openRegistration);
  const onEmailChange    = event => setEmail(event.target.value);
  const onPasswordChange = event => setPassword(event.target.value);

  useEffect(() => {is_logged && handleClose()}, [is_logged]);

  return (
      <Dialog
        open={signin.open}
        TransitionComponent={SlideDialogDown}
        keepMounted
        onClose={handleClose}
        aria-labelledby="sign in"
        aria-describedby="choose method of signing in"
        style={{zIndex:1101}}
        fullScreen={fullScreen && width < 560}
      >
        <Grid container justify='center' style={{maxWidth:376, margin:'auto', position:'relative'}}>
          <DialogTitle style={{textAlign: 'center', margin: 'auto', marginTop: 0}}>
            {openRegistration ? "SIGN UP" : "SIGN IN"}
          </DialogTitle>

          <IconButton
            color="default"
            aria-label="cancel"
            style={{position:'absolute', right:0, top: 0}}
            onClick={handleClose}
          >
            <CancelIcon/>
          </IconButton>

          <DialogContent style={{padding: fullScreen && width < 560 ? 0 :20, paddingTop:0}}>
            <ExpansionPanel
              panel={<div/>}
              details={[
                <ExternalSignIn key={0}/>,
                <NormalSignIn
                  key={1}
                  openPasswordRecovery={openPasswordRecovery}
                  setOpenPasswordRecovery={setOpenPasswordRecovery}
                  email={email}
                  password={password}
                  onEmailChange={onEmailChange}
                  onPasswordChange={onPasswordChange}
                  setPassword={setPassword}
                />
              ]}
              open={!openRegistration}
              onChange={toggleRegistration}
            />
            <ExpansionPanel
              panel={<div/>}
              open={openRegistration}
              onChange={toggleRegistration}
              details={<SignUp
                email={email}
                password={password}
                onEmailChange={onEmailChange}
                onPasswordChange={onPasswordChange}
              />}
            />
            <div className={classes.signup_msg} onClick={toggleRegistration}>
              {openRegistration ? 'Have an account?' : 'Don\'t have an account?'}
              <span className={classes.signup_msg_highlight}>{" "}Sign {openRegistration ? 'In' : 'Up'}</span>
            </div>
          </DialogContent>
        </Grid>

      </Dialog>
  );
}