import React, {useState, useContext} from "react";
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import {LinearProgress} from '@material-ui/core';
import context from '../../context'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import EditPersonalInfoForm from '../forms/account/EditPersonalInfoForm'
import NewEmailForm from '../forms/account/NewEmailForm'
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Chip from '@material-ui/core/Chip';
import type {User} from "../../types";
import beautifulNumber from '../../utils/beautifulNumber'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid'
import DriverCard from '../cards/DriverCard'
import { makeStyles } from '@material-ui/core/styles';
import MyDrivers from '../MyDrivers'
import MyVehicles from '../MyVehicles'
import platform_container from '../../assets/images/icons/platform_container.svg'
import boxes from '../../assets/images/icons/moving-home-transport-for-boxes-svgrepo-com.svg'
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import {
  count_user_drivers,
  count_user_vehicles,
  count_user_platforms,
  count_user_freight,
  count_user_assistants,
  get_user_drivers_page,
  get_user_vehicles_page,
  get_user_platforms_page,
  get_user_freight_page,
  get_user_assistants_page,
} from "../../api/public/users";
import {usePagination} from "../../hooks/usePagination";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const maxTabsWidth = 1000;
const initial_items_per_page = 30;

export default () => {
  const classes = useStyles();

  const links = useContext(context.links);
  const user: User = useContext(context.user).user;
  const {entered_page} = useContext(context.entered_page);
  const width: number = useContext(context.size).size.width;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const maxWidth = mobile?450:600;

  const mobileTabs = width < maxTabsWidth + 100;

  const [loading, set_loading] = useState(false);
  const [value, setValue] = useState(0);
  const [open, set_open] = useState(false);
  const [mounted_tabs, set_mounted_tabs] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // TODO: add sleep arg to usePaginate, to wait until tab value is relevant
  const driversPageProps = usePagination({active: value === 0, initial_items_per_page, fetchCount: () => count_user_drivers(user._id), fetchPage: (page_number: number, items_per_page: number) => get_user_drivers_page(user._id, page_number, items_per_page)});
  const vehiclesPageProps = usePagination({active: value === 1, initial_items_per_page, fetchCount: () => count_user_vehicles(user._id), fetchPage: (page_number: number, items_per_page: number) => get_user_vehicles_page(user._id, page_number, items_per_page)});
  const platformsPageProps = usePagination({active: value === 2, initial_items_per_page, fetchCount: () => count_user_platforms(user._id), fetchPage: (page_number: number, items_per_page: number) => get_user_platforms_page(user._id, page_number, items_per_page)});
  const freightPageProps = usePagination({active: value === 3, initial_items_per_page, fetchCount: () => count_user_freight(user._id), fetchPage: (page_number: number, items_per_page: number) => get_user_freight_page(user._id, page_number, items_per_page)});
  const assistantsPageProps = usePagination({active: value === 4, initial_items_per_page, fetchCount: () => count_user_assistants(user._id), fetchPage: (page_number: number, items_per_page: number) => get_user_assistants_page(user._id, page_number, items_per_page)});

  return (
    <Slide direction="up" timeout={1300} in={entered_page} mountOnEnter unmountOnExit>
    <div>
      {/*<Fade in={entered_page} timeout={1300}>*/}
      {/*  <Paper elevation={mobile?0:3} style={{maxWidth, margin: `${mobile ? 12 : 48}px auto`, padding: mobile?0:'2rem'}}>*/}
          <Fade in={entered_page} timeout={2000}>
            <div style={{maxWidth: maxTabsWidth, margin:'auto', marginTop: 35, marginBottom: 15}}>
              <Slide direction="down" timeout={2000} in={entered_page} mountOnEnter unmountOnExit>
                <Typography variant={'h5'} style={{textAlign: 'center', marginBottom: '2rem'}}>
                  {loading ? 'Saving Changes...' : 'My Assets'}
                </Typography>
              </Slide>

              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                variant={mobileTabs?"scrollable":undefined}
                scrollButtons={mobileTabs?"on":undefined}
                centered={!mobileTabs}
                // style={{maxWidth:120}}
              >
                <Tab label={<Grid container alignItems='center'><SportsMotorsportsIcon fontSize={mobile?'small':'large'} style={{marginRight:mobile?3:6}}/> Drivers <Chip style={{marginLeft:mobile?3:6}} size={mobile?'small':'medium'} label={beautifulNumber(user.drivers)}/></Grid>} />
                <Tab label={<Grid container alignItems='center'><LocalShippingIcon fontSize={mobile?'small':'large'} style={{marginRight:mobile?3:6}}/> Vehicles <Chip style={{marginLeft:mobile?3:6}} size={mobile?'small':'medium'} label={beautifulNumber(user.vehicles)}/></Grid>} />
                <Tab label={<Grid container alignItems='center'><img src={platform_container} style={{marginRight:mobile?3:6, width:mobile?24:32, height:mobile?24:32}}/> Platforms <Chip style={{marginLeft:mobile?3:6}} size={mobile?'small':'medium'} label={beautifulNumber(user.platforms)}/></Grid>} />
                <Tab label={<Grid container alignItems='center'><img src={boxes} style={{marginRight:mobile?3:6, width:mobile?20:26, height:mobile?20:26}}/> Freight <Chip style={{marginLeft:mobile?3:6}} size={mobile?'small':'medium'} label={beautifulNumber(user.freight)}/></Grid>} />
                <Tab label={<Grid container alignItems='center'><EmojiPeopleIcon fontSize={mobile?'small':'large'} style={{marginRight:mobile?3:6}}/> Assistants <Chip style={{marginLeft:mobile?3:6}} size={mobile?'small':'medium'} label={beautifulNumber(user.assistants)}/></Grid>} />
              </Tabs>
              {loading && <LinearProgress/>}
            </div>
          </Fade>

          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <MyDrivers driversPageProps={driversPageProps}/>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <MyVehicles vehiclesPageProps={vehiclesPageProps}/>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <MyVehicles platformsPageProps={platformsPageProps}/>
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <MyVehicles freightPageProps={freightPageProps}/>
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
              <MyVehicles assistantsPageProps={assistantsPageProps}/>
            </TabPanel>
          </SwipeableViews>
        {/*</Paper>*/}
      {/*</Fade>*/}
    </div>
    </Slide>
  )
};