import React, {useContext} from "react";
import context from "../../../context";
import {fetchStore, useEditUserStore} from "../../../stores/useEditUserStore";
import Grid from "@material-ui/core/Grid";
import Submit from "../../custom/CustomButtons/Submit";
import {text} from "../../../variables/text/UserEdit";
import type {Language} from "../../../types";

export const UpdateUserAction = () => {
  const language: Language = useContext(context.language).language;
  const updating = useEditUserStore(fetchStore.updating);
  const onSubmit = useEditUserStore(fetchStore.useSetUser)();
  return (
    <Grid item>
      <Submit style={{width: 200}} loading={updating} onSubmit={onSubmit} >
        {text[language.value].submit}
      </Submit>
    </Grid>
  )
};

export const AddDriverAction = () => {
  const language: Language = useContext(context.language).language;
  const updating = useEditUserStore(fetchStore.updating);
  const onSubmit = useEditUserStore(fetchStore.useCreateDriver)();
  return (
    <Grid item>
      <Submit style={{width: 200}} loading={updating} onSubmit={onSubmit} color={'green'}>
        {text[language.value].add_driver}
      </Submit>
    </Grid>
  )
};

export const AddVehicleAction = () => {
  const language: Language = useContext(context.language).language;
  const updating = useEditUserStore(fetchStore.updating);
  const onSubmit = useEditUserStore(fetchStore.useCreateVehicle)();
  return (
    <Grid item>
      <Submit style={{width: 200}} loading={updating} onSubmit={onSubmit} color={'orange'} >
        {text[language.value].add_vehicle}
      </Submit>
    </Grid>
  )
};
