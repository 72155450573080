import React, {useContext, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import context from '../../../../context'
import IconButton from '@material-ui/core/IconButton'
import Fade from "@material-ui/core/Fade/Fade";
import FormHelperText from '@material-ui/core/FormHelperText'
import {icon_size, languages} from '../../../../variables/variables'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ids from '../../../../ids'
import type {Language, Function} from "../../../../types";

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  helper: {
    color: 'white',
    position: 'absolute',
    bottom: -26,
    left: -22,
    width: icon_size.width,
  }
}));

export default () => {
  const classes = useStyles();
  const language: Language = useContext(context.language).language;
  const set_language: Function = useContext(context.language).set_language;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    if (languages[value])
      set_language(languages[value]);
    setAnchorEl(null);
  };

  const Icon = languages[language.value].icon;

  return (
    <>
      <Fade in={true} timeout={400}>
        <div>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Language"
            aria-expanded={anchorEl?"true":"false"}
            aria-controls={ids.languages}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Icon style={icon_size}/>
            <div style={{position: 'relative'}}>
              <FormHelperText className={classes.helper}>{language.value}</FormHelperText>
            </div>
          </IconButton>
        </div>
      </Fade>
      <Menu
        id={ids.languages}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.values(languages).map(i=>
          <MenuItem key={i.value} onClick={()=>handleClose(i.value)}>{i.description}</MenuItem>
        )}
      </Menu>
    </>
  );
}