// import React, {useContext} from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import context from '../../../../context'
// import IconButton from '@material-ui/core/IconButton'
// import MenuIcon from '@material-ui/icons/Menu'
// import VisibilityTrigger from "../../../VisibilityTrigger";
// import Fade from "@material-ui/core/Fade/Fade";
//
// const useStyles = makeStyles(theme => ({
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
// }));
//
// export default () => {
//   const classes = useStyles();
//   const {set_sidebar} = useContext(context.sidebar);
//
//   return (
//     <VisibilityTrigger delay={2000}>
//       {({triggered}) =>
//         <Fade in={triggered} timeout={2000}>
//           <div>
//             <IconButton
//               edge="start"
//               className={classes.menuButton}
//               color="inherit"
//               aria-label="open drawer"
//               onClick={()=>{set_sidebar(true)}}
//             >
//               <MenuIcon />
//             </IconButton>
//           </div>
//         </Fade>
//       }
//     </VisibilityTrigger>
//   );
// }
import React, {useContext, useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import state from '../../../../context'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import VisibilityTrigger from "../../../VisibilityTrigger";
import Fade from "@material-ui/core/Fade/Fade";
// import Hamburger from '../../../Hamburger/Hamburger'
import CloseIcon from '@material-ui/icons/Close';
import sleep from '../../../../utils/sleep'

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const {sidebar, set_sidebar} = useContext(state.sidebar);
  const [Icon, set_Icon] = useState(sidebar ? CloseIcon : MenuIcon);
  const [open, set_open] = useState(true);

  useEffect(()=>{(async ()=> {
    set_open(false);
    await sleep(400);
    set_open(true);
    set_Icon(sidebar ? CloseIcon : MenuIcon)
  })()}, [sidebar]);

  return (
    <VisibilityTrigger delay={2000}>
      {({triggered}) =>
        <Fade in={triggered && open} timeout={400}>
          <div>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              aria-controls="navigation"
              aria-expanded={sidebar?"true":"false"}
              onClick={()=>{set_sidebar(!sidebar)}}
            >
              {/*<Hamburger active={sidebar}/>*/}
              <Icon />
            </IconButton>
          </div>
        </Fade>
      }
    </VisibilityTrigger>
  );
}