import React, {useMemo, useContext, useState, useEffect} from "react";
import Measure from "react-measure";
import context from '../context'
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import sleep from '../utils/sleep'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },

  topContainer: {
    display:'flex',
    cursor:'pointer',
  },

  container: {
    backgroundColor: 'white',
    display: 'inline-block',
    border: '1px solid #ababab',
    borderTopLeftRadius: ({rtl, size}) => rtl ? 0 : 0.15 * size.height,
    borderTopRightRadius: ({rtl, size}) => rtl ? 0.15 * size.height : 0,
  },

  headContainer: {
    backgroundColor: 'white',
    display: 'inline-block',
    border: '1px solid #ababab',
    width: ({size}) => size.height * 0.6,
    height: ({size}) => size.height * 0.6,
    marginTop: ({size}) => size.height * 0.4,
    marginLeft: ({rtl, size}) => rtl ? 0 : 0.1*size.height,
    marginRight: ({rtl, size}) => rtl ? 0.1*size.height : 0,
    borderTopLeftRadius: ({rtl, size}) => rtl ? 0.3 * size.height : 0,
    borderTopRightRadius: ({rtl, size}) => rtl ? 0 : 0.3 * size.height,
  },

  platform: {
    backgroundColor: 'white',
    display: 'flex',
    border: '1px solid #ababab',
    width: ({size}) => size.width + size.height * 0.6 + 0.1 * size.height,
    height: ({size}) => size.height * 0.3,
    marginTop: ({size}) => size.height * 0.05,

  },

  wheel: {
    width: ({size}) => size.height * 0.4,
    height: ({size}) => size.height * 0.4,
    backgroundColor: 'white',
    border: '1px solid #ababab',
    borderRadius: '100%',
    margin: ({size}) => `${size.height * 0.15}px ${size.height * 0.15}px 0px ${size.height * 0.15}px`,
  },

  wheelSpace: {
    flexGrow: 1,
  },

}));

export const CoolTitle = ({children, className, ...props}) => {
  const [size, set_size] = useState({width: 0, height:0});
  const [clicked, set_clicked] = useState(false);
  const context_rtl = useContext(context.rtl);

  const [rtl_style, set_rtl_style] = useState(true);

  const silde_direction = useMemo(() => (clicked ? !context_rtl.rtl : context_rtl.rtl) ? 'left' : "right", [context_rtl, clicked]);

  const styleProps = useMemo(()=>({rtl: rtl_style ? context_rtl.rtl : !context_rtl.rtl, size}), [context_rtl, size, rtl_style]);

  const classes = useStyles(styleProps);

  const handleClick = async () => {
    set_rtl_style(true);
    set_clicked(true);
    await sleep(1500);
    set_rtl_style(false);
    set_clicked(false);
    await sleep(1500);
    set_rtl_style(true);
  };

  return (
    <Slide direction={silde_direction} in={!clicked} timeout={1500} mountOnEnter unmountOnExit>
      <div className={className ? classes.root + ' ' + className : classes.root} {...props}>
        <div className={classes.topContainer} onClick={handleClick}>
          {rtl_style ?
            <>
              <Measure bounds onResize={contentRect=>set_size(contentRect.bounds)}>{({measureRef}) => (<div ref={measureRef} className={classes.container}>{children}</div>)}</Measure>
              <div className={classes.headContainer}/>
            </> : <>
              <div className={classes.headContainer}/>
              <Measure bounds onResize={contentRect=>set_size(contentRect.bounds)}>{({measureRef}) => (<div ref={measureRef} className={classes.container}>{children}</div>)}</Measure>
            </>
          }
        </div>
        <div className={classes.platform}>
          <div className={classes.wheel}/>
          <div className={classes.wheelSpace}/>
          <div className={classes.wheel}/>
        </div>
      </div>
    </Slide>
  )
};