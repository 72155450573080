import React from 'react';
import {useHistory} from 'react-router-dom'
import route from '../../routes';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },

  for04: {
    margin: theme.spacing(6),
    color: '#6883b3'
  },
}));

const Container = styled.div`
  position: relative;
  margin: auto;
  margin-top: 30px;
`;

const NoMatch = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Grid style={{position:'fixed', width: '100vw', height: '100vh', top: 100, left: 0}} justify={'center'} alignItems={'center'} direction={'column'} className={classes.root}>
      <Typography variant="h1" component="h2">
        PAGE
      </Typography>
      <Typography variant="h3" component="h2" style={{color:'#777777'}}>
        Not Found
      </Typography>
      <Typography variant="h2" component="h2" gutterBottom className={classes.for04}>
        4😵4
      </Typography>
      <Button
        onClick={() => {history.push(route.home)}}
        variant="contained"
        color="primary"
      >
        Go Back
      </Button>
    </Grid>
  )
};

export default NoMatch;