import {useContext, useMemo} from "react";
import context from "../context";
import type {Language} from "../types";

export const useGetLabel = (path:string, text, mandatory) => {
  const language: Language = useContext(context.language).language;
  return useMemo(() => {
    if (!text || !path)
      return;

    const label = path.split('.').reduce((o,i)=>o[i], text[language.value]);
    return mandatory ? '* ' + label : label
  }, [path, language, mandatory])
};