import routes from '../routes'
import {useHistory} from 'react-router-dom'
import open_tab from '../utils/open_tab'
import { gravatar_url } from '../variables/variables'
import sleep from '../utils/sleep'

const default_delay = 1000;

export default (set_entered_page, set_page_loading) => {
  const history = useHistory();
  return {

    go_home: async (delay:number=default_delay) => {
      set_page_loading(true);
      set_entered_page(false); //leave page
      await sleep(delay);
      history.push(routes.home)
    },

    driver_calendar: async (driver_id:string) => {
      history.push(routes.search_transport.replace(':id', driver_id))
    },

    search_transport: async (transport_id:string) => {
      history.push(routes.search_transport.replace(':id', transport_id))
    },

    search_transport_results: async (transport_id:string) => {
      history.push(routes.search_transport_results.replace(':id', transport_id))
    },

    go_to_my_account: async (delay:number=default_delay) => {
      set_page_loading(true);
      set_entered_page(false); //leave page
      await sleep(delay);
      history.push(routes.my_account)
    },

    go_to_my_account_setup: async (delay:number=default_delay) => {
      // set_page_loading(true);
      // set_entered_page(false); //leave page
      // await sleep(delay);
      history.push(routes.my_account_setup)
    },

    go_to_my_account_edit: async (delay:number=default_delay) => {
      // set_page_loading(true);
      // set_entered_page(false); //leave page
      // await sleep(delay);
      history.push(routes.my_account_edit)
    },

    go_to_my_assets: async (delay:number=default_delay) => {
      set_page_loading(true);
      set_entered_page(false); //leave page
      await sleep(delay);
      history.push(routes.my_assets);
    },

    go_to_profile: async (username:string, delay:number=default_delay) => {
      set_page_loading(true);
      set_entered_page(false); //leave page
      await sleep(delay);
      history.push(routes.profile.replace(':username', username));
    },

    go_to_new_orders: async (delay:number=default_delay) => {
      set_page_loading(true);
      set_entered_page(false); //leave page
      await sleep(delay);
      history.push(routes.new_orders);
    },

    go_to_orders_status: async (delay:number=default_delay) => {
      set_page_loading(true);
      set_entered_page(false); //leave page
      await sleep(delay);
      history.push(routes.orders_status);
      set_page_loading(false);
    },

    go_to_my_assignments: async (delay:number=default_delay) => {
      set_page_loading(true);
      set_entered_page(false); //leave page
      await sleep(delay);
      history.push(routes.my_assignments);
      set_page_loading(false);
    },

    go_to_about:    () => {history.push(routes.about)},
    go_to_our_team: () => {history.push(routes.team)},
    go_to_investors_relations: () => {history.push(routes.investors_relations)},
    go_to_press:    () => {history.push(routes.press)},
    go_to_privacy:  () => {history.push(routes.privacy)},
    go_to_trust_and_safety:    () => {history.push(routes.trust_and_safety)},
    go_to_terms:    () => {history.push(routes.terms)},
    go_to_help:     () => {history.push(routes.help)},
    go_to_contact:  () => {history.push(routes.contact)},


    go_to_driver_without_vehicle_service: () => {history.push(routes.services_driver_without_vehicle)},
    go_to_driver_with_vehicle_service:    () => {history.push(routes.services_driver_with_vehicle)},
    go_to_vehicle_rent_and_share_service: () => {history.push(routes.services_vehicle_rent_and_share)},
    go_to_services_full_service:          () => {history.push(routes.services_full_service)},
    go_to_collaboration_service:          () => {history.push(routes.services_collaboration)},


    go_to_gotrackers_carriers: () => {history.push(routes.gotrackers_carriers)},
    go_to_gotrackers_driver_without_vehicle: () => {history.push(routes.gotrackers_driver_without_vehicle)},
    go_to_gotrackers_driver_with_vehicle: () => {history.push(routes.gotrackers_driver_with_vehicle)},
    go_to_gotrackers_vehicle_owner: () => {history.push(routes.gotrackers_vehicle_owner)},
    go_to_gotrackers_list_yourself: () => {history.push(routes.gotrackers_list_yourself)},
    go_to_gotrackers_how_it_works: () => {history.push(routes.gotrackers_how_it_works)},
    go_to_gotrackers_questions: () => {history.push(routes.gotrackers_questions)},
    go_to_gotrackers_how_to_earn_money: () => {history.push(routes.gotrackers_how_to_earn_money)},
    go_to_gotrackers_insurance_and_protection: () => {history.push(routes.gotrackers_insurance_and_protection)},
    go_to_gotrackers_gold_Tracker_benefits: () => {history.push(routes.gotrackers_gold_Tracker_benefits)},
    go_to_gotrackers_career_and_placement: () => {history.push(routes.gotrackers_career_and_placement)},


    go_to_shippers_how_it_works: () => {history.push(routes.shippers_how_it_works)},
    go_to_shippers_questions: () => {history.push(routes.shippers_questions)},
    go_to_shippers_insurance_and_protection: () => {history.push(routes.shippers_insurance_and_protection)},


    go_to_admin: async (delay:number=default_delay) => {
      set_page_loading(true);
      set_entered_page(false); //leave page
      await sleep(delay);
      history.push(routes.admin)
    },

    go_to_gravatar: () => open_tab(gravatar_url),

    go_to_edit_user: (user_id) => {history.push(routes.user_update.replace(':user_id', user_id))},

    go_to_driver_update: (user_id, driver_id) => {
      history.push(
        routes.user_driver_update
          .replace(':user_id', user_id)
          .replace(':driver_id', driver_id)
      )
    },

    go_to_new_user: () => {history.push(routes.users_create)},

  }
}