import React, {useContext} from 'react'
import {mobile_size} from '../../variables/variables'
import context from '../../context'
import Fade from '@material-ui/core/Fade'
import Zoom from '@material-ui/core/Zoom'
import VisibilityTrigger from '../../components/VisibilityTrigger'
import cover from '../../assets/images/home_page/cover.jpg'
import HeaderBackground from '../../components/landing_page/HeaderBackground'


export default ({title, text, backgroundImage=cover, darken=false}) => {
  const width: number = useContext(context.size).size.width;
  const {entered_page} = useContext(context.entered_page);
  return (
    <HeaderBackground backgroundImage={backgroundImage} darken={darken}>
      <VisibilityTrigger delay={3500}>{({triggered}) =>
        <Zoom in={triggered && entered_page} timeout={1200}>
          <h1 style={{
            margin: 0,
            fontSize: width > mobile_size ? 80 : 40,
            padding: 20,
            paddingTop: width > mobile_size ? 120 : 20,
            paddingBottom: 0,
            fontWeight: 100,
            color: 'rgba(255, 255, 255, 0.8)',
            textAlign: 'center',
            /* transform: translateY(10px); */
            fontFamily: 'Open Sans',
          }}>
            {title}
          </h1>
        </Zoom>
      }</VisibilityTrigger>
      <VisibilityTrigger delay={4000}>{({triggered}) =>
        <Fade in={triggered && entered_page} timeout={2500}>
          <h3 style={{
            margin: 0,
            fontSize: width > mobile_size ? 24 : 13,
            padding: 10,
            paddingTop: 0,
            paddingBottom: width > mobile_size ? 50 : 20,
            fontWeight: 100,
            color: 'white',
            textAlign: 'center',
            /* transform: translateY(10px); */
            fontFamily: 'Open Sans',
          }}>
            {text}
          </h3>
        </Fade>
      }</VisibilityTrigger>
    </HeaderBackground>
  )
}

