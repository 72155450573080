import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  h6: {
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
    textAlign: 'center',
  },
});

export default ({...props}) => {
  return (
    <Grid container justify="center">
      <Grid item>
        <h6 className={useStyles().h6} {...props}/>
      </Grid>
    </Grid>
  )
};