import React, {useEffect, useState, useContext} from "react";
import {Formik, Form, Field} from 'formik';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import FaceIcon from '@material-ui/icons/Face';
import Tooltip from '../../Tooltip';
import sleep from "../../../utils/sleep";
import context from '../../../context'
import {Input} from '../../custom/CustomInput/FormikInput'
import Avatar from "../../custom/CustomUpload/Avatar";
import ids from "../../../ids";
import type {Function, User, Driver} from "../../../types";
import placeholder_avatar from '../../../assets/images/avatars/driver-svgrepo-com.svg'
import email_icon from '../../../assets/images/icons/email.svg'
import { makeStyles } from '@material-ui/core/styles';
import {animated, useSpring} from "react-spring";
import flag from "../../../assets/images/icons/flag.svg";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import Grow from "@material-ui/core/Grow";
import region_icon from "../../../assets/images/icons/region.svg";
import city from "../../../assets/images/icons/city.svg";
import BusinessIcon from '@material-ui/icons/Business';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import WorkIcon from '@material-ui/icons/Work';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import birthdate from '../../../assets/images/icons/birthday-cake-cake-svgrepo-com.svg'
import DatePicker from '../../custom/CustomInput/DatePicker'
import Typography from "@material-ui/core/Typography";
import InfoIcon from '@material-ui/icons/Info';

export const useStyles = makeStyles({
  close: {maxHeight: 0},
  open: {maxHeight: 10000},
});

interface Values {
  email: string,
  home_country: string,
  home_region: string,
  home_city: string,
  phone_number: string,
  company: string,
  job: string,
  personal_id: string,
  birthdate: string,
  experience_start_date: string,
}

const validate = values => {
  const errors: Partial<Values> = {};

  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
  }

  return errors;
};

export default ({template, onSuccess, open, set_formProps}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const user: User = template;

  const [image, set_image] = useState(user&&user.image_url_hd?user.image_url_hd:null);
  const [description, set_description] = useState(user?user.description:'');

  const maxWidth = mobile ? 280 : 400;

  const initialValues = {
    email: template.email?template.email:'',
    home_country: template.home_country?template.home_country:'',
    home_region: template.home_region?template.home_region:'',
    home_city: template.home_city?template.home_city:'',
    date: template.birthdate?template.birthdate:undefined,
    phone_number: template.phone_number?template.phone_number:'',
    company: template.company?template.company:'',
    job: template.job?template.job:'',
    personal_id: template.personal_id?template.personal_id:'',
    birthdate: template.birthdate?template.birthdate:'',
    experience_start_date: template.experience_start_date?template.experience_start_date:'',
  };

  const onSubmit = async (values, {setSubmitting}) => {
    // set_loading(true);
    await sleep(1000);
    const res = true;
      // const res = await edit_account_info({
    //   set_user,
    //   full_name: values.full_name,
    //   email: values.country,
    //   description: values.region,
    //   city: values.city,
    //   phone_number: values.phone,
    //   company: values.company,
    //   job: values.job,
    // });
    // set_loading(false);
    // if (res) {
    //   set_user({...user, ...res});
    //   await sleep(1000);
    //   Swal.fire({
    //     type: 'success',
    //     title: 'Account Updated',
    //     text: 'Your account is up to date! happy GOtracking',
    //     confirmButtonText: 'OK',
    //     timer: 6000,
    //   });
    //   await sleep(800);
    // }
    setSubmitting(false);
    !!res && onSuccess();
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >{({submitForm, isSubmitting, values, setFieldValue, handleChange, handleBlur}) => {

      const classes = useStyles();
      const [region_style, set_region_style] = useState({height: 0, marginTop: 0, from: {height: 0, marginTop: 0}});
      const regionStyle = useSpring(region_style);

      useEffect(() => {
        set_formProps({submitForm, isSubmitting});
      }, [submitForm, isSubmitting]);

      useEffect(() => {
        const root = document.getElementById(ids.new_driver_profile_form_root);
        open
          ? root.className = classes.open
          : root.className = classes.close;
      }, [open]);

      useEffect(() => {
        if (!values.country) {
          set_region_style({height: 0, marginTop: 0, from: {height: region_style.from.height, marginTop: region_style.from.marginTop}})
        } else {
          set_region_style({height: 41, marginTop: 30, from: {height: region_style.from.height, marginTop: region_style.from.marginTop}})
        }
      }, [values]);

      return (
        <div id={ids.new_driver_profile_form_root} style={{width:'100%', transition: 'max-height 0.5s ease-in-out'}}>
          <Grid container justify='center'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Form style={{paddingTop: 10, width: maxWidth}}>

                <Slide direction="right" timeout={1200} in={open} mountOnEnter unmountOnExit>
                  <Grid container justify='center' style={{marginBottom:10}}>
                    <div>
                      <Grid container>
                        <Typography variant="h6" gutterBottom style={{marginBottom:0, textAlign:'center'}}>
                          Personal Details
                        </Typography>
                        <span style={{width:0}}>
                        <Grid container style={{height:'100%', marginLeft:5}} direction="row" alignItems="center">
                          <Tooltip title={
                            <p style={{paddingLeft:10, paddingRight:10, maxWidth:250, fontSize:14, fontWeight:300}}>
                              this information is not<br/>
                              mandatory, you can fill it<br/>
                              out later.<br/>
                              <br/>
                              but be sure to take<br/>
                              a few moments<br/>
                              and fill out your information<br/>
                              so we can get you validated<br/>
                              and win extra points.
                            </p>
                          }>
                            <InfoIcon fontSize='small'/>
                          </Tooltip>
                        </Grid>
                      </span>
                      </Grid>
                    </div>
                  </Grid>
                </Slide>

                <Slide direction="right" timeout={1000} in={open} mountOnEnter unmountOnExit>
                  <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                    <Grid item>
                      <Tooltip title={'fill in your email address'}>
                        <img src={email_icon} alt="" style={{width: 20, height: 20, margin: 2}}/>
                      </Tooltip>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                      <Field component={Input} label="email" name="email" type="email"/>
                    </Grid>
                  </Grid>
                </Slide>

                <Slide direction="right" timeout={1200} in={open} mountOnEnter unmountOnExit>
                  <div style={{marginBottom: 20, marginTop: 30}}>
                    <div style={{display: 'flex'}}>
                      <Tooltip title={
                        'select your country ' +
                        'so we can better address ' +
                        'your needs and connect you ' +
                        'with opportunities near your vicinity.'}>
                        <img src={flag} alt="" style={{width: 20, height: 20, margin: 2, marginTop: 10}}/>
                      </Tooltip>
                      <CountryDropdown
                        style={{
                          width: maxWidth - 30,
                          marginLeft: 7,
                          padding: 10,
                          fontSize: '1rem',
                          color: '#575757',
                        }}
                        name="country"
                        value={values.country}
                        onChange={(_, e) => handleChange(e)}
                        onBlur={handleBlur}
                      />
                    </div>

                    <Grow in={!!values.country} timeout={1000}>
                      <animated.div style={regionStyle}>
                        <div style={{display: 'flex'}}>
                          <Tooltip title={
                            'some countries have regions or states within them. ' +
                            'please select your region if you have one.'}>
                            <div style={{width: 20, height: 20, margin: 2, marginTop: 10}}>
                              <img src={region_icon} alt="" style={{width: 40, height: 40, position: 'relative', top: -7, right: 10}}/>
                            </div>
                          </Tooltip>
                          <RegionDropdown
                            style={{
                              width: maxWidth - 30,
                              marginLeft: 7,
                              padding: 10,
                              fontSize: '1rem',
                              color: '#575757',
                            }}
                            name="state"
                            country={values.country}
                            value={values.context}
                            onChange={(_, e) => handleChange(e)}
                            onBlur={handleBlur}
                          />
                        </div>
                      </animated.div>
                    </Grow>
                  </div>
                </Slide>

                <Slide direction="right" timeout={1400} in={open} mountOnEnter unmountOnExit>
                  <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                    <Grid item>
                      <Tooltip title={
                        'write down where you live. ' +
                        'we consider this information private and don\'t sell/share it.'}>
                        <img src={city} alt="" style={{width: 20, height: 20, margin: 2}}/>
                      </Tooltip>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                      <Field
                        component={Input}
                        label="home town"
                        name="city"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Slide>

                <Slide direction="right" timeout={1400} in={open} mountOnEnter unmountOnExit>
                  <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                    <Grid item>
                      <Tooltip title={
                        'write down your birth date. ' +
                        'knowing your age can help us figure your veterancy.'}>
                        <img src={birthdate} alt="" style={{width: 20, height: 20, margin: 2}}/>
                      </Tooltip>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                      <Field
                        component={DatePicker}
                        name="birthdate"
                        label="Birth Date"
                      />
                    </Grid>
                  </Grid>
                </Slide>

                <Slide direction="right" timeout={1600} in={open} mountOnEnter unmountOnExit>
                  <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                    <Grid item>
                      <Tooltip title={
                        'write down your contact phone number, ' +
                        'we may want to verify you and give you a call or an SMS. ' +
                        'other potential clients may want to contact you this way. ' +
                        'your phone number is private to you and will not be shown ' +
                        'unless you specifically ask to share it.'}>
                        <ContactPhoneIcon style={{color: '#629ad4'}}/>
                      </Tooltip>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                      <Field
                        component={Input}
                        label="phone number"
                        name="phone_number"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Slide>

                <Slide direction="right" timeout={1800} in={open} mountOnEnter unmountOnExit>
                  <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 30}}>
                    <Grid item>
                      <Tooltip title={
                        'write down your company ' +
                        'if you have one or represent one in any way, shape or form.'}>
                        <BusinessIcon style={{color: '#d47b90'}}/>
                      </Tooltip>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                      <Field
                        component={Input}
                        label="company"
                        name="company"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Slide>

                <Slide direction="right" timeout={2000} in={open} mountOnEnter unmountOnExit>
                  <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 30}}>
                    <Grid item>
                      <Tooltip title={
                        'tell us what is your job title ' +
                        'so we can better understand what you need.'}>
                        <WorkIcon style={{color: '#d46c56'}}/>
                      </Tooltip>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                      <Field
                        component={Input}
                        label="job title"
                        name="job"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Slide>

              </Form>
            </MuiPickersUtilsProvider>
          </Grid>
        </div>
      )
    }}</Formik>
  )
};