import React, {useCallback, useContext, useState} from "react";
import context from '../../context'
import Typography from '@material-ui/core/Typography';
import {CopyAndCreateNewAssignmentAction, DeleteAssignmentAction, UpdateAssignmentAction, AssignmentActions, CancelAction, CreateAssignmentAction, CreateOrderAction, FindAmatchAction, ResetAction} from './AssignmentActions'
import {useAssignmentsStyles} from "./styles";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import storage_ids from "../../storage_ids";
import {getStore} from "../../hooks/useStore";
import {do_nothing_function} from "../../utils/do_nothing_function";
import {entity_types} from "./variables";
import uuid from "../../utils/uuid";
import Fade from "@material-ui/core/Fade/Fade";
import Grid from "@material-ui/core/Grid";
import {SelectStartAndEndTime} from "../custom/CustomDateTime/SelectStartAndEndTime";
import GoogleMapsInput from "../custom/CustomInput/GoogleMapsInput";
import {DistributionPoints} from "./DistributionPoints";
import {Service} from "./Service";
import {AddService} from "./AddService";
import {MissionSteps} from "./MissionSteps";
import {Notes} from "./Notes";
import {CreateAssigmentNameDialog} from './CreateAssigmentNameDialog'
import sleep from '../../utils/sleep'
import Swal from 'sweetalert2'
import Collapse from '@material-ui/core/Collapse';
import {TimeAndPlace} from "./TimeAndPlace";
import Measure from "react-measure";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useTheme} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {useMyOrdersStore} from "../../stores/useOrdersStores";

export const AssignmentHead = ({data, collapse, toggleCollapse, onRemove, onCopy, onCreateOrder, onFindAmatch}) => {
  return collapse
    ?
    <Grid container justify={'center'} alignItems={'center'} style={{cursor:'pointer', padding: 30}} onClick={toggleCollapse}>
      <ExpandLessIcon/>
      <Typography variant="h4" gutterBottom style={{margin: '0 10px'}} >
        <span>משימה:</span> <span>{data.name}</span>
      </Typography>
    </Grid>
    :
    <>
      <Grid container style={{cursor:'pointer', marginBottom:10}} onClick={toggleCollapse} alignItems={'center'}>
        <ExpandMoreIcon/>
        <Typography variant="h5" gutterBottom style={{margin: '0 10px'}} >
          <span>משימה:</span> <span>{data.name}</span>
        </Typography>
      </Grid>
      <Grid container justify={'flex-end'}>
        <DeleteAssignmentAction onRemove={onRemove}/>
        <CopyAndCreateNewAssignmentAction onCopy={onCopy}/>
        <CreateOrderAction onCreateOrder={onCreateOrder}/>
        <FindAmatchAction onFindAmatch={onFindAmatch}/>
      </Grid>
    </>

};

export const Assignment = ({data, ...props}) => {
  const width: number = useContext(context.size).width;
  const [size, set_size] = useState({width:0, height: 0});
  const mobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  const classes = useAssignmentsStyles({...useContext(context.rtl), mobile, width});

  const {user} = useContext(context.user);
  const {signin, set_signin} = useContext(context.signin);

  const [collapse, set_collapse] = useState(false);

  const createOrder = useMyOrdersStore(state => state.createOrder);

  const assignments = getStore(storage_ids.assignments);

  const {
    name, start_time, end_time,
    from_location, to_location,
    services, notes, steps,
    distribution_points,
  } = data;

  const [new_assignment_name_dialog, set_new_assignment_name_dialog] = useState(false);

  const toggleCollapse = () => set_collapse(!collapse);

  const onCreateOrder = async () => {
    if (!user) {
      set_signin({...signin, open: true});
      return false;
    }

    // validations here

    // api here
    await sleep(2000);
    const res = {_id: `${uuid()}`};

    createOrder({
      name,
      services,
      notes,
      steps,
      distribution_points,
      start_time,
      end_time,
      from_location,
      to_location,
      assignment_id: data._id,
      _id: res._id,
    });

    Swal.fire({
      type: 'success',
      title: 'מצוין!',
      text: `הזמנתך נשמרה בהצלחה, אתה יכול עכשיו לראות את סטטוס ההזמנה בדף הסטטוס של ההזמנות.`,
      confirmButtonText: 'OK',
    });

    return true;
  };

  const onUpdateAssignment = do_nothing_function;

  return (
    <>
      <div style={{width:'100%'}}>
        <Measure bounds onResize={({bounds})=>set_size(bounds)}>{({measureRef})=>(
          <div className={classes.assignment_container} ref={measureRef} {...props}>
            <AssignmentHead
              data={data} collapse={collapse}
              toggleCollapse={toggleCollapse}
              onCopy={onUpdateAssignment}
              onRemove={onUpdateAssignment}
              onCreateOrder={onCreateOrder}
              onFindAmatch={onCreateOrder}
            />
            <Collapse in={collapse}>
              <TimeAndPlace
                size={size}
                start_time={start_time} set_start_time={do_nothing_function}
                end_time={end_time} set_end_time={do_nothing_function}
                from_location={from_location} set_from_location={do_nothing_function}
                to_location={to_location} set_to_location={do_nothing_function}
              />

              <DistributionPoints/>

              <Grid container justify={'flex-start'} className={classes.entities} spacing={mobile?1:3}>
                <Typography variant="h5" gutterBottom style={{textAlign:'start', margin:'0 12px'}}>
                  סוגי שירות
                </Typography>
                <div style={{flexGrow: 1}}/>
                <FindAmatchAction onFindAmatch={onCreateOrder} style={{height: 40, margin: 0}}/>
                <div style={{width: mobile?4:12}}/>
              </Grid>

              {services.map((service, index)=>
                <Service
                  index={index}
                  service={service}
                  key={index}
                />
              )}

              <MissionSteps/>
              <Notes/>

              <AssignmentActions>
                <DeleteAssignmentAction onRemove={onUpdateAssignment}/>
                <UpdateAssignmentAction onUpdateAssignment={onUpdateAssignment}/>
                <CopyAndCreateNewAssignmentAction onCopy={onUpdateAssignment}/>
                <CreateOrderAction onCreateOrder={onCreateOrder}/>
                <FindAmatchAction onFindAmatch={onCreateOrder}/>
              </AssignmentActions>
            </Collapse>
          </div>
        )}</Measure>
      </div>
    </>
  )
};