import React, { useEffect } from "react";
import TagManager from 'react-gtm-module'
// import ReactPixel from 'react-facebook-pixel';

const dataLayerName = 'PageDataLayer';

TagManager.initialize({
  gtmId: 'GTM-MTF9L2X',
  dataLayerName,
});
// ReactPixel.init('');

// export {ReactPixel}

export default (WrappedComponent, options = {}) => {
  const trackPage = page => {
    const tagManagerArgs = {
      dataLayer: {
        userId: '001',
        userProject: 'project',
        page,
      },
      dataLayerName,
    };
    TagManager.dataLayer(tagManagerArgs)
    // ReactPixel.pageView();
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};