import React, {useContext, useEffect, useState} from "react";
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Tooltip from '../../../Tooltip';
import sleep from "../../../../utils/sleep";
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close'
import InputAdornment from "@material-ui/core/InputAdornment";
import license_icon from '../../../../assets/images/icons/license_icon.svg'
import {Field} from "formik";
import DatePicker from '../../../custom/CustomInput/DatePicker'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DocumentPhoto, {defaultState as defaultPhotoState} from '../../../custom/CustomUpload/DocumentPhoto'
import ScheduleIcon from '@material-ui/icons/Schedule';
import UpdateIcon from '@material-ui/icons/Update';
import InputNumber from "../../../custom/CustomInput/InputNumber";
import { withStyles } from '@material-ui/core/styles';
import Permits from './Permits'
import Limitations from './Limitations'
import {validPhotoExtension, validSizeMB, validFileExtensions} from "../../../../validations/file_validations";
import type {License, Function, FileChangeProps} from '../../../../types'
import FormHelperText from "@material-ui/core/FormHelperText";
import {ExpansionPanelSummary} from "./Expansion";
import {KeyboardDatePicker} from '@material-ui/pickers'
import assert from '../../../../utils/assert'
import context from '../../../../context'
import type {Language} from '../../../../types'

const GreenCheckbox = withStyles({
  root: {
    color: '#67d580',
    '&$checked': {
      color: '#2bca37',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

interface Props {
  license: License,
  onCancel: Function,
  onSave: Function,
  open: boolean,
}

const filename_url_err_msg = "url suffix filename pattern does not math https://domain/{filename}.{extension}";

const get_filename_from_url = (url:string):Array => {
  try {
    const arr = url.split('/');
    assert(arr.length > 0, filename_url_err_msg);

    const fullfilename = arr[arr.length-1].split('.');
    assert(fullfilename.length === 2, filename_url_err_msg);

    const name = fullfilename[0];
    const extension = fullfilename[1].toLowerCase();
    assert(validFileExtensions.photos.includes(extension), `${extension} is not a valid file extension, please use one of ${validFileExtensions.photos}`);
    assert(name.length > 0 && name.length < 65, `filename ${name} is too long`);

    return [name, extension]

  } catch (e) {
    console.log(e)
  }
  return [false, false]
};

const useSetNewLicense = (license: Object, set_new_license:Function) => {
  useEffect(() => {
    const update = {...license};
    const frontside_link = update.frontside_link;
    const backside_link = update.backside_link;

    update.frontside_link = defaultPhotoState();
    update.backside_link = defaultPhotoState();

    if (typeof frontside_link === 'string' && frontside_link.startsWith('https://')) {
      const [name, extension] = get_filename_from_url(frontside_link);
      if (name !== false) {
        update.frontside_link.url = frontside_link;
        update.frontside_link.name = name;
        update.frontside_link.extension = extension;
      }
    }

    if (typeof backside_link === 'string' && frontside_link.startsWith('https://')) {
      const [name, extension] = get_filename_from_url(backside_link);
      if (name !== false) {
        update.backside_link.url = backside_link;
        update.backside_link.name = name;
        update.backside_link.extension = extension;
      }
    }

    set_new_license(update)
  }, []);
};

const text = {
  eng: {
    license: 'license type',
    add: 'add',
    cancel: 'cancel',
    tooltip: 'type in your license type: A/A1/A2/B/C/C1/D/D1/D2/D3/E...',

    license_number: 'license number',
    license_number_tooltip: 'type in your license number',

    personal_id: 'personal ID',
    personal_id_tooltip: 'type in your personal identification number',

    LicenseFrontSide: 'License Front Side',
    LicenseBackSide: 'License Back Side',

    select_image: 'select image',

    temporary_paper_license: "temporary paper license",

    manual_license: "manual license",

    license_vehicle_type: "license vehicle type",
    license_vehicle_type_tooltip: "type in your vehicle type",

    license_issued_tooltip: 'write down when your license was issued to you',
    license_issued: "",

    license_expiration_tooltip: 'write down when your license expires',
    license_expiration: "license expiration",

    dateFormat: "dd/MM/yyyy",

    vehicle_maximum_weight: 'vehicle maximum weight',
    vehicle_maximum_weight_unit: ' ton',

    practical_experience: 'practical experience',
    practical_experience_unit: ' years',

    seniority: 'seniority',
    seniority_unit: ' years',
  },

  heb: {
    license: 'סוג רישיון',
    add: 'הוסף',
    cancel: 'בטל',
    tooltip: 'רשום את סוג הרישיון המתאים: A/A1/A2/B/C/C1/D/D1/D2/D3/E...',

    license_number: 'מספר רישיון',
    license_number_tooltip: 'רשום את מספר הרישיון',

    personal_id: 'תעודת זהות',
    personal_id_tooltip: 'רשום את מספר תעודת הזהות של בעל הרישיון',

    LicenseFrontSide: 'תצלום קידמי של הרישיון',
    LicenseBackSide: 'תצלום אחורי של הרישיון',

    select_image: 'בחר תמונה',

    temporary_paper_license: "רישיון זמני",

    manual_license: "רישיון ידני",

    license_vehicle_type: "סוג רישיון רכב",
    license_vehicle_type_tooltip: "רשום את סוג הרכב",

    license_issued_tooltip: 'רשום מתי קיבלת את הרישיון',
    license_issued: "תאריך הנפקת רישיון",

    license_expiration_tooltip: 'רשום מתי צריך לחדש את הרישיון',
    license_expiration: "תאריך תפוגת רישיון",

    dateFormat: "שנה/חודש/יום",

    vehicle_maximum_weight: 'משקל רכב מקסימלי',
    vehicle_maximum_weight_unit: ' טון',

    practical_experience: 'ניסיון מעשי',
    practical_experience_unit: ' שנים',

    seniority: 'ותק',
    seniority_unit: ' שנים',
  },

};

export default (props:Props) => {
  const language: Language = useContext(context.language).language;

  const license = props.license;
  const onCancel = props.onCancel;
  const onSave = props.onSave;
  const open = props.onSave;

  const state = useState(null);
  const new_license: License = state[0];
  const set_new_license: Function = state[1];

  useSetNewLicense(license, set_new_license);

  const onTextChange = (field:string) => (event) => set_new_license({...new_license, [field]: event.target.value});
  const set_permits = (permits) => set_new_license({...new_license, permits});
  const set_limitations = (limitations) => set_new_license({...new_license, limitations});

  const onFrontLinkChange = (props:FileChangeProps) => {
    const update = {...new_license};
    const frontside_link = {};
    frontside_link.extension = props.extension;
    frontside_link.name = props.name;
    frontside_link.file = props.file;
    frontside_link.url = props.url;
    update.frontside_link = frontside_link;
    set_new_license(update)
  };

  const onBackLinkChange = (props:FileChangeProps) => {
    const update = {...new_license};
    const backside_link = {};
    backside_link.extension = props.extension;
    backside_link.name = props.name;
    backside_link.file = props.file;
    backside_link.url = props.url;
    update.backside_link = backside_link;
    set_new_license(update)
  };

  const handleCancel = () => {
    return onCancel();
  };

  const handleSave = () => {
    return onSave(license, new_license);
  };

  if (new_license === null)
    return null;

  return (
    <div>

      <Slide direction="right" timeout={1000} in={open} mountOnEnter unmountOnExit>
        <Grid container spacing={1} alignItems="flex-end" style={{padding: 15}}>
          <Grid item>
            <Tooltip title={text[language.value].tooltip}>
              <img src={license_icon} alt={''} style={{width:32, height: 32, position:'relative', top:5}}/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label={text[language.value].license}
              name={'license-type'}
              type='text'
              fullWidth
              placeholder={'A/A1/A2/B/C/C1/D/D1/D2/D3/E...'}
              value={new_license.type}
              onChange={onTextChange('type')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{display:'flex'}}>
                      {new_license.type &&
                        <div>
                          <IconButton aria-label="save license" onClick={handleSave}>
                            <CheckIcon/>
                          </IconButton>
                          <FormHelperText style={{textAlign:'center'}}>
                            {text[language.value].add}
                          </FormHelperText>
                        </div>
                      }
                      <div>
                        <IconButton aria-label="cancel" onClick={handleCancel}>
                          <CloseIcon/>
                        </IconButton>
                        <FormHelperText style={{textAlign:'center'}}>
                          {text[language.value].cancel}
                        </FormHelperText>
                      </div>
                    </div>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Slide>

      <Slide direction="right" timeout={1100} in={Boolean(new_license.type) && open} mountOnEnter unmountOnExit>
        <Grid container spacing={1} alignItems="flex-end" style={{padding: 15}}>
          <Grid item>
            <Tooltip title={text[language.value].license_number_tooltip}>
              <img src={license_icon} alt={''} style={{width:32, height: 32, position:'relative', top:5}}/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              label={text[language.value].license_number}
              name={'license-number'}
              type='text'
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder={'1234567'}
              value={new_license.license_number}
              onChange={onTextChange('license_number')}
            />
          </Grid>
        </Grid>
      </Slide>

      <Slide direction="right" timeout={1100} in={Boolean(new_license.type && new_license.license_number && open)} mountOnEnter unmountOnExit>
        <Grid container spacing={1} alignItems="flex-end" style={{padding: 15}}>
          <Grid item>
            <Tooltip title={text[language.value].personal_id_tooltip}>
              <img src={license_icon} alt={''} style={{width:32, height: 32, position:'relative', top:5}}/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              label={text[language.value].personal_id}
              name={'personal-id'}
              type='text'
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder={'203555666'}
              value={new_license.personal_id}
              onChange={onTextChange('personal_id')}
            />
          </Grid>
        </Grid>
      </Slide>

      <Slide direction="right" timeout={1200} in={open} mountOnEnter unmountOnExit>
        <div style={{padding:15}}>
          <legend>{text[language.value].LicenseFrontSide}</legend>
          <DocumentPhoto
            addButtonText={text[language.value].select_image}
            values={new_license.frontside_link}
            style={{marginBottom:15}}
            disableName={true}
            addButtonProps={{color: "rose", round: true}}
            changeButtonProps={{color: "rose", round: true}}
            removeButtonProps={{color: "danger", round: true}}
            validate={(file:File) => {return validSizeMB(file, 2) && validPhotoExtension(file);}}
            onChange={onFrontLinkChange}
          />

          <legend>{text[language.value].LicenseBackSide}</legend>
          <DocumentPhoto
            addButtonText={text[language.value].select_image}
            values={new_license.backside_link}
            disableName={true}
            addButtonProps={{color: "rose", round: true}}
            changeButtonProps={{color: "rose", round: true}}
            removeButtonProps={{color: "danger", round: true}}
            validate={(file:File) => {return validSizeMB(file, 2) && validPhotoExtension(file);}}
            onChange={onBackLinkChange}
          />

          <FormControlLabel
            control={
              <Checkbox
                // checked={gilad}
                // onChange={handleChange}
                name="temporary-paper-license"
                // color='primary'
              />
            }
            label={text[language.value].temporary_paper_license}
          />

          <FormControlLabel
            control={
              <GreenCheckbox
                // checked={gilad}
                // onChange={handleChange}
                name="manual"
              />
            }
            label={text[language.value].manual_license}
          />
        </div>
      </Slide>

      <Slide direction="right" timeout={1300} in={Boolean(new_license.type && new_license.license_number && open)} mountOnEnter unmountOnExit>
        <Grid container spacing={1} alignItems="flex-end" style={{padding: 15}}>
          <Grid item>
            <Tooltip title={text[language.value].license_vehicle_type_tooltip}>
              <LocalShippingIcon/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              label={text[language.value].license_vehicle_type}
              name={'license-vehicle-type'}
              type='text'
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder={'1234567'}
              value={new_license.vehicle_type}
              onChange={onTextChange('vehicle_type')}
            />
          </Grid>
        </Grid>
      </Slide>

      <Slide direction="right" timeout={1400} in={Boolean(new_license.type && new_license.license_number && open)} mountOnEnter unmountOnExit>
        <Grid container spacing={1} alignItems="flex-end" style={{padding: 15}}>
          <Grid item>
            <Tooltip title={text[language.value].license_issued_tooltip}>
              <ScheduleIcon/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <KeyboardDatePicker
              margin="normal"
              label={text[language.value].license_issued}
              format={text[language.value].dateFormat}
              fullWidth
              value={new_license.issued}
              onChange={(date) => set_new_license({...new_license, issued: date})}
              KeyboardButtonProps={{'aria-label': 'license issued'}}
            />
          </Grid>
        </Grid>
      </Slide>

      <Slide direction="right" timeout={1500} in={Boolean(new_license.type && new_license.license_number && open)} mountOnEnter unmountOnExit>
        <Grid container spacing={1} alignItems="flex-end" style={{padding: 15}}>
          <Grid item>
            <Tooltip title={text[language.value].license_expiration_tooltip}>
              <UpdateIcon/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <KeyboardDatePicker
              margin="normal"
              label={text[language.value].license_expiration}
              format={text[language.value].dateFormat}
              fullWidth
              value={new_license.expiration}
              onChange={(date) => set_new_license({...new_license, expiration: date})}
              KeyboardButtonProps={{'aria-label': 'license expiration'}}
            />
          </Grid>
        </Grid>
      </Slide>

      <Slide direction="right" timeout={1600} in={Boolean(new_license.type && new_license.license_number && open)} mountOnEnter unmountOnExit>
        <Grid container spacing={1} style={{marginBottom: 0, marginLeft: 28, padding: 15}}>
          <Grid item xs={12}>
            <InputNumber
              style={{maxWidth: 200, marginBottom:10}}
              value={new_license.weight}
              setValue={(value) => {
                let new_value = Number(value);

                if (new_value < 0.01)
                  new_value = 0.01;

                if (new_value > 100000)
                  new_value = 100000;

                set_new_license({...new_license, weight:new_value});
              }}
              step={1}
              min={0}
              max={100000}
              label={text[language.value].vehicle_maximum_weight}
              suffix={text[language.value].vehicle_maximum_weight_unit}
              iconSize={'small'}
            />
          </Grid>

          <Grid item xs={12}>
            <InputNumber
              style={{maxWidth: 200, marginBottom:10}}
              value={new_license.practical_experience}
              setValue={(value) => {
                let new_value = Number(value);

                if (new_value < 0)
                  new_value = 0;

                if (new_value > 200)
                  new_value = 99;

                set_new_license({...new_license, practical_experience:new_value});
              }}
              step={1}
              min={0}
              max={200}
              label={text[language.value].practical_experience}
              suffix={text[language.value].practical_experience_unit}
              iconSize={'small'}
            />
          </Grid>

          <Grid item xs={12}>
            <InputNumber
              style={{maxWidth: 200, marginBottom:10}}
              value={new_license.seniority}
              setValue={(value) => {
                let new_value = Number(value);

                if (new_value < 0)
                  new_value = 0;

                if (new_value > 200)
                  new_value = 99;

                set_new_license({...new_license, seniority:new_value});
              }}
              step={1}
              min={0}
              max={200}
              label={text[language.value].seniority}
              suffix={text[language.value].seniority_unit}
              iconSize={'small'}
            />
          </Grid>
          {/*<FormHelperText>set a price range for your service</FormHelperText>*/}
        </Grid>
      </Slide>

      <Permits open={Boolean(new_license.type && new_license.license_number && open)} permits={new_license.permits} set_permits={set_permits}/>

      <Limitations open={Boolean(new_license.type && new_license.license_number && open)} limitations={new_license.limitations} set_limitations={set_limitations}/>

    </div>
  )
};