import React, {useState, useContext} from "react";
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import {LinearProgress} from '@material-ui/core';
import context from '../../context'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import EditPersonalInfoForm from '../forms/account/EditPersonalInfoForm'
import NewEmailForm from '../forms/account/NewEmailForm'
import ChangePasswordForm from '../forms/account/ChangePasswordForm'
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Close";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default () => {
  const links = useContext(context.links);
  const {entered_page} = useContext(context.entered_page);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const maxWidth = mobile?450:550;
  const [loading, set_loading] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Slide direction="up" timeout={1300} in={entered_page} mountOnEnter unmountOnExit>
    <div>
      <Fade in={entered_page} timeout={1300}>
        <Paper elevation={mobile?0:3} style={{maxWidth, margin: `${mobile ? 12 : 48}px auto`, padding: mobile?0:'2rem'}}>
          <div style={{position:'relative'}}>
            <IconButton
              aria-label="cancel"
              style={{position:'absolute', right:0, top: 0}}
              onClick={links.go_to_my_account}
            >
              <CancelIcon />
            </IconButton>
          </div>
          <Fade in={entered_page} timeout={2000}>
            <div style={{marginBottom: 15}}>
              <Slide direction="down" timeout={2000} in={entered_page} mountOnEnter unmountOnExit>
                <Typography variant={'h5'} style={{textAlign: 'center', marginBottom: '2rem'}}>
                  {loading ? 'Saving Changes...' : 'Edit Account'}
                </Typography>
              </Slide>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                variant="scrollable"
                scrollButtons="on"
                centered
              >
                <Tab label="Personal Info" />
                <Tab label="New E-mail" />
                <Tab label="Change Password" />
              </Tabs>
              {loading && <LinearProgress/>}
            </div>
          </Fade>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <EditPersonalInfoForm set_loading={set_loading}/>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <NewEmailForm set_loading={set_loading}/>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <ChangePasswordForm set_loading={set_loading}/>
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </Fade>
    </div>
    </Slide>
  )
};