import React, {useMemo, useCallback} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import {uuid_generator} from '../../../utils/uuid'
import ids from '../../../ids'
import {useSetState} from "../../../hooks/useSetNestedStore";
import {useGetInputState} from "../../../hooks/useGetNestedStore";
import {useGetLabel} from "../../../hooks/useGetText";
import {useError, useInputChange} from "../CustomInput/InputStore";
import type {InputStoreProps} from "../CustomInput/InputStore";
import FormHelperText from '@material-ui/core/FormHelperText'
import {UseStore} from "zustand";


const uuids = new uuid_generator();
const uuidLabel = () => `${ids.MultiChipSelectIDs}-label-${uuids.create()}`;
const uuidSelect = () => `${ids.MultiChipSelectIDs}-select-${uuids.create()}`;
const uuidInput = () => `${ids.MultiChipSelectIDs}-input-${uuids.create()}`;


const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
    // maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const errorStyle = {color:'red'};
export const emptyStyle = {};

export function getStyles(value, selected, theme) {
  return {
    fontWeight:
      selected.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export function SelectedChip({value, onDelete}) {
  const classes = useStyles();
  const handleDelete = useCallback(()=>onDelete(value), [value]);
  return (
    <Chip
      key={value}
      label={value}
      className={classes.chip}
      // onDelete={handleDelete}
    />
  )
}


export interface MultiChipSelectProps extends InputStoreProps {
  label?: string,
  options: [string],
  selected: [string],
  onChange: Function,
  onDelete: Function,
  error?: string,
  FormControlProps?: Object,
  InputLabelProps?: Object,
  SelectProps?: Object,
  MenuItemProps?: Object,
}


export function MultiChipSelect(
  {
    label, options, selected,
    onChange, onDelete, error,
    FormControlProps={}, InputLabelProps={},
    SelectProps={}, MenuItemProps={},
  }:MultiChipSelectProps) {

  const classes = useStyles();
  const theme = useTheme();

  const labelId = useMemo(uuidLabel);
  const selectId = useMemo(uuidSelect);
  const inputId = useMemo(uuidInput);

  return (
    <FormControl className={classes.formControl} {...FormControlProps}>
      {label && <InputLabel id={labelId} style={error?errorStyle:emptyStyle} {...InputLabelProps}>{label}</InputLabel>}
      <Select
        labelId={label ? {labelId} : undefined}
        id={selectId}
        multiple
        value={selected}
        onChange={onChange}
        input={<Input id={inputId} />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) =>
              <SelectedChip
                value={value}
                onDelete={onDelete}
                key={value}
              />
            )}
          </div>
        )}
        MenuProps={MenuProps}
        {...SelectProps}
      >
        {options.map((value) =>
          <MenuItem key={value} value={value} style={getStyles(value, selected, theme)} {...MenuItemProps}>
            {value}
          </MenuItem>
        )}
      </Select>
      {error && <FormHelperText style={errorStyle}>{error}</FormHelperText>}
    </FormControl>
  );
}

export const useChipDelete = (setState:Function, selected:[string]) => {
  const container = useMemo(()=>({}), []);
  container.setState = setState;
  container.selected = selected;
  return useCallback((value:string) => container.setState(container.selected.filter(i=>i!==value)), [])
};

export interface MultiChipSelectStoreProps extends MultiChipSelectProps {
  useStore: UseStore,
  text: Object,
  path: string,
  validate?: Function,
  mandatory?: boolean,
}

export function MultiChipSelectStore(props: MultiChipSelectStoreProps) {
  const {useStore, text, path, validate, mandatory, ...rest} = props;
  const value = useStore(useGetInputState(path));
  const set = useStore(useSetState(path));
  const onChange = useInputChange(set);
  const onDelete = useChipDelete(set, value);
  const label = useGetLabel(path, text, mandatory);
  const error = useError(validate, value, mandatory);

  return <MultiChipSelect
    label={label}
    selected={value}
    onChange={onChange}
    onDelete={onDelete}
    error={error}
    {...rest}
  />
}