import React, {useContext} from "react";
import {useStyles} from "../../../assets/jss/useEditUserStyles";
import Grid from "@material-ui/core/Grid";
import {useNewUserStore, fetchStore} from "../../../stores/useNewUserStore";
import {text} from "../../../variables/text/UserEdit";
import {InputTextStore, useInputChange} from "../../custom/CustomInput/InputStore";
import {InputPasswordStore, InputSelectStore} from "../../custom/CustomInput/InputStore";
import {CheckboxStore} from "../../custom/CustomCheckbox/CheckboxStore";
import {useGetLabel} from "../../../hooks/useGetText";
import {useGetState} from "../../../hooks/useGetNestedStore";
import Avatar from "../../custom/CustomUpload/Avatar";
import ids from "../../../ids";
import {useEditUserPhoto} from "../../../api/auth/users";
import default_driver_image from '../../../assets/images/avatars/driver-svgrepo-com.svg'
import image_placeholder from '../../../assets/images/image_placeholder.jpg'
import {useSetState} from "../../../hooks/useSetNestedStore";
import {MultiChipSelectStore} from '../../custom/CustomMultiSelect/MultiChipSelect'


export const Item = ({...props}) => {
  const classes = useStyles();
  return (
    <Grid item>
      <div className={classes.item} {...props}/>
    </Grid>
  )
};

export const TextItem = ({path, ...props}) => {
  return (
    <Item>
      <InputTextStore
        useStore={useNewUserStore}
        path={path}
        text={text}
        {...props}
      />
    </Item>
  )
};

export const PasswordItem = ({path, ...props}) => {
  return (
    <Item>
      <InputPasswordStore
        useStore={useNewUserStore}
        path={path}
        text={text}
        {...props}
      />
    </Item>
  )
};

export const SelectItem = ({path, options, ...props}) => {
  return (
    <Item>
      <InputSelectStore
        useStore={useNewUserStore}
        path={path}
        text={text}
        options={options}
        {...props}
      />
    </Item>
  )
};

export const CheckboxItem = ({path, color, ...props}) => {
  return (
    <Item>
      <CheckboxStore
        useStore={useNewUserStore}
        path={path}
        text={text}
        color={color}
        {...props}
      />
    </Item>
  )
};

export const MultiChipSelectItem = ({path, options, ...props}) => {
  return (
    <Item>
      <MultiChipSelectStore
        useStore={useNewUserStore}
        path={path}
        text={text}
        options={options}
        {...props}
      />
    </Item>
  )
};

export const ReadItem = ({value, label, ...props}) => {
  return (
    <Item>
      <div><b>{label}:</b></div>
      <div>{value}</div>
    </Item>
  )
};

export const ReadOnlyItem = ({path, ...props}) => {
  const value = useNewUserStore(useGetState(path));
  const label = useGetLabel(path, text);
  return (
    <Item>
      <div><b>{label}:</b></div>
      <div>{value}</div>
    </Item>
  )
};

export const UserAvatarItem = () => {
  const edit_photo = useEditUserPhoto();
  const image_url_hd = useNewUserStore(useGetState('data.image_url_hd'));
  const user_id = useNewUserStore(useGetState('data._id'));
  const label = useGetLabel('data.image_url_hd', text);
  const classes = useStyles();

  return (
    <>
      <Avatar
        id={ids.admin_edit_profile_photo}
        direction='down'
        timeout={1500}
        open={true}
        justify='center'
        image={image_url_hd ? image_url_hd : image_placeholder}
        onImage={(url: string, blob:Blob) => {
          edit_photo({user_id, file:blob})
        }}
      />
      <div className={classes.avatarLabel}><b>{label}</b></div>
    </>
  )
};

export const NewDriverAvatarItem = () => {
  const path = 'driver.image_url_hd';
  const image = useNewUserStore(useGetState(path));
  const set_image = useNewUserStore(useSetState(path));

  const label = useGetLabel(path, text);
  const classes = useStyles();

  const set = useNewUserStore(fetchStore.set);
  const onChange = (url: string, blob:Blob) => {
    set(state => {state.driver_image = {url, file:blob}});
    set_image(url)
  };

  return (
    <>
      <Avatar
        id={ids.admin_edit_driver_profile_photo}
        direction='down'
        timeout={1500}
        open={true}
        justify='center'
        image={image ? image : default_driver_image}
        onImage={onChange}
      />
      <div className={classes.avatarLabel}><b>{label}</b></div>
    </>
  )
};