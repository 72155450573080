import createStore, {UseStore} from 'zustand'
import {CreateFetcher} from "../utils/storeFetchFunctions";
import {projection} from "../variables/projections";
import produce from "immer";
import {useCallback, useContext, useEffect, useMemo} from "react";
import {useGetUser, useSetUser} from "../api/auth/users";
import {useCreateDriver, useGetDrivers, useAdminEditDriver, useAdminEditDriverPhoto} from "../api/auth/drivers";
import {useCreateVehicle, useGetVehicles} from "../api/auth/vehicles";
import context from "../context";
import Swal from "sweetalert2";
import {text} from "../variables/text/UserEdit";
import {useRouteMatch} from 'react-router-dom'
import {register} from "./stores";
import {access_levels_text} from "../variables/text/lists";
import {access_levels} from "../variables/variables";
import type {Language} from "../types";


// improve performance by fetching state
// from dynamically created functions
// functions are created on store creation time
export const fetchStore = {
  // [state[key]]: state => state[state[key]],
};


export const useEditUserStore: UseStore = createStore(
  (set, get) => CreateFetcher(fetchStore, {
    // data
    data: null,
    loading: false,
    loaded: false,

    loading_drivers: false,
    loaded_drivers: false,
    driver: projection.drivers.reduce((a,b)=> (a[b]=undefined, a),{}),
    driver_image: { url:null, file:null },
    drivers: [],

    loading_vehicles: false,
    loaded_vehicles: false,
    vehicle: projection.vehicles.reduce((a,b)=> (a[b]=undefined, a),{}),
    vehicle_image: null,
    vehicles: [],

    updating: false,
    tab: {value:'drivers', index: 0},

    // getting
    get,

    // setting
    set: fn => set(produce(fn)),
    set_field: (field:string, data:any) => get().set(state=>{state.data[field]=data}),
    useTextField: (field) => useCallback((event) => get().set_field(field, event.target.value), [field]),
    useDriverTextField: (field) => useCallback((event) => get().set(state=>{state.driver[field]=event.target.value}), [field]),
    useVehicleTextField: (field) => useCallback((event) => get().set(state=>{state.vehicle[field]=event.target.value}), [field]),

    /////////////
    // actions //
    /////////////

    onTabChange: tab => get().set(state => {state.tab = tab}),

    useGetUser: () => {
      const user_id = useRouteMatch().params.user_id;
      const call = useGetUser();
      const language: Language = useContext(context.language).language;

      useEffect(()=>{(async () => {
        const state = get();

        if (state.loading)
          return;

        state.set(state => {state.loading = true});

        const res = await call({user_id, projection: projection.user});
        if (res) {
          state.set(state => {
            state.data = res;

            state.driver.email = res.email;
            state.driver.home_country = res.country;
            state.driver.full_name = res.full_name;
            state.driver.phone_number = res.phone_number;

            const key = Object.keys(access_levels).find(key=>access_levels[key]===res.access_level);
            if (key)
              state.data.access_level = access_levels_text[language.value].map[key];
          });
          state.set(state => {state.loaded = true});
        }

        state.set(state => {state.loading = false});
      })()}, [user_id]);

      return call;
    },

    useGetDrivers: () => {
      const user_id = useRouteMatch().params.user_id;
      const call = useGetDrivers();

      useEffect(()=>{(async () => {
        const state = get();

        if (state.loading_drivers)
          return;

        state.set(state => {state.loading_drivers = true});

        const res = await call({user_id, projection: projection.drivers});
        if (res) {
          state.set(state => {state.drivers = res.data});
          state.set(state => {state.loaded_drivers = true});
        }

        state.set(state => {state.loading_drivers = false});
      })()}, [user_id]);

      return call;
    },

    useGetVehicles: () => {
      const user_id = useRouteMatch().params.user_id;
      const call = useGetVehicles();

      useEffect(()=>{(async () => {
        const state = get();

        if (state.loading_vehicles)
          return;

        state.set(state => {state.loading_vehicles = true});

        const res = await call({user_id, projection: projection.vehicles});
        if (res) {
          state.set(state => {state.vehicles = res.data});
          state.set(state => {state.loaded_vehicles = true});
        }

        state.set(state => {state.loading_vehicles = false});
      })()}, [user_id]);

      return call;
    },

    useSetUser: () => {
      const container = useMemo(()=>({}), []); // useCallback once
      container.id = useRouteMatch().params.user_id;
      container.call = useSetUser();
      container.language = useContext(context.language).language;

      return useCallback(()=>{(async () => {
        const state = get();
        state.set(state => {state.updating = true});

        const key = Object.keys(access_levels_text[container.language.value].map)
          .find(key=>access_levels_text[container.language.value].map[key]===state.data.access_level);
        const access_level = key ? access_levels[key] : state.data.access_level;

        if (await container.call({user_id: container.id, data: {...state.data, access_level}}))
          Swal.fire({
            type: 'success',
            title: text[container.language.value].update_success.title,
            text: text[container.language.value].update_success.text,
            confirmButtonText: text[container.language.value].update_success.confirmButtonText,
            timer: 6000,
          });
        state.set(state => {state.updating = false});
      })()}, []);
    },

    useCreateDriver: () => {
      const container = useMemo(()=>({}), []); // useCallback once
      container.id = useRouteMatch().params.user_id;
      container.call = useCreateDriver();
      container.editDriverPhoto = useAdminEditDriverPhoto();
      container.language = useContext(context.language).language;
      container.links = useContext(context.links);

      return useCallback(()=>{(async () => {
        const state = get();
        state.set(state => {state.updating = true});

        const res = await container.call({user_id: container.id, data: state.driver});
        if (res) {
          Swal.fire({
            type: 'success',
            title: text[container.language.value].add_driver_success.title,
            text: text[container.language.value].add_driver_success.text,
            confirmButtonText: text[container.language.value].add_driver_success.confirmButtonText,
            timer: 6000,
          });
          state.set(state => {
            state.data.drivers++;
            state.drivers.push(res);
          });
          if (state.driver_image.file !== null)
            container.editDriverPhoto({
              user_id: container.id,
              driver_id: res._id,
              file: state.driver_image.file,
            });
          container.links.go_to_driver_update(container.id, res._id);
        }

        state.set(state => {state.updating = false});
      })()}, []);
    },

    useCreateVehicle: () => {
      const container = useMemo(()=>({}), []); // useCallback once
      container.id = useRouteMatch().params.user_id;
      container.call = useCreateVehicle();
      container.language = useContext(context.language).language;

      return useCallback(()=>{(async () => {
        const state = get();
        state.set(state => {state.updating = true});

        const res = await container.call({user_id: container.id, data: state.vehicle});
        if (res) {
          Swal.fire({
            type: 'success',
            title: text[container.language.value].add_vehicle_success.title,
            text: text[container.language.value].add_vehicle_success.text,
            confirmButtonText: text[container.language.value].add_vehicle_success.confirmButtonText,
            timer: 6000,
          });
          state.set(state => {
            state.data.vehicles++;
            state.vehicles.push(res);
          })
        }

        state.set(state => {state.updating = false});
      })()}, []);
    },

  })
);

register({fetchStore, useEditUserStore});