import React, {useContext} from "react";
import context from "../../../context";
import {fetchStore, useEditUserStore} from "../../../stores/useEditUserStore";
import {text} from "../../../variables/text/UserEdit";
import {Tabs1} from "../../tabs/Tabs";
import {TabPanels} from "../../tabs/TabPanels";
import type {Language} from "../../../types";

export const Tabs = ({children}) => {
  const language: Language = useContext(context.language).language;
  const drivers = useEditUserStore(state => state.data ? state.data.drivers : 0);
  const vehicles = useEditUserStore(state => state.data ? state.data.vehicles : 0);
  const tab = useEditUserStore(fetchStore.tab);
  const onChange = useEditUserStore(fetchStore.onTabChange);
  const tabs = text[language.value].tabs({drivers, vehicles});

  return (
    <>
      <Tabs1 tabs={tabs} onChange={onChange} tab={tab}/>
      <TabPanels tabs={tabs} onChange={onChange} tab={tab}>
        {children}
      </TabPanels>
    </>
  )
};