import {useAuthCall} from "../utils/auth_calls";
import request from "axios";
import {auth_url} from "../utils/urls";
import {auth_config} from "../utils/configs";

export const useGetVehicles = () => useAuthCall(async ({user_id, projection}) => {
  return (await request.get(`${auth_url}/user/${user_id}/vehicles`, {...auth_config, params: {
      projection: JSON.stringify(projection),
    }})).data;
});

export const useCreateVehicle = () => useAuthCall(async ({user_id, data}) => {
  return (await request.post(`${auth_url}/user/${user_id}/vehicle`, {data}, auth_config)).data;
});