import React from "react";
import Grid from "@material-ui/core/Grid";
import {bigNumber} from "../../utils/beautifulNumber";
import {do_nothing_function} from '../../utils/do_nothing_function'
import IconButton from '@material-ui/core/IconButton'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {withStyles, makeStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

const GreenButton = withStyles({
  root: {
    color: green[500],
    '&:hover': {
      color: green[700],
    },
  },
})(IconButton);

const GreyButton = withStyles({
  root: {
    color: grey[500],
    '&:hover': {
      color: grey[700],
    },
  },
})(IconButton);

const useStyles = makeStyles({
  root: {width: 54, marginBottom: 15},
  text:  props => ({minHeight: 7, width:'100%', lineHeight: '7px', textAlign:'center', color: props.active ? green[500] : 'rgba(0, 0, 0, 0.54)'}),
});

export default ({price, onClick=do_nothing_function, ...props}) => {
  const active = typeof price === 'number' && price > 0;
  const classes = useStyles({active});
  const Btn = active ? GreenButton : GreyButton;
  return (
    <Grid container justify='center' className={classes.root} {...props}>
      <Btn onClick={onClick} size={'small'}>
        <EventAvailableIcon color={'inherit'} fontSize={'inherit'}/>
      </Btn>
      <small className={classes.text}>
        available
      </small>
    </Grid>
  )
}