import { makeStyles } from '@material-ui/core/styles'
import style_vars from "./style_vars";

export const text1 = {
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif',
  fontWeight: 300,
  color: 'rgba(38,38,38,1)',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: '1.5',
};

export const text2 = {
  ...text1,
  height: 24,
  margin:0,
  marginBottom:10,
  fontWeight:400,
};

export const text3 = {
  ...text2,
  fontWeight:400,
};

export const headerStyles = makeStyles({
  text1,

  text2,

  text3,

  header2: {
    margin:'auto',
    textAlign:'center',
    font: style_vars.fonts.header2_font,
    color: style_vars.colors.small_text,
    pointerEvents: 'auto',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontSize: 17,
    padding: '0',
    border: '0',
    outline: '0',
    verticalAlign: 'baseline',
    background: '0 0',
    fontFamily: style_vars.font_families.header,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
  },

  header3: {
    font: style_vars.fonts.small_header,
    pointerEvents: 'auto',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontSize: 12,
    color: style_vars.colors.small_text,
    margin: '0',
    padding: '0',
    border: '0',
    outline: '0',
    verticalAlign: 'baseline',
    background: '0 0',
    fontFamily: style_vars.font_families.header,
  },

  MiddleMediumHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    color: style_vars.colors.small_text,
    font: style_vars.fonts.text_font,
  },

  MiddleSmallHeader: {
    font: style_vars.fonts.text_font,
    pointerEvents: 'auto',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontSize: '16px',
    margin: '0',
    padding: '0',
    border: '0',
    outline: '0',
    verticalAlign: 'baseline',
    background: '0 0',
    color: style_vars.colors.small_text,
  },

  LearnMoreText: {
    font: style_vars.fonts.learn_more_font,
    color: style_vars.colors.learn_more,
    pointerEvents: 'auto',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontSize: '17px',
    fontWeight: 'normal',
    margin: '0',
    padding: '0',
    border: '0',
    outline: '0',
    verticalAlign: 'baseline',
    background: '0 0',
    fontFamily: style_vars.font_families.learn_more,
    cursor: 'pointer',
  },

  TopSmallHeader: {
    font: style_vars.fonts.top_small_header,
    color: style_vars.colors.small_text,
    fontSize: 18,
    marginBottom: 30,
    padding: '0 10px',
    textAlign: 'center',
  },

  // Paragraph: {
  //   font: style_vars.fonts.header2_font,
  //   color: style_vars.colors.learn_more,
  //   pointerEvents: 'auto',
  //   lineHeight: 'normal',
  //   letterSpacing: 'normal',
  //   textAlign: 'center',
  //   fontSize: 18,
  //   fontWeight: 'normal',
  //   margin: '0',
  //   padding: '0 10px',
  //   border: '0',
  //   outline: '0',
  //   verticalAlign: 'baseline',
  //   background: '0 0',
  //   fontFamily: style_vars.font_families.Paragraph,
  //   marginBottom: 20,
  // },

  Header: {
    color:'#333333',
  },

  Paragraph: {
    WebkitFontSmoothing: 'antialiased',
    font: '400 16px/24px "ProximaNova","Helvetica Neue",Helvetica,Arial,sans-serif',
    WebkitBoxDirection: 'normal',
    listStyle: 'none',
    listStyleImage: 'none',
    margin: '0',
    padding: '0',
    border: '0',
    outline: '0',
    fontSize: '100%',
    verticalAlign: 'baseline',
    background: '0 0',
    color: '#62646a',
    marginBottom: 20,
  },
});