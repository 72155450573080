import React, {useContext} from 'react'
import cover from '../../assets/images/wallpaper.jpg'
import {Page} from '../../wrappers/view_wrappers'
import PageHeader from '../../components/headers/PageHeader'
import PageHeaderBottom from '../../components/headers/PageHeaderBottom'
import PageHeaderBottomSlope from '../../components/headers/PageHeaderBottomSlope'
import {useLocation} from 'react-router-dom'
import SectionsLayout from '../../components/layout/SectionsLayout'
import HeadSection from '../../components/sections/HeadSection'
import context from '../../context'
import signinToViewStyles from "../../assets/jss/signinToViewStyles";


export default ({head_section_title, head_section_text, children=null, ...props}) => {
  const {signin, set_signin} = useContext(context.signin);
  const {sidebar} = useContext(context.sidebar);
  const {rtl} = useContext(context.rtl);
  const location = useLocation();
  const classes = signinToViewStyles();

  return (
    <Page>
      <div style={sidebar?rtl?{paddingRight:10}:{paddingLeft:10}:{}}>
        <PageHeader
          title={location.pathname
            .slice(1, location.pathname.length)
            .split('/').join(' ')
            .split('_').join(' ')
            .toUpperCase()
          }
          text={
            <div className={classes.signin} onClick={()=>set_signin({...signin, open: true})}>
              SIGN IN TO VIEW
            </div>
          }
          // backgroundImage={'undefined'}
          // backgroundImage={undefined}
          backgroundImage={cover}
          darken={true}
        />
        <PageHeaderBottomSlope/>
        <PageHeaderBottom/>
        <SectionsLayout {...props}>
          <HeadSection
            title={head_section_title}
            text={head_section_text}
          />
          {children}
        </SectionsLayout>
      </div>
    </Page>
  )
}

