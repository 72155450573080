import React, {useContext, useState} from "react";
import {useAssignmentsStyles} from "./styles";
import {entities, entity_icons} from "./variables";
import Zoom from "@material-ui/core/Zoom/Zoom";
import Grid from "@material-ui/core/Grid";
import Tooltip from "../Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import CancelIcon from '@material-ui/icons/Close'
import CheckIcon from "@material-ui/icons/Check";
import context from "../../context";

export const Service = ({service, index, onDeleteService, onAddRequirement, onDeleteRequirement, onRequirementChange}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  const Icon = entity_icons[service.index];
  const [new_req, set_new_req] = useState('');

  const handle_add_requirement = () => {
    onAddRequirement(new_req);
    set_new_req('');
  };

  return (
    <Zoom in={true} timeout={200 + index * 100}>
      <div className={classes.service_root}>
        <Grid container justify='flex-start' alignItems='center'>
          <Icon/>
          <div style={{fontSize: 16, padding: '0 10px'}}>{entities[service.index]}</div>
          <div style={{flexGrow: 1}}/>
          <Tooltip title={'מחק דרישה'}>
            <IconButton aria-label="delete" onClick={onDeleteService}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        </Grid>

        <div style={{fontSize: 16, padding: '10px 32px'}}>
          {service.requirements.length ? <div style={{marginBottom:20}}>{'דרישות נוספות:'}</div> : null}
          {service.requirements.map((requirement, index) =>
            <Grid container spacing={1} style={{marginBottom:20}} alignItems="flex-start" key={index}>
              <Grid item style={{flexGrow: 1}}>
                <FormControl style={{width: '100%'}}>
                  <TextField value={requirement} onChange={event=>onRequirementChange(index, event.target.value)}/>
                </FormControl>
              </Grid>
              <Grid item>
                <IconButton
                  style={{padding: 2, marginTop: 6}}
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="check"
                  aria-haspopup="true"
                  onClick={()=>onDeleteRequirement(index)}
                >
                  <CancelIcon style={{color: '#ababab85'}}/>
                </IconButton>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item>
              <FormControl>
                <TextField value={new_req} onChange={event=>set_new_req(event.target.value)}/>
                <FormHelperText style={{textAlign:'right'}}>{'הוסף דרישות נוספות ל'+entities[service.index]}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <IconButton
                style={{padding: 2, marginTop: 6}}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="check"
                aria-haspopup="true"
                onClick={handle_add_requirement}
              >
                <CheckIcon/>
              </IconButton>
            </Grid>
          </Grid>

          {/*{service.requirements.map(requirement=>*/}
          {/*<div>*/}
          {/*  {requirement}*/}
          {/*</div>*/}
          {/*)}*/}
          {/*<div>*/}
          {/*  הוסף דרישה*/}
          {/*</div>*/}
        </div>
      </div>
    </Zoom>
  )
};