import React, {ReactNode, useContext} from "react";
import Grid, {GridJustification} from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "../../Tooltip";
import photo_icon from "../../../assets/images/icons/photo2.svg";
import Slide from "@material-ui/core/Slide";
import Crop from "./Crop";
import {makeStyles} from "@material-ui/core";
import type {ImageReady, User, Function, Direction} from "../../../types";
import context from "../../../context";

const useStyles = makeStyles({
  avatarCircle: {
    borderRadius:'50%',
    padding: 4,
    backgroundColor:'white',
    boxShadow: 'inset 0 1.5px 3px 0 rgba(0,0,0,.15), 0 1.5px 3px 0 rgba(0,0,0,.15)'
  },

  avatar: {
    borderRadius:'50%',
    width: 150,
    height: 150,
    cursor:'pointer',
  },
});

type Props = {|
  open: boolean,
  timeout: number,
  direction: Direction,
  justify: GridJustification,
  id: string,
  onClick: Function,
  onImage: ImageReady,
|};

export default (
  {
    open=true,
    timeout=1500,
    direction='down',
    justify='center',
    id,
    onClick,
    image,
    onImage,
  }: Props)
  :ReactNode => {
  const classes = useStyles();
  return (
    <Slide direction={direction} timeout={timeout} in={open}>
      <Grid container justify={justify}>
        <Zoom in={open} timeout={timeout}>
          <div className={classes.avatarCircle}>
            <img className={classes.avatar} src={image} alt="..." onClick={()=>{onClick&&onClick()}}/>
            <div style={{position:'relative'}}>
              <label htmlFor={id}>
                <IconButton component="span" variant="contained" color="primary" size="small" style={{position:'absolute', bottom: 0, right:-5}}>
                  <Tooltip title={'Update Your Photo'}>
                    <img src={photo_icon} style={{width:40}} alt="change photo"/>
                  </Tooltip>
                </IconButton>
              </label>
            </div>
          </div>
        </Zoom>
        <Crop id={id} onImage={onImage}/>
      </Grid>
    </Slide>
  )
}
