import React, {useContext, useState} from "react";
import {useAssignmentsStyles} from "./styles";
import {useStoreStorage} from "../../hooks/useStore";
import storage_ids from "../../storage_ids";
import Zoom from "@material-ui/core/Zoom/Zoom";
import Grid from "@material-ui/core/Grid";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Tooltip from "../Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import CancelIcon from '@material-ui/icons/Close'
import DeleteIcon from "@material-ui/icons/Delete"
import CheckIcon from "@material-ui/icons/Check";
import context from "../../context";

export const MissionSteps = () => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  const [steps, set_steps] = useStoreStorage(storage_ids.new_assignment_steps,[]);
  const [new_step, set_new_step] = useState('');

  const onDeleteSteps = () => {
    set_steps([])
  };

  const onAddStep = value => {
    if (value)
      set_steps([...steps, value]);
  };

  const onDeleteStep = step_index => {
    const array = [...steps];
    array.splice(step_index, 1);
    set_steps(array)
  };

  const onStepChange = (step_index, value) => {
    const array = [...steps];
    array[step_index] = value;
    set_steps(array)
  };

  const handle_add_step = () => {
    onAddStep(new_step);
    set_new_step('');
  };

  return (
    <Zoom in={true} timeout={500}>
      <div className={classes.service_root}>
        <Grid container justify='flex-start' alignItems='center'>
          <AssignmentIcon/>
          <div style={{fontSize: 20, padding: '0 10px'}}>שלבי המשימה:</div>
          <div style={{flexGrow: 1}}/>
          <Tooltip title={'מחק את כל שלבי המשימה'}>
            <IconButton aria-label="delete" onClick={onDeleteSteps}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        </Grid>

        <div style={{fontSize: 16, padding: '10px 32px'}}>
          {steps.map((step, index) =>
            <Grid container spacing={1} style={{marginBottom:20}} alignItems="flex-start" key={index}>
              <Grid item style={{flexGrow: 1}}>
                <FormControl style={{width: '100%'}}>
                  <TextField fullWidth={true} value={step} onChange={event=>onStepChange(index, event.target.value)}/>
                </FormControl>
              </Grid>
              <Grid item>
                <IconButton
                  style={{padding: 2, marginTop: 6}}
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="check"
                  aria-haspopup="true"
                  onClick={()=>onDeleteStep(index)}
                >
                  <CancelIcon style={{color: '#ababab85'}}/>
                </IconButton>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item>
              <FormControl>
                <TextField value={new_step} onChange={event=>set_new_step(event.target.value)}/>
                <FormHelperText style={{textAlign:'right'}}>הוסף שלב למשימה</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <IconButton
                style={{padding: 2, marginTop: 6}}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="check"
                aria-haspopup="true"
                onClick={handle_add_step}
              >
                <CheckIcon/>
              </IconButton>
            </Grid>
          </Grid>

        </div>
      </div>
    </Zoom>
  )
};