import React, {useContext, useEffect, useState} from "react";
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField";
import DescriptionIcon from '@material-ui/icons/Description';
import Grid from "@material-ui/core/Grid";
import Tooltip from "../../../Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core";
import type {Language} from "../../../../types";
import context from "../../../../context";

export const useStyles = makeStyles(theme => ({
  close: {maxHeight: 0},
  open: {maxHeight: 10000},

  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 300,
    // width:'100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export const text = {
  eng: {
    label: 'license permits',
    tooltip: 'add in your license permits',
  },

  heb: {
    label: 'היתרי רישיון',
    tooltip: 'הוסף היתרים לרישיון',
  },
};

export default ({open, permits={}, set_permits}) => {
  const language: Language = useContext(context.language).language;
  const classes = useStyles();

  const [permit_list, set_permit_list] = useState([]);
  const [permit, set_permit] = useState('');

  useEffect(() => set_permit_list(Object.keys(permits)), [permits]);

  return (
    <Slide direction="right" timeout={1800} in={open} mountOnEnter unmountOnExit>
      <div style={{marginBottom: 15}}>
        <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 8}}>
          <Grid item>
            <Tooltip title={text[language.value].tooltip}
            >
              <DescriptionIcon/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              fullWidth
              label={text[language.value].label}
              value={permit}
              onChange={event=>set_permit(event.target.value)}
              InputProps={{
                endAdornment: permit?(
                  <InputAdornment position="end">
                    <div style={{display:'flex'}}>
                      <IconButton
                        aria-label="add permit"
                        onClick={() => {
                          if (!permit_list.includes(permit)) {
                            const update = {...permits};
                            update[permit] = true;
                            set_permits(update);
                          }
                          set_permit('')
                        }}
                      >
                        <CheckIcon/>
                      </IconButton>
                      <IconButton
                        aria-label="cancel permit"
                        onClick={()=>set_permit('')}
                      >
                        <CloseIcon/>
                      </IconButton>
                    </div>

                  </InputAdornment>
                ):undefined
              }}
            />
          </Grid>
        </Grid>
        {permit_list.length ?
          <FormControl className={classes.formControl} style={{paddingLeft:32}}>
            <div className={classes.chips}>
              {permit_list.map((value, index) => (
                <Chip
                  key={index}
                  label={value}
                  className={classes.chip}
                  onDelete={()=>{
                    const update = {...permits};
                    delete update[value];
                    set_permits(update);
                  }}
                />
              ))}
            </div>
          </FormControl>:null
        }
      </div>
    </Slide>
  )
};
