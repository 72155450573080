import { makeStyles } from '@material-ui/core/styles';


export const useAssignmentsStyles = makeStyles((theme) => ({
  new_assignment_actions: {
    margin: 5,
  },

  menuButton: {
    marginRight: ({rtl}) => rtl ? theme.spacing(2) : 0,
    marginLeft: ({rtl}) => rtl ? 0 : theme.spacing(2),
  },

  menuButton2: {
    marginRight: ({rtl}) => rtl ? 'auto' : 0,
    marginLeft: ({rtl}) => rtl ? 0 : 'auto',
  },

  distributionPoint: {
    margin: 7,
    marginBottom: 13,
    border: '1px solid #ababab',
    borderRadius: 3,
    padding: '5px 25px',
  },

  helper: {
    position: 'absolute',
    bottom: -26,
    left: -22,
  },

  service_root: {
    textAlign: ({rtl}) => rtl ? 'right' : 'left',
    border: '1px solid #bbbbbb',
    padding:8,
    marginBottom: 40,
  },

  new_assignment_text_input_root: {
    textAlign: ({rtl}) => rtl ? 'right' : 'left',
    '& > *': {
      margin: theme.spacing(1),
    },
    marginBottom: 10,
  },

  entities: {
    marginBottom: 30,
  },

  fields_container: {
    margin: 0,
  },

  field_container: {
    margin: 0,
    paddingBottom:20,
    width: '100%',
    maxWidth: props =>
      !props.width ? 300 : props.width >= 1300 ? 300
      : props.width >= 650 ? props.width*0.45
      : props.width - 20,
  },

  root: {
    width: '100%',
    padding: 10,
    paddingRight: 20,
    // // mobile
    // [theme.breakpoints.down('xs')]: {
    //   paddingRight: 20,
    // },
  },
  paper: {
    // width: '100%',
    // marginBottom: theme.spacing(2),
    paddingLeft: 10,
    paddingRight: 10,
    background: '#ffffff',
    // border: '1px solid #AAA',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  title: {
    textAlign: 'center',
    padding: 20,
    background: '#ffffff',
    border: '1px solid #ababab',
    margin: 10,
  },

  title_text: {
    textAlign: 'center',
    padding: 20,
    margin: 10,
  },

  title_text2: {
    padding: 20,
    margin: 10,
  },

  assignment_container: {
    width: '100%',
    textAlign: 'center',
    padding: 20,
    background: '#ffffff',
    border: '1px solid #ababab',
    margin: 10,
    maxWidth: props => props.width && props.width < 1300 ? props.width-40: 1300,
  },

  assignment_title: {
    margin: 10,
  }
}));