import React, {useState, useContext, useEffect} from "react";
import Typography from '@material-ui/core/Typography';
import context from '../context'
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import type {Driver, User} from "../types";
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid'
import {
  DeleteDriverSetting,
  DriverCard,
  ActionEdit,
  ActionGetAssignment,
  RateIcon,
  LikeIcon,
  PriceIcon,
  AvailabilityIcon,
} from './cards/DriverCard'
import { makeStyles } from '@material-ui/core/styles';
import NewDriverDialog from './dialogs/NewDriverDialog'
import {Paginator} from './Pagination'
import {BlueButton} from './custom/CustomButtons/Buttons'
import usePrevious from '../hooks/usePrevious'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default ({driversPageProps}) => {
  const classes = useStyles();
  const user:User = useContext(context.user).user;
  const [open, set_open] = useState(false);
  const prev_open = usePrevious(open);

  const {
    loading, set_loading,
    count_items, set_count_items,
    items_per_page, set_items_per_page,
    page_number, set_page_number,
    last_page, set_last_page,
    page_items, set_page_items,
    page_matrix, set_page_matrix,
    add_item, remove_item,
    reRenderPage,
  } = driversPageProps;

  const [template, set_template] = useState({
    driver_created: false, // signal if profile was created
  });

  const driver_created = template.driver_created;

  // reset template
  useEffect(()=>{
    if (user===null)
      set_template({driver_created: false});

    else if(open===false) {
      set_template({...template, driver_created: false})
    }
  },[user, open]);

  // add new driver to assets (driver )
  useEffect(()=>{
    if (driver_created) {
      if (page_items.find(i=>i._id===template._id) === undefined)
        add_item(template);
    }
  },[driver_created]);

  useEffect(()=>{
    if (user !== null && page_items.length === 0) {
      const update = {...template};
      update.full_name = user.full_name;
      if (user.image_url_hd.startsWith('https://')) {
        update.image_url_hd = user.image_url_hd;
      }
      update.description = user.description;
      update.home_country = user.country;
      update.home_region = user.region;
      update.home_city = user.city;
      update.email = user.email;
      update.company = user.company;
      update.job = user.job;
      update.phone_number = user.phone_number;
      set_template(update)
    }
  },[user]);

  useEffect(()=>{
    if (page_items.length > 0 && driver_created === false) {
      const update = {...template};
      const driver:Driver = page_items[0];
      if (driver.image_url_hd.startsWith('https://')) {
        update.image_url_hd = driver.image_url_hd;
      }
      update.description = driver.description;
      update.home_country = driver.home_country;
      update.home_region = driver.home_region;
      update.home_city = driver.home_city;
      update.company = driver.company;
      update.job = driver.job;
      set_template(update)
    }
  },[page_items, driver_created]);

  useEffect(()=>{
    if (prev_open === true) {
      reRenderPage()
    }
  }, [prev_open]);

  const onEdit = (driver) => () => {
    set_template(driver);
    set_open(true)
  };

  const onNewDriver = () => {
    set_template({...template});
    set_open(true);
  };

  return (
      <div>
        <Grid container justify='center' style={{marginTop:35}}>
          <BlueButton
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={onNewDriver}
          >
            New Driver
          </BlueButton>
        </Grid>

        <Grid container justify='center' className={classes.root} spacing={2} style={{marginTop:35}}>
          {page_items.map((driver, index)=>
            <Grid item key={driver._id}>
              <DriverCard
                settings={({set_is_open, handleClose}) => [
                  <DeleteDriverSetting key={1} driver={driver} handleClose={handleClose} afterDelete={()=>remove_item(driver)} set_is_open={set_is_open}/>,
                ]}
                actions={[
                  <ActionEdit key={1} onClick={onEdit(driver, index)}/>,
                  <ActionGetAssignment key={2}/>
                ]}
                icons={[
                  <RateIcon key={1} driver={driver}/>,
                  <LikeIcon key={2} driver={driver}/>,
                  <PriceIcon key={3} driver={driver}/>,
                  <AvailabilityIcon key={4} driver={driver}/>,
                ]}
                driver={driver}
                style={{maxWidth:300}}
                timeout={300+100*index}
              />
            </Grid>
          )}
        </Grid>

        <Paginator
          style={{marginTop:40}}
          loading={loading}
          set_page_number={set_page_number}
          page_number={page_number}
          last_page={last_page}
        />

        <NewDriverDialog
          open={open}
          set_open={set_open}
          template={template}
          set_template={set_template}
        />

      </div>
  )
};