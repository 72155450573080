export const regions = {
  eng: {
    values: [
      'Northern',
      'Haifa',
      'Tel Aviv',
      'Central',
      'Jerusalem',
      'Southern',
    ],
  },

  heb: {
    values: [
      'הצפון',
      'חיפה',
      'תל אביב',
      'המרכז',
      'ירושלים',
      'הדרום',
    ],
  }
};

export const service_types = {
  eng: {
    values: [
      'Driver',
      'Vehicle',
      'Full Service',
    ],
  },

  heb: {
    values: [
      'נהג',
      'רכב',
      'שירות מלא',
    ],
  }
};

export const access_levels_text = {
  eng: {
    map: {
      user: 'user',
      tester: 'tester',
      developer: 'developer',
      analyst: 'analyst',
      admin: 'admin',
    },
    values: [],
  },

  heb: {
    map: {
      user: 'משתמש רגיל',
      tester: 'בודק איכות',
      developer: 'מפתח תוכנה',
      analyst: 'מנתח נתונים',
      admin: 'מנהל מערכת',
    },
    values: [],
  }
};

Object.values(access_levels_text).forEach(language=>{
  language.values = Object.values(language.map)
});