import {useAssignmentsStyles} from "../../assignments/styles";
import Swal from "sweetalert2";
import Grid from "@material-ui/core/Grid";
import {FilledDateTime} from "./FilledDateTime";
import React, {useContext} from "react";
import context from "../../../context";

export const SelectStartAndEndTime = ({size, start_time, set_start_time, end_time, set_end_time, index=0}) => {
  const classes = useAssignmentsStyles({...useContext(context.rtl), width: size.width});

  start_time = typeof start_time === 'string' ? new Date(start_time) : start_time;
  end_time = typeof end_time === 'string' ? new Date(end_time) : end_time;

  const handle_start_time = (new_start_time:Date) => {
    if (new_start_time && end_time && new_start_time >= end_time) return Swal.fire({
      type: 'error',
      title: 'אופס...',
      text: `לא ניתן לבחור זמן התחלה אחרי זמן הסיום`,
      confirmButtonText: 'הבנתי',
    });
    set_start_time(new_start_time);
  };

  const handle_end_time = (new_end_time:Date) => {
    if (new_end_time && start_time && new_end_time <= start_time) return Swal.fire({
      type: 'error',
      title: 'אופס...',
      text: `לא ניתן לבחור זמן סיום לפני זמן ההתחלה`,
      confirmButtonText: 'הבנתי',
    });
    set_end_time(new_end_time);
  };

  return (
    <>
      <Grid container direction='column' className={classes.field_container}>
        <FilledDateTime id={`assignment-time-start-${index}`} label={'זמן התחלה'} value={start_time} onChange={handle_start_time}/>
      </Grid>
      <Grid container direction='column' className={classes.field_container}>
        <FilledDateTime id={`assignment-end-start-${index}`} label={'זמן סיום'} value={end_time} onChange={handle_end_time}/>
      </Grid>
    </>
  )
};