import {useEffect, useState} from "react";
import {useSpring} from "react-spring";

export const emptyStyle = {from: {}};

export const useOpenSpring = (
  {
    open,
    initialStyle=emptyStyle,
    openStyle=emptyStyle,
    closeStyle=emptyStyle,
  }) => {
  const [style, set_style] = useState(initialStyle);

  // open and close
  useEffect(()=>{
    set_style(open ? openStyle : closeStyle)
  }, [open]);

  return {
    style,
    set_style,
    animaStyle: useSpring(style),
  };
};