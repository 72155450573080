import React from 'react';
import 'typeface-roboto';
import './assets/scss/material-dashboard-pro-react.scss';
import './assets/scss/styles.scss';
import './stores/useDefaultStore'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PersistGate } from 'zustand-persist'
import route from './routes';

import SignIn from './components/signin/SignIn'
import BackDrop from './components/loaders/BackDrop'
import GloablState from './components/global/GlobalState'
import {AppPage} from './wrappers/view_wrappers'
import GlobalSnackbar from './components/global/GlobalSnackbar'
import GlobalHooks from './components/global/GlobalHooks'
import IncompleteProfileDialog from './components/dialogs/IncompleteProfileDialog'

import OnRedirect from './views/Auth/OnRedirect'

import Home from './views/Gotrack/Home';
import Contact from './views/Gotrack/Contact'
import Terms from './views/Gotrack/Terms'
import Privacy from './views/Gotrack/Privacy'
import Help from './views/Gotrack/Help'

import MyAccount from './views/UserPages/MyAccount'
import MyAccountSetup from './views/UserPages/MyAccountSetup'
import MyAccountEdit from './views/UserPages/MyAccountEdit'
import MyAssets from './views/UserPages/MyAssets'
import MyAssignments from './views/UserPages/MyAssignments'

import MyOrders from './views/UserPages/MyOrders'

import Users from './views/UserPages/Users'
import UserEdit from './views/UserPages/UserEdit'
import NewUser from './views/UserPages/NewUser'
import DriverEdit from './views/UserPages/DriverEdit'

import NoMatch from './views/OtherPages/NoMatch';
import withTracker from './wrappers/withTracker'


export default () =>
  <Router basename={process.env.PUBLIC_URL}>
    <PersistGate>
      <GloablState>
        <GlobalHooks/>
        <AppPage>
          <Switch>
            <Route path={route.home} exact component={withTracker(Home)}/>

            <Route path={route.my_account} exact component={withTracker(MyAccount)}/>
            <Route path={route.my_account_setup} exact component={withTracker(MyAccountSetup)}/>
            <Route path={route.my_account_edit} exact component={withTracker(MyAccountEdit)}/>
            <Route path={route.my_assets} exact component={withTracker(MyAssets)}/>
            <Route path={route.my_assignments} exact component={withTracker(MyAssignments)}/>
            <Route path={route.orders_status} exact component={withTracker(MyOrders)}/>

            <Route path={route.users} exact component={withTracker(Users)}/>
            <Route path={route.user_update} exact component={withTracker(UserEdit)}/>
            <Route path={route.users_create} exact component={withTracker(NewUser)}/>
            <Route path={route.user_driver_update} exact component={withTracker(DriverEdit)}/>

            <Route path={route.terms} exact component={withTracker(Terms)}/>
            <Route path={route.privacy} exact component={withTracker(Privacy)}/>
            <Route path={route.contact} exact component={withTracker(Contact)}/>
            <Route path={route.redirect_oauth} component={withTracker(OnRedirect)}/>
            <Route path={route.help} component={withTracker(Help)}/>
            <Route component={NoMatch}/>
          </Switch>
        </AppPage>
        <GlobalSnackbar/>
        <IncompleteProfileDialog/>
        <SignIn/>
        <BackDrop/>
      </GloablState>
    </PersistGate>
  </Router>
