import createStore, {UseStore} from 'zustand'
import {CreateFetcher} from "../utils/storeFetchFunctions";
import {persist} from '../utils/persist'
import {storage_ids} from '../storage_ids'
import produce from 'immer'
import {register} from "./stores";


// improve performance by fetching state
// from dynamically created functions
// functions are created on store creation time
export const fetchStore = {
  // [state[key]]: state => state[state[key]],
};


export const useSidebarStore: UseStore = createStore(
  persist(
    {
      key: storage_ids.sidebar,
      // denylist: ['isLoading', 'errorMessage'],
      allowlist: ['expanded'],
    },
    (set, get) => CreateFetcher(fetchStore, {
      expanded: {
        // [route]: bool
      },

      routes: {
        // [route]: {
        //   expanded: boolean,
        //   open: Function,
        //   close: Function,
        //   toggle: Function,
        // }
      },

      set: fn => set(produce(fn)),

      getRouteStore: (route: string) => {
        const state = get();

        if (state.expanded[route] === undefined || state.routes[route] === undefined) {
          const route_store = {
            expanded: state.expanded[route] === undefined ? false : state.expanded[route],
            open: () => get().set(state => {
              state.expanded[route] = true;
              state.routes[route].expanded = state.expanded[route];
            }),
            close: () => get().set(state => {
              state.expanded[route] = false;
              state.routes[route].expanded = state.expanded[route];
            }),
            toggle: () => get().set(state => {
              state.expanded[route] = !state.expanded[route];
              state.routes[route].expanded = state.expanded[route];
            }),
          };
          state.set(state => {
            if (state.expanded[route] === undefined)
              state.expanded[route] = route_store.expanded;
            state.routes[route] = route_store
          });
          return route_store;
        }
        return state.routes[route];
      },

      // isLoading: false,
      // errorMessage: '',
      // data: [
      //   {
      //     id: '1',
      //     text: 'first note',
      //     date: new Date().toISOString(),
      //     completed: false
      //   }
      // ],
      // create: (todoRequest) => {
      //   set((state) => ({
      //     data: [
      //       {
      //         id: new Date().getTime().toString(),
      //         ...todoRequest
      //       },
      //       ...state.data
      //     ]
      //   }))
      // },
      // remove: (todoId) => {
      //   set((state) => ({
      //     data: state.data.filter((item) => item.id !== todoId)
      //   }))
      // },
      // update: (todoId, todoRequest) => {
      //   set((state) => ({
      //     data: state.data.map((item) =>
      //       item.id === todoId
      //         ? {
      //           ...item,
      //           ...todoRequest
      //         }
      //         : item
      //     )
      //   }))
      // },
      // clear: () => {
      //   set((state) => ({
      //     data: []
      //   }))
      // },
    })
  )
);

register({fetchStore, useSidebarStore});