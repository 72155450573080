import React, {useContext} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../../assets/jss/style_vars'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link';
import context from '../../context'
import IconButton from '@material-ui/core/IconButton'
import {date, app_name} from '../../variables/variables'
import {ReactComponent as TruckIcon} from '../../assets/images/icons/truck-ultrathin-transport-svgrepo-com.svg'
import type {Language} from "../../types";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor:colors.primary,
    color:'white',
    minHeight:64,
  },

  root: {
    flexGrow: 1,
  },

  Grid: {
    maxWidth: 1200,
    margin:'auto',
    padding:20,
    paddingTop:40,
    paddingBottom:80,
    '& h3': {
      color: '#949ca9',
    }
  },

  item: {
    width: '50%',
    minWidth: 300,
    padding:20,
  },

  itemSocial: {
    minWidth: 300,
    padding:20,
    width:'100%',
    paddingLeft: 12,
    paddingBottom: 0,
  },

  GridSocial: {
    paddingLeft: 7,
  },

  link: {
    color:'white',
    width:'100%',
    textAlign:'left',
    '&:hover': {
      color: 'rgba(255,255,255,0.65)',
    }
  },

  link2: {
    color:'white',
    textAlign:'left',
    '&:hover': {
      color: 'rgba(255,255,255,0.65)',
    }
  },

  RightsGrid: {
    padding: 20,
    marginTop: 20,
  },

  sep: {
    marginLeft:10,
    marginRight: 10,
  }
}));

export const text = {
  eng: {
    Gotrack: {
      title: 'Gotrack',
      links: [
        'About us',
        'Team',
        'Investors Relations',
        'press',
        'Privacy Policies',
        'Trust & Safety',
        'Terms',
        'Help',
      ],
    },
    Services: {
      title: 'Services',
      links: [
        'Driver without vehicle',
        'Driver with vehicle',
        'Vehicle for rent/share',
        'Full Service',
        'Collaboration',
      ],
    },
    Gotrackers: {
      title: 'Gotrackers/Freelancers',
      links: [
        'Sign in',
        'Carriers',
        'Driver without vehicle',
        'Driver with vehicle',
        'Vehicle owner',
        'List yourself',
        'How it works',
        'Questions',
        'How to earn money',
        'Insurance & protection',
        'Gold Tracker benefits',
        'Career and placement',
      ],
    },
    Shippers: {
      title: 'Shippers/Seekers',
    },
    Social: {
      title: 'Social',
      links: [
        'linkedin',
      ],
    },
    Rights: {
      terms: 'Terms',
      privacy: 'Privacy',
      text: `© ${date.getFullYear()}, GOtrack, Inc. or its affiliates. All rights reserved.`
    },
  },

  heb: {
    Gotrack: {
      title: 'גוטראק',
      links: [
        'עלינו',
        'מייסדים',
        'משקיעים',
        'חדשות',
        'פרטיות',
        'אבטחת מידע',
        'תנאי שימוש',
        'עזרה',
      ],
    },
    Services: {
      title: 'השירותים שלנו',
      links: [
        'נהג ללא רכב',
        'נהג עם רכב',
        'רכב להשכיר/לחלוק',
        'שירות מלא',
        'שיתופי פעולה',
      ],
    },
    Gotrackers: {
      title: 'גוטראקר/עצמאי',
      links: [
        'להצטרפות',
        'מובילים',
        'נהג ללא רכב',
        'נהג עם רכב',
        'בעל רכב',
        'הצטרף לרשימה',
        'איך זה עובד',
        'שאלות/תשובות',
        'איך מרוויחים',
        'ביטוח',
        'יתרונות למנוי זהב',
        'קריירה ומיקום',
      ],
    },
    Shippers: {
      title: 'צורכי שירות',
    },
    Social: {
      title: 'רשתות חברתיות',
      links: [
        'לינקדין',
      ],
    },
    Rights: {
      terms: 'תנאים',
      privacy: 'פרטיות',
      text: `© ${date.getFullYear()}` + `, גוטראק, בע"מ. או השותפים שלה. כל הזכויות שמורות.`
    },
  },
};

const CustomLink2 = ({onClick, children}) => {
  const classes = useStyles();
  return (
    <Link className={classes.link2} component="button" variant="body2" onClick={onClick}>
      {children}
    </Link>
  )
};

export default ({...props}) => {
  const classes = useStyles();
  const language: Language = useContext(context.language).language;
  const links = useContext(context.links);

  return (
    <div className={classes.footer} {...props}>
      <Grid container className={classes.Grid} >
        <Grid container className={classes.RightsGrid}>
          <CustomLink2 onClick={links.go_to_privacy}>{text[language.value].Rights.privacy}</CustomLink2>
          <span className={classes.sep}>|</span>
          <CustomLink2 onClick={links.go_to_terms}>{text[language.value].Rights.terms}</CustomLink2>
          <span className={classes.sep}>|</span>
          <span>{text[language.value].Rights.text}</span>
        </Grid>
      </Grid>
    </div>
  )
}