import React, {useEffect, useState, useContext} from "react";
import {Formik, Form, Field} from 'formik';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import FaceIcon from '@material-ui/icons/Face';
import Tooltip from '../../Tooltip';
import sleep from "../../../utils/sleep";
import context from '../../../context'
import {Input} from '../../custom/CustomInput/FormikInput'
import Avatar from "../../custom/CustomUpload/Avatar";
import ids from "../../../ids";
import type {Function, User, Driver} from "../../../types";
import placeholder_avatar from '../../../assets/images/avatars/driver-svgrepo-com.svg'
import email_icon from '../../../assets/images/icons/email.svg'
import {animated, useSpring} from "react-spring";
import flag from "../../../assets/images/icons/flag.svg";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import Grow from "@material-ui/core/Grow";
import region_icon from "../../../assets/images/icons/region.svg";
import city from "../../../assets/images/icons/city.svg";
import BusinessIcon from '@material-ui/icons/Business';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import WorkIcon from '@material-ui/icons/Work';
import {
  TimePicker,
  DatePicker,
  DateTimePicker,
} from 'formik-material-ui-pickers';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import birthdate from '../../../assets/images/icons/birthday-cake-cake-svgrepo-com.svg'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from "@material-ui/core/Typography";
import InputNumber from '../../custom/CustomInput/InputNumber'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import InputSelect from '@material-ui/core/Input';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

export const useStyles = makeStyles(theme => ({
  close: {maxHeight: 0},
  open: {maxHeight: 10000},

  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 300,
    // width:'100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function valuetext(value) {
  return `${value}km`;
}

interface Values {
  prefered_services: Array<string>,
  unacceptable_services: Array<string>,
  service_types: Object,
  order_types: Object,
}

const validate = values => {
  const errors: Partial<Values> = {};


  return errors;
};

export default ({template, onSuccess, open, set_formProps}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const user: User = template;

  const [image, set_image] = useState(user&&user.image_url_hd?user.image_url_hd:null);
  const [description, set_description] = useState(user?user.description:'');

  const maxWidth = mobile ? 280 : 400;

  const initialValues = {
    service_types: template.service_types?template.service_types:{},
    order_types: template.service_types?template.order_types:{},
  };

  const onSubmit = async (values, {setSubmitting}) => {
    // set_loading(true);
    await sleep(1000);
    const res = true;
      // const res = await edit_account_info({
    //   set_user,
    //   full_name: values.full_name,
    //   email: values.country,
    //   description: values.region,
    //   city: values.city,
    //   phone_number: values.phone,
    //   company: values.company,
    //   job: values.job,
    // });
    // set_loading(false);
    // if (res) {
    //   set_user({...user, ...res});
    //   await sleep(1000);
    //   Swal.fire({
    //     type: 'success',
    //     title: 'Account Updated',
    //     text: 'Your account is up to date! happy GOtracking',
    //     confirmButtonText: 'OK',
    //     timer: 6000,
    //   });
    //   await sleep(800);
    // }
    setSubmitting(false);
    !!res && onSuccess();
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >{({submitForm, isSubmitting, values, setFieldValue, handleChange, handleBlur}) => {

      const classes = useStyles();
      const [region_style, set_region_style] = useState({height: 0, marginTop: 0, from: {height: 0, marginTop: 0}});
      const [operative_radius_from_current_location, set_operative_radius_from_current_location] = useState(template.operative_radius_from_current_location?template.operative_radius_from_current_location:10);
      const [arrival_ability, set_arrival_ability] = useState(template.arrival_ability?template.arrival_ability:50);
      const [workplace_radius, set_workplace_radius] = useState(template.workplace_radius?template.workplace_radius:50);

      const [prefered_service, set_prefered_service] = useState('');
      const [unacceptable_service, set_unacceptable_service] = useState('');
      const [prefered_services, set_prefered_services] = useState(template.prefered_services?template.prefered_services:[]);
      const [unacceptable_services, set_unacceptable_services] = useState(template.unacceptable_services?template.unacceptable_services:[]);

      useEffect(() => {
        set_formProps({submitForm, isSubmitting});
      }, [submitForm, isSubmitting]);

      useEffect(() => {
        const root = document.getElementById(ids.new_driver_profile_form_root);
        open
          ? root.className = classes.open
          : root.className = classes.close;
      }, [open]);

      useEffect(() => {
        if (!values.country) {
          set_region_style({height: 0, marginTop: 0, from: {height: region_style.from.height, marginTop: region_style.from.marginTop}})
        } else {
          set_region_style({height: 41, marginTop: 30, from: {height: region_style.from.height, marginTop: region_style.from.marginTop}})
        }
      }, [values]);

      return (
        <div id={ids.new_driver_profile_form_root} style={{width:'100%', transition: 'max-height 0.5s ease-in-out'}}>
          <Grid container justify='center'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Form style={{paddingTop: 10, width: maxWidth}}>
                <div>

                  <Slide direction="right" timeout={1400} in={open} mountOnEnter unmountOnExit>
                    <Grid container justify='center' style={{marginBottom:30}}>
                      <div>
                        <Grid container>
                          <Typography variant="h6" gutterBottom style={{marginBottom:0, textAlign:'center'}}>
                            Work Distance
                          </Typography>
                          <span style={{width:0}}>
                          <Grid container style={{height:'100%', marginLeft:5}} direction="row" alignItems="center">
                            <Tooltip title={
                              <div style={{paddingLeft:10, paddingRight:10, maxWidth:250, fontSize:14, fontWeight:300}}>
                                <p>
                                  how far should we search<br/>
                                  for orders in your vicinity ?<br/>
                                  <br/>
                                  how far will you drive<br/>
                                  just to get to work ?<br/>
                                  <br/>
                                  do you need a ride to work ?<br/>
                                  <br/>
                                  tell us what kind of driving<br/>
                                  offers you want to serve/not serve.
                                </p>
                              </div>
                            }>
                              <InfoIcon fontSize='small'/>
                            </Tooltip>
                          </Grid>
                        </span>
                        </Grid>
                        <Typography variant="body2" gutterBottom style={{textAlign:'center'}}>
                          And Service Preferences
                        </Typography>
                      </div>
                    </Grid>
                  </Slide>

                  <Slide direction="right" timeout={1500} in={open} mountOnEnter unmountOnExit>
                    <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                      <Grid item>
                        <Tooltip
                          title={
                            'looking to get offers near your current/future location ? ' +
                            'you can search for opportunities near your current vicinity, ' +
                            'or search for offers near your existing destinations ' +
                            'and set yourself orders on your way back.'
                          }
                        >
                          <LocationOnIcon/>
                        </Tooltip>
                      </Grid>
                      <Grid item style={{flexGrow: 1}}>
                        <InputNumber
                          fullWidth={true}
                          value={operative_radius_from_current_location}
                          setValue={set_operative_radius_from_current_location}
                          step={1}
                          min={1}
                          max={22000}
                          label={'operative radius from current location'}
                          suffix='km'
                        />
                      </Grid>
                    </Grid>
                  </Slide>

                  <Slide direction="right" timeout={1600} in={open} mountOnEnter unmountOnExit>
                    <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                      <Grid item>
                        <Tooltip
                          title={
                            'do you have an independent ride to work ? ' +
                            'how far can you go? if you have no means ' +
                            'to arrive to your workplace and need ' +
                            'your employer to provide one for you, ' +
                            'set the distance to 0km'
                          }
                        >
                          <LocationOnIcon/>
                        </Tooltip>
                      </Grid>
                      <Grid item style={{flexGrow: 1}}>
                        <InputNumber
                          fullWidth={true}
                          value={arrival_ability}
                          setValue={set_arrival_ability}
                          step={1}
                          min={0}
                          max={22000}
                          label={'arrival ability'}
                          suffix='km'
                        />
                      </Grid>
                    </Grid>
                  </Slide>

                  <Slide direction="right" timeout={1700} in={open} mountOnEnter unmountOnExit>
                    <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                      <Grid item>
                        <Tooltip
                          title={
                            'how far away from home are you willing to go ? ' +
                            'input the distance in kilometers'
                          }
                        >
                          <LocationOnIcon/>
                        </Tooltip>
                      </Grid>
                      <Grid item style={{flexGrow: 1}}>
                        <InputNumber
                          fullWidth={true}
                          value={workplace_radius}
                          setValue={set_workplace_radius}
                          step={1}
                          min={0}
                          max={22000}
                          label={'workplace distance from home'}
                          suffix='km'
                        />
                      </Grid>
                    </Grid>
                  </Slide>

                  <Slide direction="right" timeout={1800} in={open} mountOnEnter unmountOnExit>
                    <div style={{marginBottom: 15}}>
                      <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 8}}>
                        <Grid item>
                          <Tooltip title={
                            'what type of driving services do you prefer to sell ? ' +
                            'cab/heavy cargo/forklift/etc. '}
                          >
                            <StoreIcon/>
                          </Tooltip>
                        </Grid>
                        <Grid item style={{flexGrow: 1}}>
                          <TextField
                            fullWidth
                            label={'prefered services'}
                            value={prefered_service}
                            onChange={event=>set_prefered_service(event.target.value)}
                            InputProps={{
                              endAdornment: prefered_service?(
                                <InputAdornment position="end">
                                  <div style={{display:'flex'}}>
                                    <IconButton
                                      aria-label="increase input"
                                      onClick={() => {
                                        if (!prefered_services.includes(prefered_service))
                                          set_prefered_services([...prefered_services, prefered_service]);
                                        set_prefered_service('')
                                      }}
                                    >
                                      <CheckIcon/>
                                    </IconButton>
                                    <IconButton
                                      aria-label="decrease input"
                                      onClick={()=>set_prefered_service('')}
                                    >
                                      <CloseIcon/>
                                    </IconButton>
                                  </div>

                                </InputAdornment>
                              ):undefined
                            }}
                          />
                        </Grid>
                      </Grid>
                      {prefered_services.length ?
                        <FormControl className={classes.formControl} style={{paddingLeft:32}}>
                          <div className={classes.chips}>
                            {prefered_services.map((value, index) => (
                              <Chip
                                key={index}
                                label={value}
                                className={classes.chip}
                                onDelete={()=>{
                                  set_prefered_services(prefered_services.filter(i=>i!==value));
                                }}
                              />
                            ))}
                          </div>
                        </FormControl>:null
                      }
                    </div>
                  </Slide>

                  <Slide direction="right" timeout={1900} in={open} mountOnEnter unmountOnExit>
                    <div style={{marginBottom: 15}}>
                      <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 8}}>
                        <Grid item>
                          <Tooltip title={
                            'are there services you are not willing to provide ? tell us what to not bother you with: ' +
                            'cab/heavy cargo/forklift/etc. '}
                          >
                            <NotInterestedIcon/>
                          </Tooltip>
                        </Grid>
                        <Grid item style={{flexGrow: 1}}>
                          <TextField
                            fullWidth
                            label={'unacceptable services'}
                            value={unacceptable_service}
                            onChange={event=>set_unacceptable_service(event.target.value)}
                            InputProps={{
                              endAdornment: unacceptable_service?(
                                <InputAdornment position="end">
                                  <div style={{display:'flex'}}>
                                    <IconButton
                                      aria-label="increase input"
                                      onClick={() => {
                                        if (!unacceptable_services.includes(unacceptable_service))
                                          set_unacceptable_services([...unacceptable_services, unacceptable_service]);
                                        set_unacceptable_service('')
                                      }}
                                    >
                                      <CheckIcon/>
                                    </IconButton>
                                    <IconButton
                                      aria-label="decrease input"
                                      onClick={()=>set_unacceptable_service('')}
                                    >
                                      <CloseIcon/>
                                    </IconButton>
                                  </div>

                                </InputAdornment>
                              ):undefined
                            }}
                          />
                        </Grid>
                      </Grid>
                      {unacceptable_services.length ?
                        <FormControl className={classes.formControl} style={{marginLeft:32}}>
                          <div className={classes.chips}>
                            {unacceptable_services.map((value, index) => (
                              <Chip
                                key={index}
                                label={value}
                                className={classes.chip}
                                onDelete={()=>{
                                  set_unacceptable_services(unacceptable_services.filter(i=>i!==value));
                                }}
                              />
                            ))}
                          </div>
                        </FormControl>:null
                      }
                    </div>
                  </Slide>

                </div>
              </Form>
            </MuiPickersUtilsProvider>
          </Grid>
        </div>
      )
    }}</Formik>
  )
};