import React, {useContext} from "react";
import context from '../../context'
import {AuthPage} from '../../wrappers/view_wrappers'
import SignInToView from '../OtherPages/SignInToView'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import storage_ids from '../../storage_ids'
import {useStoreStorage} from '../../hooks/useStore'
import {useAssignmentsStyles} from '../../components/assignments/styles'
import {title} from '../../components/assignments/variables'
import {NewAssignment} from '../../components/assignments/NewAssignment'
import {Assignment} from '../../components/assignments/Assignment'
import {CoolTitle} from '../../components/CoolTitle'
import Fade from "@material-ui/core/Fade";


export default function () {
  // const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const {user} = useContext(context.user);

  const classes = useAssignmentsStyles(useContext(context.rtl));
  const [assignments] = useStoreStorage(storage_ids.assignments, []);

  if (user===null) return (
    <SignInToView
      head_section_title='Create Assignments'
      head_section_text={'customize your demands and profile unique assignments with prerequisites'}
    />
  );

  return (
    <AuthPage>
      <div className={classes.root}>
        <NewAssignment/>
        {
          assignments.length > 0 &&
          <CoolTitle className={classes.assignment_title}>
            <Typography variant="h5" gutterBottom className={classes.title_text2}>
              {title}
            </Typography>
          </CoolTitle>
        }
        <Grid container justify={'flex-start'}>
          {assignments.map((assignment, index)=>
            <Fade in={true} timeout={300+index*200}>
              <Assignment key={assignment._id} data={assignment}/>
            </Fade>
          )}
        </Grid>
      </div>
    </AuthPage>
  );
}