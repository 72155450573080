import React from "react";
import Tooltip from "../Tooltip";
import {search_types} from "../../variables/variables";

export const title = 'המשימות שלי';

export const entities = ['נהג', 'כלי רכב', 'עגלה', 'עוזר נהג'];
export const entity_types = ['driver', 'vehicle', 'platform', 'helper'];
export const entity_icons = [
  ({...props}) => {const Icon = search_types.drivers.icon; return <Tooltip title={`למשימה נדרש ${entities[0]}`}><Icon {...props}/></Tooltip>},
  ({...props}) => {const Icon = search_types.vehicles.icon; return <Tooltip title={`למשימה נדרש ${entities[1]}`}><Icon {...props}/></Tooltip>},
  ({...props}) => {const Icon = search_types.platforms.icon; return <Tooltip title={`למשימה נדרשת ${entities[2]}`}><Icon {...props}/></Tooltip>},
  ({...props}) => {const Icon = search_types.assistants.icon; return <Tooltip title={`למשימה נדרש ${entities[3]}`}><Icon {...props}/></Tooltip>},
];