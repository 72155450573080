import {do_nothing_function} from "../../utils/do_nothing_function";
import {useAssignmentsStyles} from "./styles";
import React, {useContext, useRef, useState} from "react";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import storage_ids from "../../storage_ids";
import Zoom from "@material-ui/core/Zoom/Zoom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import {StyledMenu} from "../menus/StyledMenu";
import {entities, entity_icons} from "./variables";
import {StyledMenuItem} from "../menus/StyledMenuItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import context from "../../context";
import IconButton from '@material-ui/core/IconButton'

export const AddServiceOld = ({onAddEntity=do_nothing_function}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));

  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useLocalStorage(storage_ids.new_assignment_selected_new_service, 0);

  const open = Boolean(anchorEl);

  const handleClose = () =>
    setAnchorEl(null);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    onAddEntity(index);
    handleClose();
  };

  const handleClick = (event) =>
    setAnchorEl(event.currentTarget);

  return (
    <Zoom in={true} timeout={800}>
      <Grid container justify={'flex-start'} className={classes.entities}>
        <div>
          <Button
            style={{padding: '10px 30px', fontSize: 18}}
            variant="outlined"
            ref={anchorRef}
            color="primary"
            size="small"
            aria-controls={open ? 'כפתור הוספת שירות' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="הוסף שירות"
            aria-haspopup="true"
            onClick={handleClick}
          >
            הוסף שירות
          </Button>
          <FormHelperText style={{textAlign:'center', fontSize: 13}}>
            מה אתה צריך ?
          </FormHelperText>
        </div>

        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {entities.map((option, index) => (
            <StyledMenuItem
              key={option}
              // disabled={index === 2}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              <ListItemText primary={option} />
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </Grid>
    </Zoom>
  )
};

export const AddService = ({onAddEntity=do_nothing_function}) => {
  return entity_icons.map((Icon, index) =>
    <Grid item key={index}>
      <IconButton onClick={()=>onAddEntity(index)} key={index}>
        <Icon fontSize="large"/>
      </IconButton>
    </Grid>
  )
};