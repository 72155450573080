import React, {useContext} from "react";
import context from "../../../context";
import {fetchStore, useEditUserStore} from "../../../stores/useEditUserStore";
import {useStyles} from "../../../assets/jss/useEditUserStyles";
import {CoolTitle} from "../../CoolTitle";
import Typography from "@material-ui/core/Typography";
import {text} from "../../../variables/text/UserEdit";
import Grid from "@material-ui/core/Grid";
import {ReadOnlyItem, TextItem, PasswordItem, SelectItem, CheckboxItem, NewDriverAvatarItem} from "./Items";
import {AddDriverAction, UpdateUserAction} from "./Actions";
import {colors} from '../../custom/CustomCheckbox/Checkbox'
import {regions} from "./EditUser";
import {Drivers} from './Drivers'
import type {Language} from "../../../types";

export const AddDriver = () => {
  const language: Language = useContext(context.language).language;
  const classes = useStyles();
  return (
    <>
      <div style={{position: 'relative'}}>
        <div style={{position: 'absolute', zIndex: -1}}>
          <CoolTitle style={{margin: 10}}>
            <Typography variant="h5" gutterBottom className={classes.title}>
              {text[language.value].title_driver}
            </Typography>
          </CoolTitle>
        </div>
      </div>

      <div className={classes.avatarContainer}>
        <NewDriverAvatarItem/>
      </div>

      <Grid className={classes.container} container spacing={2}  justify={'flex-start'} style={{marginBottom: 20}}>
        {/*'owner',*/}
        {/*'username',*/}
        {/*'image_url_hd',*/}
        {/*'image_url',*/}
        <TextItem path={'driver.full_name'}/>
        <TextItem path={'driver.description'}/>
        {/*'current_address_location',*/}
        {/*'current_geospatial_location',*/}
        {/*'current_location_last_update',*/}
        {/*'operative_locations',*/}
        {/*'created',*/}
        {/*'last_update',*/}
        {/*'likes',*/}
        {/*'views',*/}
        {/*'followers',*/}
        {/*'following',*/}
        {/*'active_orders',*/}
        {/*'active_services',*/}
        {/*'finished_orders',*/}
        {/*'finished_services',*/}
        {/*'canceled_orders',*/}
        {/*'canceled_services',*/}
        {/*'failed_orders',*/}
        {/*'failed_services',*/}
        {/*'rank',*/}
        {/*'votes',*/}
        {/*'stars',*/}
        <TextItem path={'driver.email'} type={'email'}/>
        <TextItem path={'driver.home_country'}/>
        <TextItem path={'driver.home_region'}/>
        <TextItem path={'driver.home_city'}/>
        <TextItem path={'driver.personal_id'}/>
        {/*'personal_id_frontside_link',*/}
        {/*'personal_id_backside_link',*/}
        {/*'personal_id_attachment_link',*/}
        <TextItem path={'driver.birthdate'} type="date"/>
        {/*<TextItem path={'driver.operative_radius_from_current_location'}/>*/}
        {/*'licenses',*/}
        {/*'permits',*/}
        {/*'skills',*/}
        {/*'arrival_ability',*/}
        {/*'work_history',*/}
        <TextItem path={'driver.workplace_radius'} type={'number'}/>
        {/*'prefered_services',*/}
        {/*'unacceptable_services',*/}
        {/*'experienced_with_vehicles',*/}
        {/*'cv_link',*/}
        {/*'traffic_violation_record',*/}

        <TextItem path={'driver.starting_price'} type={'number'}/>

        <TextItem path={'driver.points'} type={'number'}/>
        <TextItem path={'driver.score'} type={'number'}/>
        <TextItem path={'driver.phone_number'}/>
        <TextItem path={'driver.phone_number2'}/>
        <TextItem path={'driver.company'}/>
        <TextItem path={'driver.job'}/>

        <TextItem path={'driver.orders_limit'} type={'number'}/>
        <TextItem path={'driver.services_limit'} type={'number'}/>

        {/*'last_orders',*/}
        {/*'max_orders',*/}
        {/*'orders_cooldown',*/}
        {/*'last_services',*/}
        {/*'max_services',*/}
        {/*'services_cooldown',*/}
        {/*'services_limit',*/}
        <CheckboxItem color={colors.blue} path={'driver.news_letter'}/>
        <CheckboxItem color={colors.blue} path={'driver.validated'}/>
        <CheckboxItem color={colors.blue} path={'driver.blocked'}/>
        <CheckboxItem color={colors.blue} path={'driver.removed'}/>

      </Grid>

      <Grid container spacing={2} justify={'flex-start'} style={{marginBottom: 20}}>
        <AddDriverAction/>
      </Grid>

      <Drivers/>
    </>
  )
};