import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  amber, blue, blueGrey,
  brown, cyan, deepOrange,
  deepPurple, green, grey,
  indigo, lightBlue, lime,
  orange, pink, lightGreen,
  purple, red, teal, yellow,
} from '@material-ui/core/colors';
import CB, {CheckboxProps as CBprops} from '@material-ui/core/Checkbox';

const setStyle = color => ({root: {color: color[400], '&$checked': {color: color[600]}}, checked: {}});

export const colors = {
  amber: 'amber',
  blue: 'blue',
  blueGrey: 'blueGrey',
  brown: 'brown',
  cyan: 'cyan',
  deepOrange: 'deepOrange',
  deepPurple: 'deepPurple',
  green: 'green',
  grey: 'grey',
  indigo: 'indigo',
  lightBlue: 'lightBlue',
  lightGreen: 'lightGreen',
  lime: 'lime',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  red: 'red',
  teal: 'teal',
  yellow: 'yellow',
};

export const AmberCheckbox = withStyles(setStyle(amber))((props) => <CB color="default" {...props} />);
export const BlueCheckbox = withStyles(setStyle(blue))((props) => <CB color="default" {...props} />);
export const BlueGreyCheckbox = withStyles(setStyle(blueGrey))((props) => <CB color="default" {...props} />);
export const BrownCheckbox = withStyles(setStyle(brown))((props) => <CB color="default" {...props} />);
export const CyanCheckbox = withStyles(setStyle(cyan))((props) => <CB color="default" {...props} />);
export const DeepOrangeCheckbox = withStyles(setStyle(deepOrange))((props) => <CB color="default" {...props} />);
export const DeepPurpleCheckbox = withStyles(setStyle(deepPurple))((props) => <CB color="default" {...props} />);
export const GreenCheckbox = withStyles(setStyle(green))((props) => <CB color="default" {...props} />);
export const GreyCheckbox = withStyles(setStyle(grey))((props) => <CB color="default" {...props} />);
export const IndigoCheckbox = withStyles(setStyle(indigo))((props) => <CB color="default" {...props} />);
export const LightBlueCheckbox = withStyles(setStyle(lightBlue))((props) => <CB color="default" {...props} />);
export const LightGreenCheckbox = withStyles(setStyle(lightGreen))((props) => <CB color="default" {...props} />);
export const LimeCheckbox = withStyles(setStyle(lime))((props) => <CB color="default" {...props} />);
export const OrangeCheckbox = withStyles(setStyle(orange))((props) => <CB color="default" {...props} />);
export const PinkCheckbox = withStyles(setStyle(pink))((props) => <CB color="default" {...props} />);
export const PurpleCheckbox = withStyles(setStyle(purple))((props) => <CB color="default" {...props} />);
export const RedCheckbox = withStyles(setStyle(red))((props) => <CB color="default" {...props} />);
export const TealCheckbox = withStyles(setStyle(teal))((props) => <CB color="default" {...props} />);
export const YellowCheckbox = withStyles(setStyle(yellow))((props) => <CB color="default" {...props} />);

export const colorMap = {
  amber: AmberCheckbox,
  blue: BlueCheckbox,
  blueGrey: BlueGreyCheckbox,
  brown: BrownCheckbox,
  cyan: CyanCheckbox,
  deepOrange: DeepOrangeCheckbox,
  deepPurple: DeepPurpleCheckbox,
  green: GreenCheckbox,
  grey: GreyCheckbox,
  indigo: IndigoCheckbox,
  lightBlue: LightBlueCheckbox,
  lightGreen: LightGreenCheckbox,
  lime: LimeCheckbox,
  orange: OrangeCheckbox,
  pink: PinkCheckbox,
  purple: PurpleCheckbox,
  red: RedCheckbox,
  teal: TealCheckbox,
  yellow: YellowCheckbox,
};

export interface CheckboxProps extends CBprops {
  color: 'amber'
    | 'blue'
    | 'blueGrey'
    | 'brown'
    | 'cyan'
    | 'deepOrange'
    | 'deepPurple'
    | 'green'
    | 'grey'
    | 'indigo'
    | 'lightBlue'
    | 'lightGreen'
    | 'lime'
    | 'orange'
    | 'pink'
    | 'purple'
    | 'red'
    | 'teal'
    | 'yellow'
}

export const Checkbox = (props: CheckboxProps) => {
  const {color, ...rest} = props;
  const ColoredCheckbox = colorMap[color];
  return (
    <ColoredCheckbox {...rest}/>
  )
};