import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

export const StyledMenu = withStyles({
  paper: {
    marginTop: 5,
    border: '1px solid #d3d4d5',
    textAlign: 'right',
    minWidth: 150,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));