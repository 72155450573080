import request from 'axios'
import {generic_error} from '../utils/errors'
import {
  handle_login_success,
  handle_is_logged_success,
} from '../utils/success'
import {get_profile_from_local_storage} from '../utils/profile_handler'
import {
  handle_refresh,
  handle_refresh_on_load
} from './refresh'
import {base_config as config} from "../utils/configs";
import {url} from '../utils/urls'


export const login = async (email, password, captcha, set_user, set_snackbar) => {
  try {
    const response = await request.post(url+'/login', {email, password, captcha}, config);
    handle_refresh(response);
    return handle_login_success(response, set_user, set_snackbar)
  } catch (e) {
    return generic_error(e)
  }
};

// export const login_facebook = async ({
//   facebookId,
//   accessToken,
//   imageUrl,
//   email,
//   full_name,
// }, set_user, set_snackbar) => {
//
//   if (already_logged_in_error())
//     return false;
//
//   try {
//     const response = await request.post(url+'/login/facebook', {
//       facebookId,
//       accessToken,
//       imageUrl,
//       email,
//       full_name,
//     }, config);
//
//     // const user: User = response.data;
//     // if (!user.username)
//     //   return {msg:username_required, response:response};
//
//     handle_refresh(response);
//     return handle_login_success(response, set_user, set_snackbar)
//   } catch (e) {
//     return generic_error(e)
//   }
// };
//
export const login_google = async (data, set_user, set_snackbar) => {
  try {
    const response = await request.post(url+'/login/google', data, config);
    handle_refresh(response);
    return handle_login_success(response, set_user, set_snackbar)
  } catch (e) {
    return generic_error(e)
  }
};

export const is_logged = async (user, set_user, set_snackbar) => {
  const data = get_profile_from_local_storage();
  if (!data)
    return false;

  if (!await handle_refresh_on_load(set_user, set_snackbar))
    return false;

  if (user) {
    handle_is_logged_success(set_snackbar, user);
    return user
  }
  if (data) {
    if (data._id) {
      set_user(data);
      handle_is_logged_success(set_snackbar, data);
      return data;
    }
  }
  return false
};
