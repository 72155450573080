import React, {useContext, useEffect} from "react";
import context from '../../context'
import {Page} from '../../wrappers/view_wrappers'
import EditAccountForm from '../../components/tabs/EditAccountTabs'
import SignInToView from '../OtherPages/SignInToView'
import SectionsLayout from '../../components/layout/SectionsLayout'
import PageHeaderBottomSlope from '../../components/headers/PageHeaderBottomSlope'
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";


export default () => {
  const links = useContext(context.links);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {user} = useContext(context.user);
  const {profile_complete} = useContext(context.profile_complete);

  useEffect(() => {
    if (profile_complete===false)
      links.go_to_my_account_setup(100);
  }, [profile_complete]);

  if (user===null) return (
    <SignInToView
      head_section_title='Update your account'
      head_section_text={'Update personal information, new email and change password.'}
    />
  );

  return (
    <Page>
      {!mobile && <PageHeaderBottomSlope/>}
      <SectionsLayout>
        <EditAccountForm/>
      </SectionsLayout>
    </Page>
  );
}