import React, {useCallback} from "react";
import {UseStore} from "zustand";
import {useGetState} from '../../../hooks/useGetNestedStore'
import {useGetLabel} from '../../../hooks/useGetText'
import {useSetState} from '../../../hooks/useSetNestedStore'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox, CheckboxProps} from './Checkbox';

export interface CheckboxStoreProps extends CheckboxProps {
  /** .*/
  useStore: UseStore,
  /** Object using language context and path property to set up the correct label.*/
  text?: Object,
  /** state path*/
  path: string,

  mandatory?: boolean,
}

export const useCheckChange = (setState:Function) => useCallback((event) => setState(event.target.checked ), []);

export const CheckboxStore = React.memo((props:CheckboxStoreProps) => {
  const {useStore, text, path, mandatory, ...rest} = props;
  const value = useStore(useGetState(path));
  const set = useStore(useSetState(path));
  const onChange = useCheckChange(set);
  const label = useGetLabel(path, text, mandatory);
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={onChange}
          name={label}
          {...rest}
        />
      }
      label={label}
    />
  )
});