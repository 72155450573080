import VisibilityTrigger from "../VisibilityTrigger";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import React, {useContext} from "react";
import context from "../../context";

export default ({text='connecting quality drivers with vehicles, and shippers with quality drivers'}) => {
  const {entered_page} = useContext(context.entered_page);
  return (
    <VisibilityTrigger delay={4000}>{({triggered}) =>
      <Grow in={triggered && entered_page} timeout={700}>
        <div
          style={{
            backgroundColor: '#3a3d3f',
            width: '100%',
          }}
        >
          <h4 style={{
            margin: 0,
            // fontSize: width > mobile_size ? 72 : 36,
            padding: 10,
            fontWeight: 100,
            color: 'white',
            textAlign: 'center',
            /* transform: translateY(10px); */
            fontFamily: 'Open Sans',
          }}>
            {triggered &&
            <VisibilityTrigger>{({triggered}) =>
              <Slide direction="right" in={triggered && entered_page} mountOnEnter unmountOnExit timeout={300}>
                <div>
                  {text}
                </div>
              </Slide>
            }</VisibilityTrigger>
            }
          </h4>
        </div>
      </Grow>
    }</VisibilityTrigger>
  )
}