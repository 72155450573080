import React, {useContext} from "react";
import context from '../../context'
import {Page} from '../../wrappers/view_wrappers'
import ComingSoon from '../OtherPages/ComingSoon'
import SectionsLayout from '../../components/layout/SectionsLayout'
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Slide from '@material-ui/core/Slide'


export default () => {
  const {entered_page} = useContext(context.entered_page);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return <ComingSoon/>;

  return (
    <Page>
      <SectionsLayout style={{padding:mobile?0:10, paddingTop: mobile?0:30, paddingBottom: 30}}>
        <Slide direction='up' timeout={700} in={entered_page}>
          <div>

          </div>
        </Slide>
      </SectionsLayout>
    </Page>
  );
}