import createStore, {UseStore} from 'zustand'
import {CreateFetcher} from "../utils/storeFetchFunctions";
import {persist} from '../utils/persist'
import {storage_ids} from '../storage_ids'
import produce from 'immer'
import {register} from "./stores";


// improve performance by fetching state
// from dynamically created functions
// functions are created on store creation time
export const fetchStore = {
  // [state[key]]: state => state[state[key]],
};


export const useMyOrdersStore: UseStore = createStore(
  persist(
    {
      key: storage_ids.my_orders,
      allowlist: ['orders'],
    },
    (set, get) => CreateFetcher(fetchStore, {
      orders: {
        // [id]: {},
      },

      set: fn => set(produce(fn)),

      getOrder: (id: string) => {
        return get().orders[id];
      },

      createOrder: (order) => {
        return get().set(state => {
          state.orders = {[order._id]: order, ...state.orders};
        });
      },
    })
  )
);

register({fetchStore, useMyOrdersStore});