import { makeStyles } from '@material-ui/core/styles'
import style_vars from '../../assets/jss/style_vars'


export default makeStyles({
  grow: {
    flexGrow: 1,
    marginBottom: 0,
    height: 64,
  },

  app_bar: {
    // boxShadow: 'none',
    // borderBottom: style_vars.borders.default,
    height: 64,
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    background: 'linear-gradient(45deg, #374494 30%, #53ffc7 90%)',
    boxShadow: '0 3px 5px 2px rgb(105 172 255 / 30%)',

  },

  app_bar_mobile: {
    // boxShadow: 'none',
    // borderBottom: style_vars.borders.default,
    minHeight: 64,
  },

  site_page: {
    WebkitFontSmoothing: 'antialiased',
    border: '0',
    cursor: 'pointer',
    margin: '0',
    display: 'inline-flex',
    outline: '0',
    alignItems: 'center',
    userSelect: 'none',
    borderRadius: '0',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    WebkitAppearance: 'none',
    WebkitTapHighlightColor: 'transparent',
    padding: '6px 12px',
    overflow: 'hidden',
    position: 'relative',
    fontSize: '1rem',
    maxWidth: '264px',
    boxSizing: 'border-box',
    minHeight: '48px',
    textAlign: 'center',
    flexShrink: '0',
    lineHeight: '1.75',
    whiteSpace: 'normal',
    letterSpacing: '0.02857em',
    color: 'rgba(255,255,255,0.9)',
    minWidth: '72px',
    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
    fontWeight: '400',
    marginLeft: 16,
    textTransform: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0,105,217,0.15)',
      borderColor: 'rgba(0,98,204,0.20)',
      boxShadow: 'none',
      borderRadius: '50%',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(0,98,204,0.25)',
      borderColor: 'rgba(0,92,191,0)',
      borderRadius: '10%',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      borderColor: 'rgba(0,92,191,0)',
      borderRadius: '10%',
    },
    '& span': {
      WebkitFontSmoothing: 'antialiased',
      cursor: 'pointer',
      userSelect: 'none',
      WebkitTapHighlightColor: 'transparent',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.75',
      whiteSpace: 'normal',
      letterSpacing: '0.02857em',
      color: 'rgba(255,255,255,0.9)',
      fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: '400',
      textTransform: 'none',
      boxSizing: 'inherit',
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      '&:hover': {
        color: '#ffffff',
        textShadow: [
          '0 0 5px #fff',
          '0 0 10px #fff',
          // '0 0 30px #e60073',
          // '0 0 40px #e60073',
          // '0 0 50px #e60073',
          // '0 0 60px #e60073',
          // '0 0 70px #e60073',
        ].join(', '),
      },
    }
  }
});