import React, {useContext} from "react";
import Grid from '@material-ui/core/Grid'
import context from "../../context";
import {text} from '../../variables/text/UserEdit'
import {fetchStore, useEditDriverStore} from '../../stores/useEditDriverStore'
import {useStyles} from '../../assets/jss/useEditUserStyles'
import {EditDriverForm} from '../../components/pages/DriverEdit/EditDriverForm'
import type {Language} from '../../types'


export default () => {
  const language: Language = useContext(context.language).language;
  const width: number = useContext(context.size).size.width;
  const loaded: boolean = useEditDriverStore(fetchStore.loaded);
  const loading: boolean = useEditDriverStore(fetchStore.loading);
  const classes = useStyles();

  useEditDriverStore(fetchStore.useGetDriver)();

  return (
   <div className={classes.root} style={{maxWidth: width}}>
     {loading ? text[language.value].loading : !loaded ? text[language.value].loaded_failed :
       <Grid container justify={'flex-start'} direction={'column'}>
         <EditDriverForm/>
       </Grid>
     }
   </div>
  )
}