import React, {useContext, useEffect, useState, useRef, useCallback} from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import context from "../../context";
import {useLocation, useHistory} from 'react-router-dom'
import routes from '../../routes'
import Paper from '@material-ui/core/Paper'
import {animated, useSpring} from 'react-spring'
import {useCollapseWidth} from '../../hooks/useCollapseWidth'
import VisibilitySensor from "react-visibility-sensor";
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Home from '@material-ui/icons/Home'
import {ReactComponent as OrdersIcon} from '../../assets/images/icons/list.svg'
import {ReactComponent as OrderStatusIcon} from '../../assets/images/icons/order_status.svg'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {useSidebarStore} from '../../stores/useSidebarStores'
import {do_nothing_function} from "../../utils/do_nothing_function";
import ExpandIcon from "../icons/ExpandIcon";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import GroupIcon from '@material-ui/icons/Group';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import type {Language} from "../../types";

const ExpansionPanel = withStyles({
  root: {
    borderRadius: 3,
    margin: 3,
    padding: 0,
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      borderRadius: 3,
      margin: 3,
      '&:first-child': {
        marginTop: 3,
      }
    },
  },
  expanded: {

  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderBottom: 'none',
    margin: 0,
    padding: 0,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
      backgroundColor: 'rgb(143 156 220 / 12%)',
      // WebkitTransition: 'background-color 1000ms linear',
      // MsTransition: 'background-color 1000ms linear',
      // transition: 'background-color 1000ms linear',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);


export const sidebar_width = 240;

export const text = {
  eng: {
    HOME: 'HOME',
    users: 'USERS',
    users_list: 'LIST USERS',
    users_create: 'CREATE USER',
    users_update: 'UPDATE USER',
    users_delete: 'DELETE USER',
    users_block: 'BLOCK USER',
  },

  heb: {
    HOME: 'דף הבית',
    users: 'משתמשים',
    users_list: 'רשימת משתמשים',
    users_create: 'צור משתמש',
    users_update: 'עדכן משתמש',
    users_delete: 'מחק משתמש',
    users_block: 'חסום משתמש',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1000,
  },

  container: {
    position: 'absolute',
  },

  inner_container: {
    padding:'10px 0 10px 10px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 10,
    },
  },

  drawerPaper: {
    WebkitBoxShadow: 'inset 0px 0px 0px 1px #d3d4d5',
    MozBoxShadow: 'inset 0px 0px 0px 1px #d3d4d5',
    boxShadow: 'inset 0px 0px 0px 1px #d3d4d5',
    overflow: 'auto',
    height:'100%',
  },

  item_wrapper: {
    padding: 3,
  },

  list: {
    paddingTop: 0,
  },

  expanded_list: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
}));

const StyledListItem = withStyles((theme) => ({
  root: {
    borderRadius: 3,
    '&:hover': {
      backgroundColor: 'rgba(150,161,239,0.2)',
      color: '#51b7ce',
    },
  },
  selected: {
    '&.Mui-selected': {
      background: 'rgba(146,169,239,0.55)',
      color: '#3a4899',
      // color: theme.palette.getContrastText('rgb(255,153,169)'),
      // background: 'rgb(255,153,169)',
      fontWeight: 500,
      '&:hover, &:focus': {
        background: 'rgba(144,202,239,0.55)',
        color: '#334d99',
        '&:before': {
          background: '#4479af',
          transform: 'scale(1)',
        },
      },
      '&:after': {
        width: 'calc(0.5rem + 8px)',
        background: 'rgb(80,108,175)',
        transform: 'translateX(0)',
      },
    },
  },
}))(ListItem);

// TODO: use memo in global context
export const useSideBar = () => {
  const {sidebar, set_sidebar} = useContext(context.sidebar);
  const {style, animaStyle} = useCollapseWidth({width: sidebar_width, open: sidebar});
  return {
    open: sidebar,
    set_open: set_sidebar,
    width: style.width,
    animaStyle,
  }
};

export const Page = ({label, icon=null, route}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  return (
    <div className={classes.item_wrapper}>
      <StyledListItem button onClick={()=>history.push(route)} selected={location.pathname===route}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </StyledListItem>
    </div>
  )
};

export const ExpandPages = ({children=null, label, icon, route}) => {
  const classes = useStyles();
  const location = useLocation();
  const routeStore = useSidebarStore(state => state.getRouteStore(route));
  const toggle = location.pathname.startsWith(route) ? do_nothing_function : routeStore.toggle;
  const expanded = location.pathname.startsWith(route) ? true : routeStore.expanded;

  return (
    <ExpansionPanel
      square
      expanded={expanded}
      onChange={toggle}
      onClick={event => { event.stopPropagation() }}
    >
      <ExpansionPanelSummary aria-controls={label}>
        <StyledListItem button selected={location.pathname===route}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
          <ExpandIcon expanded={expanded}/>
        </StyledListItem>
        <Divider />
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        <List className={classes.expanded_list}>
          {children}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
};

export default function SideBar({height}) {
  const language: Language = useContext(context.language).language;
  const classes = useStyles();
  const location = useLocation();
  const links = useContext(context.links);
  const { animaStyle } = useSideBar();
  const [menu_style, set_menu_style] = useState({marginTop: 0, from: {marginTop: 0}});
  const [is_menu_visible, set_is_menu_visible] = useState(false);
  const [is_paper_visible, set_is_paper_visible] = useState(false);
  const menuRef = useRef();
  const paperRef = useRef();
  const menu_anima_style = useSpring(menu_style);

  const onMenuVisibilityChange = (isVisible) => set_is_menu_visible(isVisible);
  const onPaperVisibilityChange = (isVisible) => set_is_paper_visible(isVisible);

  useScrollPosition(({ prevPos, currPos }) => {
    const menu_bounds: DOMRect = menuRef.current.getBoundingClientRect();
    const paper_bounds: DOMRect = paperRef.current.getBoundingClientRect();

    if (paper_bounds.height > menu_bounds.height && is_paper_visible) {
      const new_style = {...menu_style};

      // DOMRect y is positive but currPos.y is negative...
      const y_view_prev = prevPos.y > 0 ? prevPos.y : -prevPos.y;
      const y_view = currPos.y > 0 ? currPos.y : -currPos.y;
      const y_paper = 74;

      new_style.from.marginTop = new_style.marginTop;

      new_style.marginTop = y_view > y_paper + paper_bounds.height
        ? paper_bounds.height - menu_bounds.height
        : y_view > y_paper
          ? y_view - y_paper
          : 0;

      // go up, handle appbar
      if (y_view < y_view_prev && y_view > 0) {
        new_style.marginTop += y_view > 64 ? 64 : y_view;
        if (paper_bounds.height - menu_bounds.height < new_style.marginTop)
          new_style.marginTop = paper_bounds.height - menu_bounds.height
      }
      set_menu_style(new_style);
    }
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <animated.div style={animaStyle}>
          <div className={classes.inner_container} style={{height}}>
            <VisibilitySensor active={true} partialVisibility={true} onChange={onPaperVisibilityChange} >
              <Paper className={classes.drawerPaper} ref={paperRef}>
                <animated.div style={menu_anima_style}>
                  <VisibilitySensor active={is_paper_visible} onChange={onMenuVisibilityChange}>
                    <div ref={menuRef}>
                      <List className={classes.list}>
                        <Page label={text[language.value].HOME} Icon={Home} route={routes.home}/>
                        <Divider />
                        <ExpandPages label={text[language.value].users} route={routes.users} icon={<PeopleAltIcon/>}>
                          <Page label={text[language.value].users_list} icon={<GroupIcon/>} route={routes.users}/>
                          <Page label={text[language.value].users_create} icon={<PersonAddIcon/>} route={routes.users_create}/>
                          <Page label={text[language.value].users_update} icon={<EditIcon/>} route={routes.users_update}/>
                          <Page label={text[language.value].users_delete} icon={<DeleteIcon/>} route={routes.users_delete}/>
                          <Page label={text[language.value].users_block} icon={<BlockIcon/>} route={routes.users_block}/>
                        </ExpandPages>

                        <Divider />
                      </List>
                    </div>
                  </VisibilitySensor>
                </animated.div>
              </Paper>
            </VisibilitySensor>
          </div>
        </animated.div>
      </div>
    </div>
  );
}