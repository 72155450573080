import React, {useState, Fragment, useContext, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {useCardStyles} from "../../assets/jss/searchStyles";
import stop_propagation from "../../utils/stop_propagation";
import Grid from "@material-ui/core/Grid";
import StarRate from "../rating/StarRate";
import Likes from "../icons/Likes";
import Price from "../icons/Price";
import Availability from "../icons/Availability";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import driver_img from '../../assets/images/avatars/driver-svgrepo-com.svg'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Swal from 'sweetalert2'
import {useRemoveDriver, useDeleteDriver} from '../../api/auth/delete_driver'
import context from '../../context'
import {second} from '../../utils/timeunit'
import sleep from '../../utils/sleep'
import Grow from '@material-ui/core/Grow'
import useOpen from '../../hooks/useOpen'
import {do_nothing_function} from '../../utils/do_nothing_function'
import {ReactComponent as DriverIcon} from '../../assets/images/icons/delivery-man-driver-svgrepo-com.svg'
import no_vehicle_img from '../../assets/images/no_vehicle.png'

import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';

import { blue } from '@material-ui/core/colors';
import uuid from "uuid/v4";

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
    margin: 'auto',
    width: '100%',
    maxWidth: 600,
    marginBottom: 5,
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 5,
      marginTop: 5,
    }
  },
}));

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, driver } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} PaperProps={{style:{background:'transparent'}}}>
      <DriverCard
        showIcon={false}
        driver={driver}
        selected={true}
        actions={[
          <ActionOrder key={1} driver={driver}/>,
        ]}
        className={classes.root}
      />
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};


const GreenButton = withStyles((theme) => ({
  root: {
    color: '#4bcf5c',
    borderColor: 'rgba(104, 228, 102, 0.76)',
    '&:hover': {
      color: '#40b14e',
    },
    '&:active': {
      boxShadow: 'none',
      color: '#5dff72',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  // outlined: {
  //   border: '1px solid rgba(104, 228, 102, 0.76)',
  //   padding: '5px 15px',
  // }
}))(Button);

const BlueButton = withStyles((theme) => ({
  root: {
    color: '#4a9dcf',
    borderColor: 'rgba(74,157,207,0.76)',
    '&:hover': {
      color: '#446ab1',
    },
    '&:active': {
      boxShadow: 'none',
      color: '#63bdff',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: '#cf8369',
    borderColor: 'rgba(207,105,71,0.76)',
    '&:hover': {
      color: '#b14b39',
    },
    '&:active': {
      boxShadow: 'none',
      color: '#ff976b',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(255,129,92,0.45)',
    },
  },
}))(Button);

const Spacer = styled.span`width:15px`;


export function ActionOrder({driver}) {
  const handleClick = () => {
    Swal.fire(
        `Order: ${uuid().slice(0, 8)}`,
      'Your Transport is on It\'s way',
      'success',
    )
  };

  return (
    <GreenButton aria-label="order-now" variant="outlined" onClick={handleClick}>
      ORDER
    </GreenButton>
  )
}

export function ActionProfile({driver}) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <BlueButton aria-label="Profile" variant="outlined" onClick={handleClickOpen}>
        Profile
      </BlueButton>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} driver={driver}/>
    </>
  )
}

export function ActionEdit({onClick}) {
  return (
    <BlueButton aria-label="order-now" variant="outlined" onClick={onClick}>
      EDIT
    </BlueButton>
  )
}

export function ActionGetAssignment({onClick}) {
  return (
    <RedButton aria-label="order-now" variant="outlined" onClick={onClick}>
      GET ASSIGNMENT
    </RedButton>
  )
}

export function DeleteDriverSetting(
  {
    driver,
    set_is_open,
    afterDelete=do_nothing_function,
    handleClose,
  })
{
  const {set_backdrop} = useContext(context.backdrop);
  const remove_driver_api = useRemoveDriver();
  const delete_driver_api = useDeleteDriver();

  const delete_driver = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        set_backdrop(true);
        if (await remove_driver_api({driver_id: driver._id})) {
          await sleep(5*second); // we need at least 5 seconds to make sure new orders will not reach
          if (await delete_driver_api({driver_id: driver._id})) {
            Swal.fire(
              'Deleted!',
              'Your driver has been deleted.',
              'success'
            );
            set_is_open(false);
            await sleep(400);
            afterDelete();
          }
        }
        set_backdrop(false);
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Your driver is safe :)',
          'error'
        )
      }
    });
    handleClose()
  };

  return (
    <MenuItem onClick={delete_driver}>
      Delete Driver
    </MenuItem>
  );
}

export function CardSettings({children, set_is_open}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  return (
    <Fragment>
      <IconButton aria-controls="settings-menu" aria-haspopup="true" aria-label="settings" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children({set_is_open, handleClose})}
      </Menu>
    </Fragment>
  );
}

export function RateIcon({driver}) {
  return (
    <Fragment>
      <StarRate stars={driver.stars} votes={driver.votes}/>
      <Spacer/>
    </Fragment>
  )
}

export function LikeIcon({driver}) {
  return (
    <Fragment>
      <Likes likes={driver.likes}/>
      <Spacer/>
    </Fragment>
  )
}

export function PriceIcon({driver}) {
  return driver.starting_price !== null &&
    <Fragment>
      <Price price={driver.starting_price}/>
      <Spacer/>
    </Fragment>
}


export function AvailabilityIcon({driver}) {
  return (
    <Fragment>
      <Availability price={driver.starting_price}/>
      <Spacer/>
    </Fragment>
  )
}

export function CardIcons({children}) {
  const classes = useCardStyles();
  return (
    <div className={classes.cardIcons}>
      <Grid container alignItems='flex-end' >
        {children}
      </Grid>
    </div>
  )
}

export function DriverCard({open=true, timeout=300, driver={}, actions=[], settings=null, icons=null, showIcon=true, ...props}) {
  const classes = useCardStyles();
  const [expanded, setExpanded] = useState(false);
  const [is_open, set_is_open] = useOpen(open);

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <Grow in={is_open} timeout={timeout}>
      <div>
        {showIcon && <div style={{position:'relative'}}><DriverIcon style={{width:36, height:36, position:'absolute', left:10, top:-15}}/></div>}
        <Card className={classes.root} onClick={stop_propagation} onFocus={stop_propagation} {...props}>

          <CardHeader
            style={{backgroundColor:'rgba(210, 212, 236, 0.49)'}}
            avatar={
              <Avatar aria-label="Driver" src={driver.image_url ? driver.image_url : driver_img} className={classes.avatar}>
                D
              </Avatar>
            }
            title={driver.full_name ? driver.full_name : ''}
            subheader={driver.dist ? (Math.round((driver.dist.calculated + Number.EPSILON) * 10) / 10).toFixed(1) + 'km' : driver.current_address_location ? driver.current_address_location : ''}
            action={settings !== null ? <CardSettings children={settings} set_is_open={set_is_open}/> : undefined}
          />
          <CardMedia
            className={classes.media}
            image={driver.vehicle_image?driver.vehicle_image:no_vehicle_img}
            title="Paella dish"
          />
          <CardContent style={{paddingBottom:0}}>

            <Typography variant="body2" color="textSecondary" component="p" style={{height:60}}>
              {driver.description?driver.description:'no description'}
            </Typography>

            {icons !== null && <CardIcons children={icons}/>}

          </CardContent>
          <CardActions
            // disableSpacing
          >
            {actions}
            {/*<IconButton*/}
            {/*  className={clsx(classes.expand, {*/}
            {/*    [classes.expandOpen]: expanded,*/}
            {/*  })}*/}
            {/*  onClick={handleExpandClick}*/}
            {/*  aria-expanded={expanded}*/}
            {/*  aria-label="show more"*/}
            {/*>*/}
            {/*  <ExpandMoreIcon />*/}
            {/*</IconButton>*/}
          </CardActions>

          {/*<Collapse in={expanded} timeout="auto" unmountOnExit>*/}
          {/*  <CardContent>*/}
          {/*    <Typography paragraph>Method:</Typography>*/}
          {/*    <Typography paragraph>*/}
          {/*      Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10*/}
          {/*      minutes.*/}
          {/*    </Typography>*/}
          {/*    <Typography paragraph>*/}
          {/*      Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high*/}
          {/*      heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly*/}
          {/*      browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken*/}
          {/*      and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and*/}
          {/*      pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add*/}
          {/*      saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.*/}
          {/*    </Typography>*/}
          {/*    <Typography paragraph>*/}
          {/*      Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook*/}
          {/*      without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to*/}
          {/*      medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook*/}
          {/*      again without stirring, until mussels have opened and rice is just tender, 5 to 7*/}
          {/*      minutes more. (Discard any mussels that don’t open.)*/}
          {/*    </Typography>*/}
          {/*    <Typography>*/}
          {/*      Set aside off of the heat to let rest for 10 minutes, and then serve.*/}
          {/*    </Typography>*/}
          {/*  </CardContent>*/}
          {/*</Collapse>*/}
        </Card>
      </div>
    </Grow>
  );
}