import React, {useContext} from "react";
import context from "../../../context";
import {fetchStore, useNewUserStore} from "../../../stores/useNewUserStore";
import Grid from "@material-ui/core/Grid";
import Submit from "../../custom/CustomButtons/Submit";
import {text} from "../../../variables/text/UserEdit";
import type {Language} from "../../../types";

export const CreateUserAction = () => {
  const language: Language = useContext(context.language).language;
  const updating = useNewUserStore(fetchStore.updating);
  const onSubmit = useNewUserStore(fetchStore.useSetUser)();
  return (
    <Grid item>
      <Submit style={{width: 200}} loading={updating} onSubmit={onSubmit} >
        {text[language.value].submit_new_user}
      </Submit>
    </Grid>
  )
};
