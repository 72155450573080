import React, { useContext } from 'react';
import {makeStyles} from "@material-ui/core";
import context from '../../../../context'
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  signin: {color: '#ffffff'},
});

export default () => {
  const { signin, set_signin } = useContext(context.signin);
  const handleOpen = () => set_signin({...signin, open: true});
  const classes = useStyles();
  return (
    <Button className={classes.signin} onClick={handleOpen}>
      SIGN IN
    </Button>
  )
}
