import Swal from 'sweetalert2'

function has_error_message(error) {
  if (error.response)
    if (error.response.data)
      return true;
  return false
}
export const generic_error = (error) => {
  console.log(error);
  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text: has_error_message(error)?error.response.data:'Something went wrong! Please try again later',
    confirmButtonText: 'OK',
    timer: 8000,
  });
  return false
};

export const user_verification_error = (error) => {
  console.log(error);
  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text: `failed to send verification email, ${has_error_message(error)?error.response.data:"please try again later"}`,
    confirmButtonText: 'OK',
  });
  return false
};

export const generic_logout_error = (error) => {
  console.log(error);
  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text: `logout failed to clean up cookies,${" "+has_error_message(error)?error.response.data:""} please delete your cookies manually or try again later`,
    confirmButtonText: 'OK',
    footer: '<a href="https://www.google.com/search?q=delete+cookies">How to delete cookies?</a>'
  });
  return false
};

export const generic_logout_after_refresh_error = (error) => {
  console.log(error);
  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text: `your session has expired but we failed to clean up your cookies,${" "+has_error_message(error)?error.response.data:""} please delete your cookies manually or try again later`,
    confirmButtonText: 'OK',
    footer: '<a href="https://www.google.com/search?q=delete+cookies">How to delete cookies?</a>'
  });
  return false
};
