import { hour } from './timeunit'

export const local_offset = new Date().getTimezoneOffset() / -60;
export const los_angeles_offset = -7;
export const offset_delta = los_angeles_offset - local_offset;

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addHours(date, hours) {
  const result = new Date(date);
  result.setTime(date.getTime() + hours * hour);
  return result;
}

export function timeConversion(millisec) {
  const raw_hours = millisec / hour;
  const hours = Math.floor(raw_hours);

  const raw_minutes = (raw_hours - hours) * 60;
  const minutes = Math.floor(raw_minutes);

  const raw_seconds = (raw_minutes - minutes) * 60;
  const seconds = Math.floor(raw_seconds);

  return {
    hours,
    minutes,
    seconds,
  };
}

export function getDay(date: Date) {
  const result = new Date(date);
  result.setHours(0);
  result.setMinutes(0);
  result.setSeconds(0);
  result.setMilliseconds(0);
  return result;
}

export function timeRemain(date: Date) {
  const next = getDay(addDays(date, 1));

  const millisecionds_delta = next - date;
  const time_remains = timeConversion(millisecionds_delta);

  return {
    hours: time_remains.hours,
    minutes: time_remains.minutes,
    seconds: time_remains.seconds,
  }
}

export function timeRemainLA() {
  return timeRemain(addHours(new Date(), offset_delta));
}