import React, {useContext} from "react";
import context from "../../../context";
import {useStyles} from "../../../assets/jss/useEditUserStyles";
import {CoolTitle} from "../../CoolTitle";
import Typography from "@material-ui/core/Typography";
import {text} from "../../../variables/text/UserEdit";
import Grid from "@material-ui/core/Grid";
import {TextItem, PasswordItem, SelectItem, MultiChipSelectItem} from "./Items";
import {CreateUserAction} from "./Actions";
import {regions, service_types} from '../../../variables/text/lists'
import type {Language} from "../../../types";


export const NewUserForm = () => {
  const language: Language = useContext(context.language).language;
  const classes = useStyles();
  return (
    <>
      <CoolTitle style={{margin: 10}}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {text[language.value].title_new_user}
        </Typography>
      </CoolTitle>

      <Grid className={classes.container} container spacing={2}  justify={'flex-start'} style={{marginBottom: 20, paddingBottom: 20}}>
        <TextItem path={'data.username'} mandatory={true}/>
        <TextItem path={'data.full_name'} mandatory={true}/>
        <TextItem path={'data.email'} type={'email'} mandatory={true}/>
        <PasswordItem path={'data.password'} mandatory={true}/>

        <TextItem path={'data.country'} mandatory={true}/>
        <SelectItem path={'data.region'} options={regions[language.value].values}/>
        <TextItem path={'data.city'}/>
        <TextItem path={'data.phone_number'} mandatory={true}/>
        <TextItem path={'data.personal_id'}/>
        <TextItem path={'data.company'}/>
        <TextItem path={'data.job'}/>

        <MultiChipSelectItem path={'data.service_types'} options={service_types[language.value].values} />
        <MultiChipSelectItem path={'data.order_types'} options={service_types[language.value].values} />

        <TextItem path={'data.credits'} type={'number'}/>
        <TextItem path={'data.coins'} type={'number'}/>
      </Grid>

      <Grid container spacing={2} justify={'flex-start'} style={{marginBottom: 20}}>
        <CreateUserAction/>
      </Grid>
    </>
  )
};