export const env = {
  test_local: 'TEST LOCAL',
  production: 'PRODUCTION',
};

export const local_env = {
  env: env.test_local,
  base_url: 'http://localhost:3000/admin',
  backend: 'http://localhost:5000/admin',
  auth_url: 'http://localhost:5000/admin/auth',
};

export const development_env = {
  env: env.production,
  base_url: 'https://gotrack.io/admin',
  backend: ' https://6y7yh5fuyi.execute-api.us-east-1.amazonaws.com/dev/admin',
  auth_url: 'https://6y7yh5fuyi.execute-api.us-east-1.amazonaws.com/dev/admin/auth',
};

export const production_env = {
  env: env.production,
  base_url: 'https://admin.gotrack.io',
  backend: 'https://api.gotrack.io/admin',
  auth_url: 'https://api.gotrack.io/admin/auth',
};

// export default local_env;
// export default development_env;
export default production_env;