import request from 'axios'
import {url} from '../utils/urls'
import {base_config} from '../utils/configs'
import {search_types} from '../../variables/variables'
import type {SearchFunction, SearchParams, SearchResult} from '../../types'

export const useSearchMembers = ():SearchFunction => {
  return async ({
    find='',     // string
    limit=30,    // number
    skip=0,      // number
    count=false, // boolean
  }: SearchParams):SearchResult => {
    try {
      const res = await request.post(
        `${url}/search/${search_types.members.value}`,
        {
          find,
          limit,
          skip,
          count,
        }, base_config
      );
      if (count) return {count: res.data.count};
      return {found: res.data.found, next: res.data.next};
    } catch (e) {
      console.log(e);
      return false
    }
  }
};

export const useSearchDrivers = ():SearchFunction => {
  return async ({
    find='',     // string
    limit=30,    // number
    skip=0,      // number
    count=false, // boolean
    from_location=null, // [longitude number, latitude number]
    to_location=null,   // [longitude number, latitude number]
    minDistanceMeters=0,     // number
    maxDistanceMeters=10000, // number
    service='', // string
    arrive_time=null, // Date
    depart_time=null, // Date
    price_min=null, // number
    price_max=null, // number
  }: SearchParams):SearchResult => {
    try {
      const res = await request.post(
        `${url}/search/${search_types.drivers.value}`,
        {
          find,
          limit,
          skip,
          count,
          from_location,
          to_location,
          minDistanceMeters,
          maxDistanceMeters,
          service,
          arrive_time,
          depart_time,
          price_min,
          price_max,
        }, base_config
      );
      if (count) return {count: res.data.count};
      return {found: res.data.found, next: res.data.next};
    } catch (e) {
      console.log(e);
      return false
    }
  }
};
