import request from 'axios'
import { handle_new_driver_success } from '../utils/success'
import { Driver, Function } from '../../types'
import {auth_url} from '../utils/urls'
import {auth_config} from '../utils/configs'
import {useAuthCall, useSilentAuthCall} from '../utils/auth_calls'
import {defaultState as defaultPhotoState} from "../../components/custom/CustomUpload/DocumentPhoto";
// import {useContext} from 'react'
// import context from '../../state'

export const useNewDriver = ():Function => {
  // const { set_user } = useContext(context.user);
  // const { set_snackbar } = useContext(context.snackbar);

  return useAuthCall(async (
    {
      full_name, // string
      description, // string
    }
  ): Driver => {
    const res = await request.post(
      `${auth_url}/drivers/new_driver`,
      {full_name, description},
      auth_config
    );
    return res.data;
  });
};

export const useEditDriver = ():Function => {
  // const { set_user } = useContext(context.user);
  // const { set_snackbar } = useContext(context.snackbar);

  return useAuthCall(async (
    {
      driver_id, // string
      full_name, // string
      description, // string
    }
  ): Driver => {
    await request.post(
      `${auth_url}/driver/${driver_id}/edit/profile`,
      {full_name, description},
      auth_config
    );
    return true;
  });
};


export const useEditDriverPhoto = ():Function => {
  return useSilentAuthCall(async (
    {
      driver_id, // string
      file, // Blob
    }
  ): Object => {
    const data = new FormData();
    data.append('file', file);
    const res = await request.post(
      `${auth_url}/driver/${driver_id}/edit/photo`,
      data,
      auth_config
    );
    return {
      image_url_hd: res.data.image_url_hd,
      image_url: res.data.image_url,
    };
  });
};

export const useEditDriverPhotoUrl = ():Function => {
  return useSilentAuthCall(async (
    {
      driver_id, // string
      url, // string
    }
  ): Object => {
    const res = await request.post(
      `${auth_url}/driver/${driver_id}/edit/photo_url`,
      {url},
      auth_config
    );
    return {
      image_url_hd: res.data.image_url_hd,
      image_url: res.data.image_url,
    };
  });
};

export const useEditDriverOperativeLocations = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      operative_locations, // Object
    }
  ): Object => {
    const res = await request.post(
      `${auth_url}/driver/${driver_id}/edit/operative_locations`,
      {operative_locations},
      auth_config
    );
    return res.data;
  });
};

export const useCreateDriverLicense = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      license, // {
      //   type: string,
      //   issued: Date,
      //   expiration: Date,
      //   license_number: string,
      //   weight: number,
      //   vehicle_type: string,
      //   seniority: number,
      //   practical_experience: number,
      //   manual: boolean,
      //   temporary: boolean, // true requires personal_id field
      //   limitations: [string],
      //   permits: {[string]:boolean},
      //   personal_id?: string,    // required if temporary is true
      //   frontside_link: Object, // if frontside_file is a link
      //   backside_link: Object,  // if backside_file is a link
      // }
    }
  ): Object => {
    const data = new FormData();
    license = {...license};
    license.frontside_link = {url: license.frontside_link.url, file: license.frontside_link.file};
    if (license.frontside_link.file !== null) {
      data.append("frontside_file", license.frontside_link.file);
      license.frontside_link.file = true;
      license.frontside_link.url = null;
    }
    license.backside_link = {url: license.backside_link.url, file: license.backside_link.file};
    if (license.backside_link.file !== null) {
      data.append("backside_file", license.backside_link.file);
      license.backside_link.file = true;
      license.backside_link.url = null;
    }
    data.append("license", new Blob([JSON.stringify(license)], {type: 'application/json'}));

    const res = await request.post(
      `${auth_url}/driver/${driver_id}/new/license`,
      data,
      auth_config
    );
    return {
      _id: res.data._id,
      created: new Date(res.data.created),
      last_update: new Date(res.data.last_update),

      type: res.data.type, // string
      license_number: res.data.license_number, // string

      frontside_link: {...defaultPhotoState(), url: res.data.frontside_link},
      backside_link: {...defaultPhotoState(), url: res.data.backside_link},

      issued: res.data.issued === null ? null : new Date(res.data.issued),
      expiration: res.data.expiration === null ? null : new Date(res.data.expiration),

      weight: res.data.weight, // number
      vehicle_type: res.data.vehicle_type, // string

      seniority: res.data.seniority, // number
      practical_experience: res.data.practical_experience, // number

      manual: res.data.manual, // boolean
      temporary: res.data.temporary, // boolean

      limitations: res.data.limitations, // [string]
      permits: res.data.permits, // {[string]:boolean}

      validated: res.data.validated, // boolean
    };
  });
};

export const useEditDriverLicense = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      license, // {
      //   type: string,
      //   issued: Date,
      //   expiration: Date,
      //   license_number: string,
      //   weight: number,
      //   vehicle_type: string,
      //   seniority: number,
      //   practical_experience: number,
      //   manual: boolean,
      //   temporary: boolean, // true requires personal_id field
      //   limitations: [string],
      //   permits: {[string]:boolean},
      //   personal_id?: string,    // required if temporary is true
      //   frontside_link?: string, // if frontside_file is a link
      //   backside_link?: string,  // if backside_link is a link
      // }
      frontside_file, // ?Blob // if frontside_link is not defined
      backside_file,  // ?Blob // if backside_link is not defined
    }
  ): Object => {
    const json = JSON.stringify(license);
    const data = new FormData();
    data.append("license", new Blob([json], {type: 'application/json'}));
    frontside_file !== undefined && data.append("frontside_file", frontside_file);
    backside_file !== undefined && data.append("backside_file", backside_file);

    const res = await request.post(
      `${auth_url}/driver/${driver_id}/edit/license`,
      data,
      auth_config
    );
    return {
      created: new Date(res.data.created),
      last_update: new Date(res.data.last_update),

      type: res.data.type, // string
      license_number: res.data.license_number, // string

      frontside_link: res.data.frontside_link, // string
      backside_link: res.data.backside_link, // string

      issued: res.data.issued === null ? null : new Date(res.data.issued),
      expiration: res.data.expiration === null ? null : new Date(res.data.expiration),

      weight: res.data.weight, // number
      vehicle_type: res.data.vehicle_type, // string

      seniority: res.data.seniority, // number
      practical_experience: res.data.practical_experience, // number

      manual: res.data.manual, // boolean
      temporary: res.data.temporary, // boolean

      limitations: res.data.limitations, // [string]
      permits: res.data.permits, // {[string]:boolean}

      validated: res.data.validated, // boolean
    };
  });
};

export const useEditDriverInfo = ():Function => {
  return useAuthCall(async (
    {
      driver_id,  // string
      email=null, // string
      home_country=null, // string
      home_region=null,  // string
      home_city=null,    // string
      phone_number=null, // string
      company=null, // string
      job=null,     // string
      personal_id=null, // string
      birthdate=null,   // Date
      experience_start_date=null, // Date
    }
  ): Object => {
    const res = await request.post(
      `${auth_url}/driver/${driver_id}/edit/info`,
      {
        email,
        home_country,
        home_region,
        home_city,
        phone_number,
        company,
        job,
        personal_id,
        birthdate,
        experience_start_date,
      },
      auth_config
    );
    return res.data;
  });
};

export const useEditDriverWorkLocations = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      work_locations, // Object
    }
  ): Object => {
    const res = await request.post(
      `${auth_url}/driver/${driver_id}/edit/work_locations`,
      {work_locations},
      auth_config
    );
    return res.data;
  });
};

export const useEditDriverWorkPreferences = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      work_preferences, // Object
    }
  ): Object => {
    const res = await request.post(
      `${auth_url}/driver/${driver_id}/edit/work_preferences`,
      {work_preferences},
      auth_config
    );
    return res.data;
  });
};

export const useEditDriverID = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      work_preferences, // Object
    }
  ): Object => {
    // const res = await request.post(
    //   `${auth_url}/driver/${driver_id}/edit/work_preferences`,
    //   {work_preferences},
    //   auth_config
    // );
    // return res.data;
  });
};

export const useEditDriverCV = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      work_preferences, // Object
    }
  ): Object => {
    // const res = await request.post(
    //   `${auth_url}/driver/${driver_id}/edit/work_preferences`,
    //   {work_preferences},
    //   auth_config
    // );
    // return res.data;
  });
};

export const useEditDriverWorkHistory = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      work_preferences, // Object
    }
  ): Object => {
    // const res = await request.post(
    //   `${auth_url}/driver/${driver_id}/edit/work_preferences`,
    //   {work_preferences},
    //   auth_config
    // );
    // return res.data;
  });
};

export const useEditDriverPermits = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      work_preferences, // Object
    }
  ): Object => {
    // const res = await request.post(
    //   `${auth_url}/driver/${driver_id}/edit/work_preferences`,
    //   {work_preferences},
    //   auth_config
    // );
    // return res.data;
  });
};

export const useEditDriverSkills = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      work_preferences, // Object
    }
  ): Object => {
    // const res = await request.post(
    //   `${auth_url}/driver/${driver_id}/edit/work_preferences`,
    //   {work_preferences},
    //   auth_config
    // );
    // return res.data;
  });
};

export const useEditDriverTrafficViolationRecord = ():Function => {
  return useAuthCall(async (
    {
      driver_id, // string
      work_preferences, // Object
    }
  ): Object => {
    // const res = await request.post(
    //   `${auth_url}/driver/${driver_id}/edit/work_preferences`,
    //   {work_preferences},
    //   auth_config
    // );
    // return res.data;
  });
};


export const useCreateDriver = () => useAuthCall(async ({user_id, data}) => {
  return (await request.post(`${auth_url}/user/${user_id}/driver`, {data}, auth_config)).data;
});


export const useGetDrivers = () => useAuthCall(async ({user_id, projection}) => {
  return (await request.get(`${auth_url}/user/${user_id}/drivers`, {...auth_config, params: {
      projection: JSON.stringify(projection),
    }})).data;
});


export const useAdminEditDriver = ():Function => {
  return useAuthCall(async (
    {
      user_id, // string
      driver_id, // string
      data,
    }
  ): Driver => {
    await request.patch(
      `${auth_url}/user/${user_id}/driver/${driver_id}`,
      {data},
      auth_config
    );
    return true;
  });
};


export const useAdminEditDriverPhoto = ():Function => {
  return useSilentAuthCall(async (
    {
      user_id, // string
      driver_id, // string
      file, // Blob
    }
  ): Object => {
    const data = new FormData();
    data.append('file', file);
    const res = await request.put(
      `${auth_url}/user/${user_id}/driver/${driver_id}/photo`,
      data,
      auth_config
    );
    return {
      image_url_hd: res.data.image_url_hd,
      image_url: res.data.image_url,
    };
  });
};


export const useGetDriver = () => useAuthCall(async ({user_id, driver_id, projection}) => {
  return (await request.get(`${auth_url}/user/${user_id}/driver/${driver_id}`, {...auth_config, params: {
      projection: JSON.stringify(projection),
    }})).data;
});
