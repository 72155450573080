import {useEffect, useContext} from 'react'
import context from '../context'

export default () => {
  const { set_size } = useContext(context.size);

  const updateWindowDimensions = () => {set_size({ width: window.innerWidth, height: window.innerHeight })};

  useEffect(() => {
    // get window size on mount
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions); // unmount
  }, []);
}
