import React, {useEffect, useState, useContext} from "react";
import {Formik, Form, Field} from 'formik';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid';
import flag from '../../../assets/images/icons/flag.svg'
import city_icon from '../../../assets/images/icons/city.svg'
import region_icon from '../../../assets/images/icons/region.svg'
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {useSpring, animated} from 'react-spring'
import Tooltip from '../../Tooltip';
import sleep from "../../../utils/sleep";
import context from '../../../context'
import {Input} from '../../custom/CustomInput/FormikInput'
import ids from "../../../ids";
import type {Function, User, Driver} from "../../../types";
import InfoIcon from '@material-ui/icons/Info';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationIcon from '@material-ui/icons/Navigation';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button';
import {useEditDriverOperativeLocations} from '../../../api/auth/drivers'

export const useStyles = makeStyles({
  close: {maxHeight: 0},
  open: {maxHeight: 10000},
});

interface Values {
  operative_locations: Object;
}

const validate = values => {
  const errors: Partial<Values> = {};
  const operative_locations: Object = values.operative_locations;

  if (Object.keys(operative_locations).length === 0)
    errors.operative_locations = 'Please Add at least 1 country where you operate';

  return errors;
};

function valuetext(value) {
  return `${value}km`;
}

const EditLocation = ({country='', region='', city='', radius=10, onCancel, onSave}) => {
  const maxWidth = useMediaQuery(useTheme().breakpoints.down('xs')) ? 280 : 400;
  const [region_style, set_region_style] = useState({height: 0, marginTop: 0, from: {height: 0, marginTop: 0}});

  const [open, set_open] = useState(true);

  const [location, set_location] = useState({
    country,
    region,
    city,
    radius,
  });

  const regionStyle = useSpring(region_style);

  useEffect(() => {
    if (!location.country) {
      set_region_style({
        height: 0,
        marginTop: 0,
        from: {height: region_style.from.height, marginTop: region_style.from.marginTop}
      })
    } else {
      set_region_style({
        height: 41,
        marginTop: 30,
        from: {height: region_style.from.height, marginTop: region_style.from.marginTop}
      })
    }
  }, [location]);

  const handleCancel = async () => {
    set_open(false);
    await sleep(500);
    onCancel();
    await sleep(1000);
    set_open(true);
  };

  const handleSave = async () => {
    set_open(false);
    await sleep(500);
    onSave(location);
    await sleep(1000);
    set_open(true);
  };

  return (
    <div>
      <Slide direction="right" timeout={1200} in={open} mountOnEnter unmountOnExit>
        <div style={{marginBottom: 20, marginTop: 30}}>
          <div style={{display: 'flex'}}>
            <Tooltip title={
              'select your country ' +
              'so we can better address ' +
              'your needs and connect you ' +
              'with opportunities near your vicinity.'}>
              <img src={flag} alt="" style={{width: 20, height: 20, margin: 2, marginTop: 10}}/>
            </Tooltip>
            <CountryDropdown
              style={{
                width: maxWidth - 30,
                marginLeft: 7,
                padding: 10,
                fontSize: '1rem',
                color: '#575757',
              }}
              name="country"
              value={location.country}
              onChange={(_, e) => set_location({...location, country:_, region:'', city:''})}
              // onBlur={handleBlur}
            />
          </div>

          <Grow in={!!location.country && open} timeout={1000}>
            <animated.div style={regionStyle}>
              <div style={{display: 'flex'}}>
                <Tooltip title={
                  'some countries have regions or states within them. ' +
                  'please select your region if you have one.'}>
                  <div style={{width: 20, height: 20, margin: 2, marginTop: 10}}>
                    <img src={region_icon} alt=""
                         style={{width: 40, height: 40, position: 'relative', top: -7, right: 10}}/>
                  </div>
                </Tooltip>
                <RegionDropdown
                  style={{
                    width: maxWidth - 30,
                    marginLeft: 7,
                    padding: 10,
                    fontSize: '1rem',
                    color: '#575757',
                  }}
                  name="state"
                  country={location.country}
                  value={location.region}
                  onChange={(_, e) => set_location({...location, region:_, city:''})}
                  // onBlur={handleBlur}
                />
              </div>
            </animated.div>
          </Grow>
        </div>
      </Slide>

      <Slide direction="right" timeout={1400} in={open} mountOnEnter unmountOnExit>
        <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
          <Grid item>
            <Tooltip title={
              'write down where you live. ' +
              'we consider this information private and don\'t sell/share it.'}>
              <img src={city_icon} alt="" style={{width: 20, height: 20, margin: 2}}/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              label='city'
              name='city'
              type='text'
              fullWidth
              value={location.city}
              onChange={(event) => set_location({...location, city:event.target.value})}
              // onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </Slide>

      <Slide direction="right" timeout={1400} in={open} mountOnEnter unmountOnExit>
        <div>
          <div style={{display: 'flex'}}>
            <Tooltip title={'Define how far you\'re willing to operate away from your city.'}>
              <LocationOnIcon/>
            </Tooltip>
            <Typography style={{marginLeft:6}} gutterBottom>
              Radius
            </Typography>
          </div>
          <div style={{paddingLeft: 32}}>
            <Slider
              defaultValue={10}
              value={typeof location.radius === 'number' ? location.radius : 10}
              onChange={(event, newValue) => set_location({...location, radius: newValue})}
              getAriaValueText={valuetext}
              // valueLabelFormat={valuetext}
              aria-labelledby="city-radius"
              step={1}
              marks={[{value: location.radius, label: `${location.radius}km`}]}
              min={10}
              max={1000}
              valueLabelDisplay="auto"
              // valueLabelDisplay="on"
            />
          </div>
        </div>
      </Slide>

      <Grow in={open} timeout={300}>
        <div style={{display:'flex', marginBottom:30}}>
          <div style={{flexGrow:1}}/>
          {location.country &&
            <IconButton aria-label="save location" onClick={handleSave}>
              <CheckIcon/>
            </IconButton>
          }
          <IconButton aria-label="cancel" onClick={handleCancel}>
            <CloseIcon/>
          </IconButton>
        </div>
      </Grow>
    </div>
  )
};

const AddLocation = ({last_location, operative_locations, set_operative_locations}) => {
  const [edit, set_edit] = useState(false);
  const [country_list, set_country_list] = useState([]);

  const update_location = (props) => {
    const new_country = props.country;
    const new_region = props.region;
    const new_city = props.city;
    const new_radius = props.radius;

    if (!new_country)
      return;

    const update = {...operative_locations};

    // TODO: delete location parent if empty

    // create new location
    if (!Object.keys(update).includes(new_country)) {
      update[new_country] = {
        [new_region]: {
          [new_city]: new_radius
        }
      };
    }
    else if (!Object.keys(update[new_country]).includes(new_region)) {
      update[new_country][new_region] = {
        [new_city]: new_radius
      };
      if (Object.keys(update[new_country]).includes('')) {
        delete update[new_country]['']
      }
    }
    else if (!Object.keys(update[new_country][new_region]).includes(new_city)) {
      update[new_country][new_region][new_city] = new_radius;

      if (Object.keys(update[new_country][new_region]).includes('')) {
        delete update[new_country][new_region]['']
      }
    }

    set_operative_locations(update)
  };

  useEffect(() => {
    set_country_list(Object.keys(operative_locations))
  }, [operative_locations]);

  useEffect(() => {
    if (country_list.length === 0)
      set_edit(true)
  }, [operative_locations]);

  return edit
    ?
    <EditLocation
      country={last_location !== null ? last_location.country : ''}
      region={last_location !== null && last_location.region ? last_location.region : ''}
      city={last_location !== null && last_location.city ? last_location.city : ''}
      radius={last_location !== null && last_location.radius ? last_location.radius : 10}
      onCancel={()=>set_edit(false)}
      onSave={({country, region, city, radius}) => {
        set_edit(false);
        update_location({country, region, city, radius})
      }}
    />
    :
    <Grow in={true} timeout={700}>
      <Grid container alignItems='center' onClick={()=>set_edit(true)}>
        <Tooltip title={'Add new locations to where you want to operate'}>
          <Fab size="small" color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Tooltip>
        <Button color="primary">
          Add Location
        </Button>
      </Grid>
    </Grow>;
};

const Location = ({country, region, city, radius, remove_location, update_location}) => {
  const [open, set_open] = useState(true);
  const [edit, set_edit] = useState(false);

  return edit
    ?
      <EditLocation
        country={country}
        region={region}
        city={city}
        radius={radius}
        onCancel={()=>set_edit(false)}
        onSave={({country, region, city, radius}) => {
          set_edit(false);
          update_location({country, region, city, radius})
        }}
      />
    :
    <Slide direction="right" timeout={1200} in={open} mountOnEnter unmountOnExit>
      <TextField
        style={{marginBottom:10}}
        disabled
        fullWidth
        value={`${country}${region?', '+region:''}${city?', '+city:''}${city&&radius?', '+radius+'km':''}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <div style={{display:'flex'}}>
                <IconButton
                  aria-label="increase input"
                  onClick={async () => {
                    set_open(false);
                    await sleep(500);
                    set_edit(true);
                    await sleep(500);
                    set_open(true);
                  }}
                >
                  <EditIcon/>
                </IconButton>
                <IconButton
                  aria-label="decrease input"
                  onClick={async () => {
                    set_open(false);
                    await sleep(500);
                    remove_location();
                    await sleep(500);
                    set_open(true);
                  }}
                >
                  <CloseIcon/>
                </IconButton>
              </div>
            </InputAdornment>
          )
        }}
      />
    </Slide>
};

const Locations = ({locations, set_locations, operative_locations, set_operative_locations}) => {

  useEffect(() => {
    const new_locations = [];

    const countries: Array<string> = Object.keys(operative_locations);
    countries.forEach((country) => {
      const regions = Object.keys(operative_locations[country]);
      regions.forEach((region) => {
        const cities = Object.keys(operative_locations[country][region]);
        cities.forEach((city) => {
          const radius = operative_locations[country][region][city];

          const remove_location = () => {
            const update = {...operative_locations};
            delete update[country][region][city];

            if (Object.keys(update[country][region]).length === 0) {
              delete update[country][region];

              if (Object.keys(update[country]).length === 0) {
                delete update[country];
              }
            }
            set_operative_locations(update)
          };

          const update_location = (props) => {
            const new_country = props.country;
            const new_region = props.region;
            const new_city = props.city;
            const new_radius = props.radius;

            if (!new_country)
              return;

            const update = {...operative_locations};

            // remove old location
            delete update[country][region][city];

            if (Object.keys(update[country][region]).length === 0) {
              delete update[country][region];

              if (Object.keys(update[country]).length === 0) {
                delete update[country];
              }
            }

            // create new location
            if (!Object.keys(update).includes(new_country)) {
              update[new_country] = {
                [new_region]: {
                  [new_city]: new_radius
                }
              };
            }
            else if (!Object.keys(update[new_country]).includes(new_region)) {
              update[new_country][new_region] = {
                [new_city]: new_radius
              };
            }
            else if (!Object.keys(update[new_country][new_region]).includes(new_city)) {
              update[new_country][new_region][new_city] = new_radius
            }

            set_operative_locations(update)
          };

          new_locations.push({
            country,
            region,
            city,
            radius,
            remove_location,
            update_location,
          })
        })
      })
    });

    set_locations(new_locations)
  }, [operative_locations]);

  return locations.map((props, index)=><Location key={index} {...props}/>)
};

export default ({template, onSuccess, open, set_formProps}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const edit_operative_locations = useEditDriverOperativeLocations();

  const [errors, set_errors] = useState({});

  const maxWidth = mobile ? 280 : 400;

  const [onLoad, set_onLoad] = useState(true);

  const [operative_locations, set_operative_locations] = useState(
    template && template.operative_locations
      ? template.operative_locations
      : {}
      );

  const [locations, set_locations] = useState([]);

  const validate_locations = ():string => {
    const new_errors = validate({operative_locations});
    set_errors(new_errors);
    return !new_errors.operative_locations;
  };

  const onSubmit = async (values, {setSubmitting}) => {

    if (!validate_locations()) {
      setSubmitting(false);
      return;
    }

    const res = await edit_operative_locations({
      driver_id: template._id,
      operative_locations,
    });

    setSubmitting(false);
    !!res && onSuccess();
  };

  useEffect(() => {
    if (onLoad)
      return set_onLoad(false);
    validate_locations()
  }, [operative_locations]);

  return (
    <Formik
      initialValues={{}}
      validate={()=>({})}
      onSubmit={onSubmit}
    >{({submitForm, isSubmitting, values, setFieldValue, handleChange, handleBlur}) => {

      const classes = useStyles();

      useEffect(() => {
        set_formProps({submitForm, isSubmitting});
      }, [submitForm, isSubmitting]);

      useEffect(() => {
        const root = document.getElementById(ids.new_driver_profile_form_root);
        open
          ? root.className = classes.open
          : root.className = classes.close;
      }, [open]);

      return (
        <div id={ids.new_driver_profile_form_root} style={{width:'100%', transition: 'max-height 0.5s ease-in-out'}}>
          <Grid container justify='center'>
            <Form style={{paddingTop: 10, width: maxWidth}}>
              <Slide direction="right" timeout={1200} in={open} mountOnEnter unmountOnExit>
                <div>

                  <Grid container justify='center' style={{marginBottom:10}}>
                    <div>
                      <Grid container>
                        <Typography variant="h6" gutterBottom style={{marginBottom:0, textAlign:'center'}}>
                          Select Locations
                        </Typography>
                        <span style={{width:0}}>
                        <Grid container style={{height:'100%', marginLeft:5}} direction="row" alignItems="center">
                          <Tooltip title={
                            <div style={{paddingLeft:10, paddingRight:10, maxWidth:250, fontSize:14, fontWeight:300}}>
                              <p>
                                {
                                  'tell us where you work ' +
                                  'so we can better address ' +
                                  'your needs and connect you ' +
                                  'with opportunities near your vicinity. '
                                }
                              </p>
                              <p>
                                select your country, region<br/>
                                city & acceptable distance<br/>
                                <br/>
                                and start receiving orders<br/>
                                from your local area immediately!
                              </p>
                            </div>
                          }>
                            <InfoIcon fontSize='small'/>
                          </Tooltip>
                        </Grid>
                      </span>
                      </Grid>
                      <Typography variant="body2" gutterBottom style={{textAlign:'center'}}>
                        Where do you operate ?
                      </Typography>
                    </div>
                  </Grid>

                  <Locations
                    locations={locations}
                    set_locations={set_locations}
                    operative_locations={operative_locations}
                    set_operative_locations={set_operative_locations}
                  />

                  <div style={{height:25}}/>

                  <AddLocation
                    last_location={locations.length > 0 ? locations[locations.length-1] : null}
                    operative_locations={operative_locations}
                    set_operative_locations={set_operative_locations}
                  />

                  <div style={{height:25}}/>

                  <div style={{maxWidth:200}}>
                    {Object.keys(errors).map((error, index) =>
                      <div key={index} style={{color:'red', marginBottom:10, fontSize:12}}>
                        {errors[error]}
                      </div>
                    )}
                  </div>

                </div>
              </Slide>
            </Form>
          </Grid>
        </div>
      )
    }}</Formik>
  )
};