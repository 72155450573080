import React, {useContext} from 'react';
import bg from '../../assets/images/wallpaper.jpg';
import Fade from '@material-ui/core/Fade';
import context from "../../context";
import {mobile_size} from '../../variables/variables'
import VisibilityTrigger from "../VisibilityTrigger";
import Grow from "@material-ui/core/Grow";
import {useSpring, animated} from 'react-spring'


export default ({children=null, light=true, backgroundImage=bg, darken=false, height, style={height: '100%', transition: '2s', background: 'black', width: '100vw'}, ...other}) => {
  const width: number = useContext(context.size).size.width;

  if (!height)
    height = width > mobile_size ? 500 : 200;

  return (
    <VisibilityTrigger delay={3000}>{({triggered}) => {
      const props = useSpring({height: triggered ? height : 0, from: {height: 0}});
      return (
        <Grow in={triggered} timeout={2000} style={{height:style.height}}>
          <animated.div style={props}>
          <div style={style} {...other}>
            <Fade in={true} timeout={2500}>
              <div style={{
                height: height,
                backgroundImage: `url(${backgroundImage})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}>
                {light &&
                <div style={{
                  background: darken
                    ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.9), transparent), radial-gradient(at 45% 40%, rgba(0, 118, 255, 0.45) 0%, transparent 60%, transparent 60%)'
                    : 'radial-gradient(at 45% 40%, rgba(0, 118, 255, 0.45) 0%, transparent 60%, transparent 60%)',
                  width: '100%',
                  height: '100%',
                }}>
                  {children}
                </div>
                }
              </div>
            </Fade>
          </div>
          </animated.div>
        </Grow>
      )
    }}</VisibilityTrigger>
  )
};
