import React, {useContext, useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import context from "../../../context";
import {follow, is_following, unfollow} from "../../../api/auth/profiles";
import {SmallBlueButton} from './Buttons'
import {followStyles} from '../../../assets/jss/buttonStyles'


export default function Follow({profile, isfollowing=null, btnProps={}, ...props}) {
  const classes = followStyles();

  const {user, set_user} = useContext(context.user);

  const [isFollowing, setIsFollowing] = useState(isfollowing);
  const [loading, setLoading] = React.useState(false);

  const Empty = () => <div style={{height:57}}/>;

  useEffect(() => {(async()=>{
    if (isfollowing === null) {
      if (user) {
        setIsFollowing(null);
        setLoading(true);
        setIsFollowing(await is_following({owner_id:profile._id, set_user}));
        setLoading(false);
      }
    }
  })()}, [user, profile]);

  const handleButtonClick = async () => {
    if (!user)
      return <Empty/>;

    if (loading)
      return <Empty/>;

    setLoading(true);

    if (isFollowing) {
      if (await unfollow({owner_id:profile._id, set_user})) {
        setIsFollowing(false);
        set_user({...user, following: user.following-1})
      }
    }

    else {
      if (await follow({owner_id:profile._id, set_user})) {
        setIsFollowing(true);
        set_user({...user, following: user.following+1})
      }
    }

    setLoading(false);
  };

  if (!user)
    return <Empty/>;

  if (isFollowing === null)
    return (
      <div className={classes.root} {...props}>
        <div className={classes.wrapper} style={{right:50, top:16}}>
          <CircularProgress size={24} className={classes.buttonProgress} />
        </div>
      </div>
    );

  return (
    <div className={classes.root} {...props}>
      <div className={classes.wrapper}>
        <SmallBlueButton
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={handleButtonClick}
          {...btnProps}
        >
          {isFollowing ? 'unFollow' : 'Follow'}
        </SmallBlueButton>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}