import React, {Fragment, useState, useEffect} from 'react'
import sleep from "../../utils/sleep";
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField';
import GradeIcon from '@material-ui/icons/Grade';
import Autocomplete from '@material-ui/lab/Autocomplete';
import request from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Paper from '@material-ui/core/Paper'
import {
  useAsynchronousStyles,
  // useCardStyles
} from '../../assets/jss/searchStyles'
import Collapse from '@material-ui/core/Collapse';
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import Fab from "@material-ui/core/Fab";
import CloseIcon from '@material-ui/icons/Close'
import Divider from "@material-ui/core/Divider";
import Pagination from '@material-ui/lab/Pagination';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Button from '@material-ui/core/Button';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import stop_propagation from '../../utils/stop_propagation'
import {useSearchDrivers} from '../../api/public/search'
import type {SearchResult, SearchFunction} from '../../types'
import StarRate from '../rating/StarRate'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import styled from 'styled-components'
import beautifulNumber, {bigNumber} from '../../utils/beautifulNumber'
import Likes from '../icons/Likes'
import Price from '../icons/Price'
import {DriverCard, ActionOrder, ActionProfile} from '../cards/DriverCard'

export const useCardStyles = makeStyles((theme) => ({
  root: {
    width: '90vw',
    // minWidth: 300,
    maxWidth: 600,
    margin: 5,
    marginBottom: 5,
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      marginBottom: 5,
      marginTop: 5,
    }
  },
}));

const useGetResults = (loading_search_results, handleSearchResultsOptions) => {
  // useEffect(() => {
  //   let active = true;
  //
  //   if (!loading_search_results) {
  //     return;
  //   }
  //
  //   (async () => {
  //     const response = await request.get('https://country.register.gov.uk/records.json?page-size=5000');
  //     await sleep(1e3); // For demo purposes.
  //     const countries = response.data;
  //
  //     if (active) {
  //       const all_options = Object.keys(countries).map((key) => countries[key].item[0]);
  //       const options = [];
  //       for (let i=0; i < 10 && i < all_options.length; i++)
  //         options.push(all_options[i]);
  //
  //       handleSearchResultsOptions(options);
  //     }
  //   })();
  //
  //   return () => {
  //     active = false;
  //   };
  // }, [loading_search_results]);
};

const useResetResults = (search_category, handleSearchResultsOptions) => {
  // const is_logged: boolean = useContext(context.is_logged);

  // useEffect(() => {
  //   if (is_logged === false) {
  //     handleSearchResultsOptions([]);
  //   }
  // }, [is_logged]);

  useEffect(() => {
    handleSearchResultsOptions([])
  }, [search_category]);
};

const useOnLoad = (
  search_drivers:SearchFunction, set_pages_date,
  set_count,
  set_loading_search_results
) => {useEffect(()=>{(async ()=>{
  const search_params = {
    find: '',
    limit: 30,
    skip: 0,
    count: false,
    from_location: null,
    to_location: null,
    minDistanceMeters: 0,
    maxDistanceMeters: 10000,
    service: '',
    arrive_time: null,
    depart_time: null,
    price_min: null,
    price_max: null,
  };
  const r1 = search_drivers(search_params);
  const r2 = search_drivers({...search_params, count: true});
  const res1 = await r1; if (res1 !== false) set_pages_date({1:res1.found});
  const res2 = await r2; if (res2 !== false) set_count(res2.count);
  set_loading_search_results(false)
})()}, [])};

export function AsynchronousSearch(
  {
    search_category,
    handleCloseDrawer,
    open_search_results, set_open_search_results,
    loading_search_results, set_loading_search_results,
    pages, set_pages,
    page, set_page,
    pages_data, set_pages_date,
    count, set_count,
  }) {
  const mobile: boolean = useMediaQuery(useTheme().breakpoints.down('xs'));
  const classes = useAsynchronousStyles();
  const card_classes = useCardStyles();
  const [search_value, set_search_value] = useState('');
  const [search_inputValue, set_search_inputValue] = useState('');
  const [results_per_page, set_results_per_page] = useState(30);
  const [search_results, set_search_results] = useState(0);

  const search_drivers:SearchFunction = useSearchDrivers();
  useOnLoad(search_drivers, set_pages_date, set_count, set_loading_search_results);

  const handleOpenSearchResults = () => set_open_search_results(true);
  const handleCloseSearchResults = () => set_open_search_results(false);
  // const handleSearchResultsOptions = (options:Array<Object>) => set_search_results_options(options);
  const handleSearchValueChange = (event, new_value:string) => set_search_value(new_value);
  const handleSearchInputChange = (event, new_value:string) => set_search_inputValue(new_value);

  const handleClose = (event) => {open_search_results ? handleCloseSearchResults() : handleCloseDrawer(event)};

  // useGetResults(loading_search_results, handleSearchResultsOptions);
  // useResetResults(search_category, handleSearchResultsOptions);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item style={{paddingBottom:0}}>
        <IconButton color="inherit">
          <SearchIcon/>
        </IconButton>
      </Grid>

      <Grid item style={{flexGrow:1}}>
        <TextField
          // {/*{...params}*/}
          fullWidth={true}
          value={search_inputValue}
          onChange={event => set_search_inputValue(event.target.value)}
          onFocus={handleOpenSearchResults}
          // onBlur={handleCloseSearchResults}
          // label="Asynchronous"
          // variant="outlined"
          placeholder='Search…'
          InputProps={{
            // ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading_search_results ? <CircularProgress color="inherit" size={20} /> : null}
                {/*{params.InputProps.endAdornment}*/}
              </Fragment>
            ),
          }}
        />
        <Autocomplete
          id="asynchronous-search"
          disableCloseOnSelect
          classes={{paper: classes.paper}}
          open={true}
          onOpen={handleOpenSearchResults}
          onClose={handleCloseSearchResults}
          value={search_value}
          onChange={handleSearchValueChange}
          inputValue={search_inputValue}
          onInputChange={handleSearchInputChange}
          getOptionSelected={(option, value) => option.full_name === value.full_name}
          getOptionLabel={(option) => option.full_name}
          options={pages_data[page]}
          loading={loading_search_results}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{display:'none'}}
              fullWidth={true}
              // label="Asynchronous"
              // variant="outlined"
              placeholder='Search…'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {/*{loading_search_results && <CircularProgress color="inherit" size={20}/>}*/}
                    {/*{params.InputProps.endAdornment}*/}
                  </Fragment>
                ),
              }}
            />
          )}
          PaperComponent={({children=null, ...props}) => (
            <Collapse in={open_search_results}>
              <Paper {...props}>
                <Divider/>
                <div style={{overflowY:'auto'}}>
                  {children}
                </div>
                {open_search_results && !loading_search_results && pages > 1 &&
                <Grid container alignItems='center' justify='center' alignContent='center'>
                  <Pagination
                    style={{height: 44, background: 'transparent', minHeight: 'unset'}}
                    count={pages}
                    variant="outlined"
                    page={page}
                    onChange={(event, page) => set_page(page)}
                  />
                </Grid>
                }
              </Paper>

            </Collapse>

          )}
          renderOption={(option, { selected }) =>
            <DriverCard
              driver={option}
              selected={selected}
              actions={[
                <ActionOrder key={1} driver={option}/>,
                <ActionProfile key={2} driver={option}/>
                ]}
              className={card_classes.root}
            />
          }
        />
      </Grid>

      <Grid item style={{paddingBottom:0}}>
        <Fab color="inherit" onClick={handleClose} size={mobile?"small":"large"}>
          <CloseIcon/>
        </Fab>
      </Grid>
    </Grid>
  );
}
