import React from "react";
import Grid from "@material-ui/core/Grid";
import {bigNumber} from "../../utils/beautifulNumber";
import {do_nothing_function} from '../../utils/do_nothing_function'
import IconButton from '@material-ui/core/IconButton'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {withStyles, makeStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';

const GreenButton = withStyles({
  root: {
    color: green[500],
    '&:hover': {
      color: green[700],
    },
  },
})(IconButton);

const GreyButton = withStyles({
  root: {
    color: grey[500],
    '&:hover': {
      color: grey[700],
    },
  },
})(IconButton);

const useStyles = makeStyles({
  root: props => ({width: props.size > 9999 ? 64 : props.size > 999 ? 48 : props.size > 99 ? 36 : 24, marginBottom: 15}),
  text: {minHeight: 7, width:'100%', lineHeight: '7px', textAlign:'center'},
});

export default ({price, onClick=do_nothing_function, ...props}) => {
  const active = typeof price === 'number' && price > 0;
  const classes = useStyles({size: active ? price : 0});
  const Btn = active ? GreenButton : GreyButton;
  return (
    <Grid container justify='center' className={classes.root} {...props}>
      <Btn onClick={onClick} size={'small'}>
        <AttachMoneyIcon color={'inherit'} fontSize={'inherit'}/>
      </Btn>
      <small className={classes.text}>
        {active ? '+'+bigNumber(Math.round((price + Number.EPSILON) * 100) / 100) : ''}
      </small>
    </Grid>
  )
}