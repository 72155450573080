import React, {useState, useEffect, useContext, Fragment} from 'react'
import {search_categories} from '../../variables/variables'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import PageviewIcon from '@material-ui/icons/Pageview';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import {AsynchronousSearch} from '../search/AsynchronousSearch'
import ids from '../../ids'
import {useAsynchronousStyles} from "../../assets/jss/searchStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import ExpandIcon from '../icons/ExpandIcon'
import Collapse from '@material-ui/core/Collapse'
import {CustomSkinCardMap, MyFancyComponent} from '../maps/MapCard'
import {get_location} from '../../utils/geolocation'
import Map from '../maps/Map'
import context from '../../context'
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {hour, year} from '../../utils/timeunit'
import DateFnsUtils from '@date-io/date-fns';
import InputNumber from '../custom/CustomInput/InputNumber'

function printTime(date:Date) {
  return `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`
}

function BasicDateTimePicker({fromDate, set_fromDate, untilDate, set_untilDate}) {
  const [fromDateError, set_fromDateError] = useState(undefined);
  const [untilDateError, set_untilDateError] = useState(undefined);

  const handleFromDateChange = (new_date:Date) => {
    if (new_date !== null) {
      const now = new Date();
      now.setTime(now.getTime() - hour);

      if (new_date.getTime() < now)
        return set_fromDateError(`cannot select dates before ${printTime(now)}`);

      if (new_date.getTime() > now.getTime() + year + hour)
        return set_fromDateError('cannot select dates after +1 year');

      if (untilDate !== null && new_date.getTime() > untilDate.getTime())
        return set_fromDateError(`cannot select dates after ${printTime(untilDate)}`);
    }
    set_fromDateError(undefined);
    set_fromDate(new_date)
  };

  const handleUntilDateChange = (new_date:Date) => {
    if (new_date !== null) {
      const now = new Date();
      now.setTime(now.getTime() - hour);

      if (new_date.getTime() < now.getTime())
        return set_untilDateError(`cannot select dates before ${printTime(now)}`);

      if (new_date.getTime() > now.getTime() + year + hour)
        return set_untilDateError('cannot select dates after +1 year');

      const from:Date = fromDate === null ? now : fromDate;

      if (new_date.getTime() < from.getTime())
        return set_untilDateError(`cannot select dates before ${printTime(from)}`);

      if (fromDate === null) {
        set_fromDateError(undefined);

        if (now.getTime()+hour < new_date.getTime())
          from.setTime(from.getTime() + hour);

        set_fromDate(from);
      }
    }
    set_untilDateError(undefined);
    set_untilDate(new_date)
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{margin:10, marginLeft: 20, flexDirection: 'column', maxWidth: 300}}>
        <DateTimePicker
          style={{marginBottom:10}}
          value={fromDate}
          disablePast
          clearable
          format="yyyy/MM/dd hh:mm a"
          onChange={handleFromDateChange}
          label="from"
          helperText={fromDateError?fromDateError:"set the availability starting point for the assignment"}
          showTodayButton
          error={fromDateError}
          onError={set_fromDateError}
        />

        <DateTimePicker
          style={{marginBottom:20}}
          value={untilDate}
          disablePast
          clearable
          format="yyyy/MM/dd hh:mm a"
          onChange={handleUntilDateChange}
          label="until"
          helperText={untilDateError?untilDateError:"set an end point for the assignment"}
          showTodayButton
          error={untilDateError}
          onError={set_untilDateError}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

const useSetDefaultPlace = (set_from) => {
  useEffect(()=>{(async()=>{
    const data = await get_location();
    if (data !== false && data.lat !== undefined && data.lon !== undefined)
      set_from({ lat: data.lat, lng: data.lon });
    else
      set_from({ lat: 34.8516, lng: 31.0461 });
  })()}, []);
};

export default function SearchDrawer(
  {
    handleCloseDrawer,
    search_category,
    handleCategoryChange,
    openDrawer,
  }) {
  const classes = useAsynchronousStyles();
  const mobile: boolean = useMediaQuery(useTheme().breakpoints.down('xs'));
  const {width, height} = useContext(context.size).size;
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandPlace, set_expandPlace] = useState(false);
  const [expandTime, set_expandTime] = useState(false);
  const [expandPrice, set_expandPrice] = useState(false);

  const [pages, set_pages] = useState(1);
  const [page, set_page] = useState(1);
  const [pages_data, set_pages_date] = useState({1:[]});
  const [count, set_count] = useState(null);

  const [from, set_from] = useState(null);

  const [fromPlace, set_fromPlace] = useState({address: null, lat: null, lng: null});
  const [toPlace, set_toPlace] = useState({address: null, lat: null, lng: null});

  const [fromDate, set_fromDate] = useState(null);
  const [untilDate, set_untilDate] = useState(null);

  const [fromPrice, set_fromPrice] = useState(null);
  const [toPrice, set_toPrice] = useState(null);

  const [open_search_results, set_open_search_results] = useState(false);
  const [loading_search_results, set_loading_search_results] = useState(open_search_results && pages_data[page] && pages_data[page].length === 0);

  const handleCategoryClick = (event) => setAnchorEl(event.currentTarget);
  const handleCategoryClose = () => setAnchorEl(null);
  const handlePlaceClick = () => set_expandPlace(!expandPlace);
  const handleTimeClick = () => set_expandTime(!expandTime);
  const handlePriceClick = () => set_expandPrice(!expandPrice);
  const onSelectFromAddress = ({address, lat, lng}) => {set_fromPlace({address, lat, lng})};
  const onSelectToAddress = ({address, lat, lng}) => {set_toPlace({address, lat, lng})};

  const openCategory = Boolean(anchorEl);
  const category_id = openCategory ? ids.search_category_popover : undefined;

  useSetDefaultPlace(set_from);

  return (
    <Drawer
      anchor={'top'}
      open={openDrawer}
      onClose={handleCloseDrawer}
      classes={{paper: classes.drawPaper}}
    >
      <div
        // style={{width:'auto', height:'100vh'}}
        style={{paddingBottom:80}}
        role="presentation"
      >
        <Grid container>
          <Grid item xs={12}>
            <List>
              <ListItem style={{paddingLeft:5, paddingBottom:0}}>
                <AsynchronousSearch
                  search_category={search_category}
                  handleCloseDrawer={handleCloseDrawer}
                  open_search_results={open_search_results} set_open_search_results={set_open_search_results}
                  loading_search_results={loading_search_results} set_loading_search_results={set_loading_search_results}
                  pages={pages} set_pages={set_pages}
                  page={page} set_page={set_page}
                  pages_data={pages_data} set_pages_date={set_pages_date}
                  count={count} set_count={set_count}
                />
              </ListItem>

              <ListItem button aria-describedby={category_id} onClick={handleCategoryClick} style={{paddingTop:0, height: 44}}>
                <ListItemText style={{flex:'0 1 auto', paddingRight:5, marginTop: 0, paddingLeft: 44, fontsize:mobile?12:16}}>
                  Category:  <Chip label={search_category.label} onClick={handleCategoryClick} size={mobile?'small':'medium'}/>
                </ListItemText>
                <ExpandIcon expanded={openCategory}/>
                {open_search_results && !loading_search_results && <span
                  style={{
                    fontFamily: 'arial,sans-serif',
                    fontSize: mobile?11:14,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    WebkitTransition: 'all 220ms ease-in-out',
                    color: '#70757a',
                    // padding: 8,
                    paddingLeft: mobile?20:32,
                  }}
                >
                  {count !== null && count + ' results'}
                </span>}
              </ListItem>
              <Popover
                id={category_id}
                open={openCategory}
                anchorEl={anchorEl}
                onClose={handleCategoryClose}
                anchorOrigin={{vertical: 'center', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
              >
                <List component="div" disablePadding>
                  {search_categories.map((category, index) =>
                    <ListItem
                      selected={search_category===category} button key={index}
                      onClick={()=>{handleCategoryChange(index);handleCategoryClose()}}
                    >
                      <ListItemIcon>
                        <category.icon/>
                      </ListItemIcon>
                      <ListItemText primary={category.label} />
                    </ListItem>
                  )}
                </List>
              </Popover>

            </List>
          </Grid>
        </Grid>

        <div
          // onClick={handleCloseDrawer}
          // onKeyDown={handleCloseDrawer}
        >
          <List>
            <Divider/>

            <ListItem button onClick={handlePlaceClick}>
              <ListItemIcon><LocationOnIcon/></ListItemIcon>
              <ListItemText style={{flex:'0 1 auto', flexDirection:'column'}}>
                {!(fromPlace.address || toPlace.address) && <div>Place</div>}
                {fromPlace.address && <div style={{display:'flex', flexDirection:'row', paddingBottom: toPlace.address ? 5 : 0}}><div style={{width:42}}>from</div> <Chip label={fromPlace.address} onClick={handlePlaceClick} size={mobile?'small':'medium'}/></div>}
                {toPlace.address && <div style={{display:'flex', flexDirection:'row'}}><div style={{width:42}}>to</div><Chip label={toPlace.address} onClick={handlePlaceClick} size={mobile?'small':'medium'}/></div>}
              </ListItemText>
              <ExpandIcon expanded={expandPlace}/>
            </ListItem>
            <Collapse in={expandPlace} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider/>
                {/*<ListItem button className={classes.nested}>*/}
                {/*  <ListItemIcon>*/}
                {/*    <Settings />*/}
                {/*  </ListItemIcon>*/}
                {/*  <ListItemText inset primary="Starred" />*/}
                {/*</ListItem>*/}

                <div style={{height:height-170, width:width, margin:'auto'}}>
                  <Map
                    onSelectFromAddress={onSelectFromAddress}
                    onSelectToAddress={onSelectToAddress}
                  />
                </div>

               {/* {from !== null &&*/}
               {/* <CustomSkinCardMap*/}
               {/*   title={''}*/}
               {/*   // center={from}*/}
               {/* />*/}
               {/*// <MyFancyComponent defaultCenter={from}/>*/}
               {/* }*/}

                <Divider/>
              </List>
            </Collapse>

            <ListItem button onClick={handleTimeClick}>
              <ListItemIcon><ScheduleIcon/></ListItemIcon>
              <ListItemText style={{flex:'0 1 auto', flexDirection:'column'}}>
                {!(fromDate || untilDate) && <div>Time</div>}
                {fromDate && <div style={{display:'flex', flexDirection:'row', paddingBottom: untilDate ? 5 : 0}}><div style={{width:42}}>from</div> <Chip label={(printTime(fromDate))} onClick={handleTimeClick} size={mobile?'small':'medium'}/></div>}
                {untilDate && <div style={{display:'flex', flexDirection:'row'}}><div style={{width:42}}>to</div><Chip label={printTime(untilDate)} onClick={handleTimeClick} size={mobile?'small':'medium'}/></div>}
              </ListItemText>
              <ExpandIcon expanded={expandTime}/>
            </ListItem>
            <Collapse in={expandTime} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider/>
                  <BasicDateTimePicker
                    fromDate={fromDate}
                    untilDate={untilDate}
                    set_fromDate={set_fromDate}
                    set_untilDate={set_untilDate}
                  />
                <Divider/>
              </List>
            </Collapse>

            <ListItem button onClick={handlePriceClick}>
              <ListItemIcon><AttachMoneyIcon/></ListItemIcon>
              <ListItemText style={{flex:'0 1 auto', flexDirection:'column'}}>
                {fromPrice === null && toPrice === null && <div>Price</div>}
                {fromPrice !== null && <div style={{display:'flex', flexDirection:'row', paddingBottom: toPrice ? 5 : 0}}><div style={{width:42}}>from</div> <Chip label={`${fromPrice}$`} onClick={handlePriceClick} size={mobile?'small':'medium'}/></div>}
                {toPrice !== null && <div style={{display:'flex', flexDirection:'row'}}><div style={{width:42}}>to</div><Chip label={`${toPrice}$`} onClick={handlePriceClick} size={mobile?'small':'medium'}/></div>}
              </ListItemText>
              <ExpandIcon expanded={expandPrice}/>
            </ListItem>
            <Collapse in={expandPrice} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider/>

                <div style={{margin:10, marginLeft: 20, flexDirection: 'column', maxWidth: 300}}>
                  <InputNumber
                    label={'from price'}
                    value={fromPrice}
                    setValue={set_fromPrice}
                    suffix={'$'}
                    iconSize={'small'}
                    style={{marginBottom: 10}}
                  />

                  <InputNumber
                    label={'to price'}
                    value={toPrice}
                    setValue={set_toPrice}
                    suffix={'$'}
                    iconSize={'small'}
                    style={{marginBottom: 20}}
                  />
                </div>

                <Divider/>
              </List>
            </Collapse>

          </List>

          <Divider />

          <List>

            <ListItem button>
              <ListItemIcon><PageviewIcon/></ListItemIcon>
              <ListItemText primary={'Advanced Search'} />
            </ListItem>

            <ListItem button>
              <ListItemIcon><CloudDoneIcon/></ListItemIcon>
              <ListItemText>
                My Search Templates <Chip label={0} onClick={()=>{}} size='small'/>
              </ListItemText>
            </ListItem>

          </List>
        </div>
      </div>
    </Drawer>
  );
}