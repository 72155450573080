import React, {useState, useContext} from "react";
import context from "../../../context";
import {fetchStore, useEditUserStore} from "../../../stores/useEditUserStore";
import {useStyles} from "../../../assets/jss/useEditUserStyles";
import {CoolTitle} from "../../CoolTitle";
import Typography from "@material-ui/core/Typography";
import {text} from "../../../variables/text/UserEdit";
import Grid from "@material-ui/core/Grid";
import {ReadItem, ReadOnlyItem, TextItem, PasswordItem, SelectItem, CheckboxItem, NewDriverAvatarItem} from "./Items";
import {AddDriverAction, UpdateUserAction} from "./Actions";
import {projection} from "../../../variables/projections";


import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import type {Language} from "../../../types";


const useItemStyles = makeStyles({
  root: {
    padding: 10,
  },

  count: {
    margin: 15,
    fontSize: 18,
    marginBottom: 15,
  },

  titles: {
    width: 200,
    overflowWrap: 'break-word',
  },

  items: {

  },

  container: {

  },

  item: {
    padding: 10,
    border: '1px solid #b8bde2',
    backgroundColor: '#fff',
    minHeight: 64,
    marginBottom: 10,
  },

  spacer: {
    width: 20,
  },

  flexer: {
    display: 'flex',
  },

  container2: {
    marginTop: 5,
    marginBottom: 5,
  },

  search: {
    marginBottom: 30,
    margintop: 15,
  },
});


export const DataItem = ({item, field}) => {
  const classes = useItemStyles();
  const language: Language = useContext(context.language).language;
  return (
    <Grid item className={classes.titles}>
      <div><b>{text[language.value].driver[field]}:</b></div>
      <div>{JSON.stringify(item[field])}</div>
    </Grid>
  )
};

export const Action = ({data}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const language: Language = useContext(context.language).language;
  const links = useContext(context.links);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEdit = () => {
    links.go_to_driver_update(data.owner, data._id);
    handleClose()
  };

  return (
    <div>
      <Tooltip title={text[language.value].driver_actions.title}>
        <IconButton aria-label="actions" color="inherit" onClick={handleClick}>
          <MoreVertIcon/>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={text[language.value].driver_actions.edit} />
        </MenuItem>
      </Menu>
    </div>
  )
};

export const Title = () => {
  const language: Language = useContext(context.language).language;
  const classes = useStyles();
  return (
    <CoolTitle style={{margin: 10}}>
      <Typography variant="h5" gutterBottom className={classes.title}>
        {text[language.value].title_drivers}
      </Typography>
    </CoolTitle>
  )
};

export const Drivers = () => {
  const data = useEditUserStore(fetchStore.drivers);
  const classes = useItemStyles();
  useEditUserStore(fetchStore.useGetDrivers)();
  return (
    <>
      <Title/>

      <Grid className={classes.container} container justify={'flex-start'} direction={'column'}>
        {data.map((i, index) =>
          <Grid item key={i._id} className={classes.item} style={data.length===index+1?{marginBottom:0}:{}}>
            <div className={classes.flexer}>
              <Action data={i}/>
              <div className={classes.spacer}/>
              <Grid className={classes.container2} container justify={'space-between'} spacing={2}>
                {['full_name',
                  'created',
                  'last_update',
                  'personal_id',
                  'birthdate',
                  'licenses',
                  'email',
                  'phone_number',
                  'phone_number2',
                  'news_letter',
                  'validated',
                  'blocked',
                  'removed',
                ].map(f=><DataItem item={i} field={f}/>
                  )}
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>

    </>
  )
};