import React, {useContext} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import context from "../../context";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'rgba(56,56,56,0.56)',
  },
}));

export default function SimpleBackdrop() {
  const classes = useStyles();
  const {backdrop} = useContext(context.backdrop);

  return (
    <Backdrop className={classes.backdrop} open={backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}