import {useContext, useEffect} from 'react'
import { createMuiTheme } from '@material-ui/core/styles';
import context from '../context'
import {theme_data} from '../assets/jss/theme'

export const useThemeRTL = () => {
  const {rtl} = useContext(context.rtl);
  const {set_custom_theme} = useContext(context.custom_theme);

  useEffect(()=>{
    const data = {...theme_data};
    if (rtl) {
      document.body.setAttribute('dir', 'rtl');
      data.direction = 'rtl';
    } else {
      document.body.setAttribute('dir', 'ltr');
      data.direction = 'ltr';
    }

    set_custom_theme(createMuiTheme(data));
  }, [rtl]);
};
