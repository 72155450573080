export const routes = {
  home: '/',
  redirect_oauth: '/auth/redirect/login/',

  my_account: '/my_account',
  my_account_setup: '/my_account/setup',
  my_account_edit: '/my_account/edit',
  my_assets: '/my_assets',
  my_assignments: '/orders/my_assignments',

  orders: '/orders',
  new_orders: '/orders/new',
  orders_status: '/orders/status',

  users: '/admin/users',

  users_create: '/admin/users/create',
  users_update: '/admin/users/edit',
  users_delete: '/admin/users/remove',
  users_block: '/admin/users/block',

  user_update: '/admin/user/:user_id/edit',
  user_driver_update: '/admin/user/:user_id/driver/:driver_id/edit',

  privacy: '/privacy',
  terms: '/terms',
  help: '/help',
  contact: '/contact',
};

export default routes