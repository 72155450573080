import React, {useContext} from "react";
import Grid from '@material-ui/core/Grid'
import context from "../../context";
import {text} from '../../variables/text/UserEdit'
import {fetchStore, useEditUserStore} from '../../stores/useEditUserStore'
import {useStyles} from '../../assets/jss/useEditUserStyles'
import {Tabs} from '../../components/pages/UserEdit/Tabs'
import {EditUser} from '../../components/pages/UserEdit/EditUser'
import {AddDriver} from '../../components/pages/UserEdit/AddDriver'
import {AddVehicle} from '../../components/pages/UserEdit/AddVehicle'
import type {Language} from "../../types";


export default () => {
  const language: Language = useContext(context.language).language;
  const width: number = useContext(context.size).size.width;

  const loaded = useEditUserStore(fetchStore.loaded);
  const loading = useEditUserStore(fetchStore.loading);

  useEditUserStore(fetchStore.useGetUser)();

  const classes = useStyles();

  return (
   <div className={classes.root} style={{maxWidth: width}}>
     {loading ? text[language.value].loading : !loaded ? text[language.value].loaded_failed :
       <Grid container justify={'flex-start'} direction={'column'}>
         <EditUser/>
         <Tabs>
           <AddDriver/>
           <AddVehicle/>
         </Tabs>
       </Grid>
     }
   </div>
  )
}