import {UseStore} from 'zustand'


export type Stores = {|
  [string]: {
    store: UseStore,
    fetch: Object,
  },
|}

export const stores: Stores = {
  // here we can register stores
  // so they can communicate with each other bi-directionaly,
  // and not have to dangerously import each other
};

export const register = ({...store}) => {
  const {fetchStore, ...store_name} = store;
  const name = Object.keys(store_name).find(name=>name);
  stores[name] = {
    fetch: fetchStore,
    store: store[name],
  };
};