import React, {useContext} from "react";
import {useAssignmentsStyles} from "./styles";
import {useStoreStorage} from "../../hooks/useStore";
import storage_ids from "../../storage_ids";
import Zoom from "@material-ui/core/Zoom/Zoom";
import Grid from "@material-ui/core/Grid";
import RoomIcon from "@material-ui/icons/Room";
import Tooltip from "../Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import GoogleMapsInput from "../custom/CustomInput/GoogleMapsInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import CancelIcon from '@material-ui/icons/Close'
import DeleteIcon from "@material-ui/icons/Delete";
import context from '../../context'

export const DistributionPoints = () => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  const [data, set_data] = useStoreStorage(storage_ids.new_assignment_distribution_points,[]);
  const [new_data, set_new_data] = useStoreStorage(storage_ids.new_assignment_new_distribution_point,'');

  const onDeleteData = () => {
    set_data([])
  };

  const onAdd = value => {
    set_new_data(value.description);
    if (value)
      set_data([...data, value]);
  };

  const onDelete = index => {
    const array = [...data];
    array.splice(index, 1);
    set_data(array)
  };

  // TODO: display on sharon's command
  return null;

  return (
    <Zoom in={true} timeout={500}>
      <div className={classes.service_root}>
        <Grid container justify='flex-start' alignItems='center'>
          <RoomIcon/>
          <div style={{fontSize: 20, padding: '0 10px'}}>נקודות פריקה:</div>
          <div style={{flexGrow: 1}}/>
          <Tooltip title={'מחק את כל נקודות הפריקה'}>
            <IconButton aria-label="delete" onClick={onDeleteData}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        </Grid>

        <div style={{fontSize: 16, padding: '10px 32px'}}>
          <Grid container spacing={1} alignItems="flex-start" style={{marginBottom: 15}}>
            {data.map((i, index) =>
              <Grid container spacing={1} className={classes.distributionPoint} alignItems="center" key={index}>
                <FormControl>
                  {i.description}
                </FormControl>
                <IconButton
                  edge="start"
                  className={classes.menuButton2}
                  color="inherit"
                  aria-label="check"
                  aria-haspopup="true"
                  onClick={()=>onDelete(index)}
                >
                  <CancelIcon style={{color: '#ababab85'}}/>
                </IconButton>
              </Grid>
            )}
          </Grid>

          <FormControl style={{width: '100%', maxWidth: 300}}>
            <GoogleMapsInput initialValue={new_data} onSelect={onAdd} TextFieldProps={{id: "assignment-location-start", variant:"standard"}}/>
            <FormHelperText style={{textAlign:'right'}}>הוסף נקודת פריקה</FormHelperText>
          </FormControl>

        </div>
      </div>
    </Zoom>
  )
};