import React, {useContext} from "react";
import context from '../../context'
import {Page} from '../../wrappers/view_wrappers'
import RegisterCompleteForm from '../../components/forms/account/RegisterCompleteForm'
import SignInToView from '../OtherPages/SignInToView'
import SectionsLayout from '../../components/layout/SectionsLayout'
import PageHeaderBottomSlope from '../../components/headers/PageHeaderBottomSlope'
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";


export default () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {user} = useContext(context.user);

  if (user===null) return (
    <SignInToView
      head_section_title='Setup your account and start working'
      head_section_text={
        'simply choose your username and fill in some personal details. ' +
        'after which you can start booking orders and profiling your transport assets. ' +
        'and don\'t worry, your personal information will not be shared without explicit consent.'
      }
    />
  );

  return (
    <Page>
      {!mobile && <PageHeaderBottomSlope/>}
      <SectionsLayout>
        <RegisterCompleteForm/>
      </SectionsLayout>
    </Page>
  );
}