import React from "react";
import { makeStyles } from '@material-ui/core/styles'


export const layoutStyles = makeStyles({
  page: {
    width: '100%',
    minHeight:'100vh',
    display: 'inline-block;',
    // display: 'flex',
    flexDirection:'column',
    // width: '100vw',
    // flexGrow: 1,
  },
});

