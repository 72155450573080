import React, {useContext, useEffect, useState} from "react";
import context from "../../../context";
import {fetchStore, useEditDriverStore} from '../../../stores/useEditDriverStore'
import {useStyles} from "../../../assets/jss/useEditUserStyles";
import {CoolTitle} from "../../CoolTitle";
import Typography from "@material-ui/core/Typography";
import {text} from "../../../variables/text/UserEdit";
import {CheckboxItem, DriverAvatarItem, TextItem} from "./Items";
import Grid from "@material-ui/core/Grid";
import {colors} from "../../custom/CustomCheckbox/Checkbox";
import {UpdateDriverAction, EditUserAction} from "./Actions";
import type {Function, Language, License as LicenseType} from "../../../types";
import Slide from "@material-ui/core/Slide/Slide";
import License from "../../forms/new_driver/licences/License";
import AddLicense from "../../forms/new_driver/licences/AddLicense";
import {Form} from "formik";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useEditDriverLicense} from "../../../api/auth/drivers";
import {validate} from "../../forms/new_driver/licences/validate";
import Swal from "sweetalert2";
import {useGetInputState} from "../../../hooks/useGetNestedStore";


export const EditDriverLicenses = () => {
  const driver = useEditDriverStore(fetchStore.driver);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {set_backdrop} = useContext(context.backdrop);

  const edit_license:Function = useEditDriverLicense();

  const [loading, set_loading] = useState(false);
  const [errors, set_errors] = useState({});
  const [license_list, set_license_list] = useState([]);

  const maxWidth = mobile ? 280 : 400;

  const [onLoad, set_onLoad] = useState(true);

  const [licenses, set_licenses] = useState(
    driver && driver.licenses
      ? driver.licenses
      : {}
  );

  const [last_license, set_last_license] = useState(null);

  const validate_licenses = ():string => {
    const new_errors = validate({licenses});
    set_errors(new_errors);
    return !new_errors.licenses;
  };

  const onSubmit = async (values, {setSubmitting}) => {

    if (!validate_licenses()) {
      setSubmitting(false);
      return;
    }

    // let res;

    // license_list.forEach(item => {
    //   const license = item.license;
    //   edit_license({
    //     driver_id: template._id,
    //     license: {
    //
    //     }
    //   })
    // });

    //   res = await create_driver({
    //     full_name: values.full_name,
    //     description,
    //   });
    //   console.log('image', image);
    //   if (!!res) {
    //     handle_new_driver_success();
    //
    //     if (image.blob !== null) {
    //       const {image_url_hd, image_url} = await edit_photo({driver_id: res._id, file:image.blob});
    //       res.image_url_hd = image_url_hd;
    //       res.image_url = image_url;
    //     }
    //     else if (image.url !== null && typeof image.url === 'string') {
    //       if (image.url.startsWith('https://')) {
    //         const {image_url_hd, image_url} = await edit_photo_url({
    //           driver_id: res._id,
    //           url: image.url,
    //         });
    //         res.image_url_hd = image_url_hd;
    //         res.image_url = image_url;
    //       } else if (image.url.startsWith('data:')) {
    //         //  TODO: maybe handle this... might be difficult
    //       }
    //     }
    //
    //     set_user({...user, drivers:user.drivers+1});
    //   }
    // }
    // else {
    //   res = await edit_profile({
    //     full_name: values.full_name,
    //     description,
    //   });
    // }
    // setSubmitting(false);
    // if (!!res) {
    //   set_template({...template, driver_created: true, ...res});
    // }
  };

  useEffect(() => {
    if (onLoad)
      return set_onLoad(false);
    validate_licenses()
  }, [licenses]);

  useEffect(() => {
    const license_list = Object.keys(licenses);
    if (license_list.length > 0)
      set_last_license(licenses[license_list[license_list.length-1]]);
    else
      set_last_license(null);
  }, [licenses]);

  useEffect(() => {
    set_license_list(
      Array.from(
        Object.keys(licenses).map((license_type) => ({
          license: licenses[license_type],
          remove_license: async () => {
            set_backdrop(true);
            const update = {...licenses};
            delete update[license_type];
            set_licenses(update);
            set_backdrop(false);
            return true;
          },
          update_license: async (license, new_license: LicenseType) => {
            set_backdrop(true);
            const update = {...licenses};
            delete update[license_type];
            update[new_license.type] = new_license;
            set_licenses(update);
            set_backdrop(false);
            return true;
          },
        }))
      )
    )
  }, [licenses]);

  return (
    <Slide direction="right" timeout={1200} in={true} mountOnEnter unmountOnExit>
      <div style={{marginTop:15}}>
        {license_list.map(({license, remove_license, update_license}, index) =>
          <License
            key={index}
            open={true}
            license={license}
            remove_license={remove_license}
            update_license={update_license}
          />
        )}

        <div style={{height:25}}/>

        <AddLicense
          template={driver}
          last_license={last_license}
          licenses={licenses}
          set_licenses={set_licenses}
        />

        <div style={{height:25}}/>

        <div style={{maxWidth:200}}>
          {Object.keys(errors).map((error, index) =>
            <div key={index} style={{color:'red', marginBottom:10, fontSize:12}}>
              {errors[error]}
            </div>
          )}
        </div>
        {Object.keys(errors).length > 0 && <div style={{height:25}}/>}
      </div>
    </Slide>
  )
};

export const EditDriverForm = () => {
  const language: Language = useContext(context.language).language;
  const classes = useStyles();

  const requirements = useEditDriverStore(useGetInputState('driver.requirements'));
  const questionnaire = useEditDriverStore(useGetInputState('driver.questionnaire'));

  return (
    <>
      <div style={{position: 'relative'}}>
        <div style={{position: 'absolute', zIndex: -1}}>
          <CoolTitle style={{margin: 10}}>
            <Typography variant="h5" gutterBottom className={classes.title}>
              {text[language.value].title_edit_driver}
            </Typography>
          </CoolTitle>
        </div>
      </div>

      <div className={classes.avatarContainer}>
        <DriverAvatarItem/>
      </div>

      <Grid className={classes.container} container spacing={2}  justify={'flex-start'} style={{marginBottom: 20}}>
        {/*'owner',*/}
        {/*'username',*/}
        {/*'image_url_hd',*/}
        {/*'image_url',*/}
        <TextItem path={'driver.full_name'}/>
        <TextItem path={'driver.description'}/>
        {/*'current_address_location',*/}
        {/*'current_geospatial_location',*/}
        {/*'current_location_last_update',*/}
        {/*'operative_locations',*/}
        {/*'created',*/}
        {/*'last_update',*/}
        {/*'likes',*/}
        {/*'views',*/}
        {/*'followers',*/}
        {/*'following',*/}
        {/*'active_orders',*/}
        {/*'active_services',*/}
        {/*'finished_orders',*/}
        {/*'finished_services',*/}
        {/*'canceled_orders',*/}
        {/*'canceled_services',*/}
        {/*'failed_orders',*/}
        {/*'failed_services',*/}
        {/*'rank',*/}
        {/*'votes',*/}
        {/*'stars',*/}
        <TextItem path={'driver.email'} type={'email'}/>
        <TextItem path={'driver.home_country'}/>
        <TextItem path={'driver.home_region'}/>
        <TextItem path={'driver.home_city'}/>
        <TextItem path={'driver.personal_id'}/>
        {/*'personal_id_frontside_link',*/}
        {/*'personal_id_backside_link',*/}
        {/*'personal_id_attachment_link',*/}
        <TextItem path={'driver.birthdate'} type="date"/>
        {/*<TextItem path={'driver.operative_radius_from_current_location'}/>*/}
        {/*'licenses',*/}
        {/*'permits',*/}
        {/*'skills',*/}
        {/*'arrival_ability',*/}
        {/*'work_history',*/}
        <TextItem path={'driver.workplace_radius'} type={'number'}/>
        {/*'prefered_services',*/}
        {/*'unacceptable_services',*/}
        {/*'experienced_with_vehicles',*/}
        {/*'cv_link',*/}
        {/*'traffic_violation_record',*/}

        <TextItem path={'driver.starting_price'} type={'number'}/>

        <TextItem path={'driver.points'} type={'number'}/>
        <TextItem path={'driver.score'} type={'number'}/>
        <TextItem path={'driver.phone_number'}/>
        <TextItem path={'driver.phone_number2'}/>
        <TextItem path={'driver.company'}/>
        <TextItem path={'driver.job'}/>

        <TextItem path={'driver.orders_limit'} type={'number'}/>
        <TextItem path={'driver.services_limit'} type={'number'}/>

        {/*'last_orders',*/}
        {/*'max_orders',*/}
        {/*'orders_cooldown',*/}
        {/*'last_services',*/}
        {/*'max_services',*/}
        {/*'services_cooldown',*/}
        {/*'services_limit',*/}
        <CheckboxItem color={colors.blue} path={'driver.news_letter'}/>
        <CheckboxItem color={colors.blue} path={'driver.validated'}/>
        <CheckboxItem color={colors.blue} path={'driver.blocked'}/>
        <CheckboxItem color={colors.blue} path={'driver.removed'}/>

      </Grid>

      <Grid container spacing={2} justify={'flex-start'} style={{marginBottom: 20}}>
        <UpdateDriverAction/>
        <EditUserAction/>
      </Grid>

      <Grid className={classes.container} container spacing={2}  justify={'flex-start'} style={{marginBottom: 20, padding: 10}}>
        <h3 style={{textAlign: 'center', width: '100%'}}>Requirements</h3>
        {requirements && requirements.map(i=>
          <div key={JSON.stringify(i)} style={{width: '100%', margin: 10, fontSize: 20}}>{JSON.stringify(i)}</div>
        )}
      </Grid>

      <Grid className={classes.container} container spacing={2}  justify={'flex-start'} style={{marginBottom: 20, padding: 10}}>
        <h3 style={{textAlign: 'center', width: '100%'}}>Questionnaire</h3>
        {questionnaire && Object.keys(questionnaire).map(key=>
          <div key={key} style={{width: '100%', margin: 10, fontSize: 20}}>{key}: {JSON.stringify(questionnaire[key])}</div>
        )}
      </Grid>

      <CoolTitle style={{margin: 20, marginBottom: 50}}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {text[language.value].title_edit_driver_licenses}
        </Typography>
      </CoolTitle>

      <Grid className={classes.container} container spacing={2}  justify={'flex-start'} style={{marginBottom: 20}}>
        <EditDriverLicenses/>
      </Grid>

    </>
  )
};