import React from 'react';
import ReactDOM from 'react-dom';
import 'date-fns';
import './assets/css/index.css';
import App from './App.js';
import * as serviceWorker from './serviceWorker';
import ids from './ids';
// import { ThemeProvider } from '@material-ui/core/styles';
// import theme from './assets/jss/theme';
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
// import CssBaseline from '@material-ui/core/CssBaseline';

ReactDOM.render(
  // <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
  {/*// // CssBaseline kickstart an elegant, consistent, and simple baseline to build upon.*/}
  {/*// //  <CssBaseline />*/}
      <App />
    </MuiPickersUtilsProvider>
  // </ThemeProvider>
  ,
  document.getElementById(ids.root)
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
