import React, {useContext} from "react";
import context from "../../../context";
import {useStyles} from "../../../assets/jss/useEditUserStyles";
import {CoolTitle} from "../../CoolTitle";
import Typography from "@material-ui/core/Typography";
import {text} from "../../../variables/text/UserEdit";
import Grid from "@material-ui/core/Grid";
import {ReadOnlyItem, TextItem, PasswordItem, SelectItem, CheckboxItem, UserAvatarItem, MultiChipSelectItem} from "./Items";
import {UpdateUserAction} from "./Actions";
import {colors} from '../../custom/CustomCheckbox/Checkbox'
import {regions, service_types, access_levels_text} from '../../../variables/text/lists'
import type {Language} from "../../../types";


export const EditUser = () => {
  const language: Language = useContext(context.language).language;
  const classes = useStyles();
  return (
    <>
      <div style={{position: 'relative'}}>
        <div style={{position: 'absolute', zIndex: -1}}>
          <CoolTitle style={{margin: 10}}>
            <Typography variant="h5" gutterBottom className={classes.title}>
              {text[language.value].title}
            </Typography>
          </CoolTitle>
        </div>
      </div>

      <div className={classes.avatarContainer}>
        <UserAvatarItem/>
      </div>

      <Grid className={classes.container} container spacing={2}  justify={'flex-start'} style={{marginBottom: 20}}>
        <TextItem path={'data.username'} mandatory={true}/>
        <TextItem path={'data.description'}/>

        <MultiChipSelectItem path={'data.service_types'} options={service_types[language.value].values} />
        <MultiChipSelectItem path={'data.order_types'} options={service_types[language.value].values} />

        <TextItem path={'data.email'} type={'email'}/>
        <PasswordItem path={'data.password'}/>
        <TextItem path={'data.country'}/>
        <SelectItem path={'data.region'} options={regions[language.value].values}/>
        <TextItem path={'data.city'}/>
        <TextItem path={'data.phone_number'}/>
        <TextItem path={'data.personal_id'}/>
        <TextItem path={'data.company'}/>
        <TextItem path={'data.job'}/>
        <TextItem path={'data.credits'} type={'number'}/>
        <TextItem path={'data.coins'} type={'number'}/>

        <ReadOnlyItem path={'data.package'}/>

        <CheckboxItem color={colors.blue} path={'data.news_letter'}/>

        <SelectItem path={'data.access_level'} options={access_levels_text[language.value].values}/>

        <CheckboxItem color={colors.blue} path={'data.validated'}/>
        <CheckboxItem color={colors.blue} path={'data.blocked'}/>

        <ReadOnlyItem path={'data.access_key'}/>

      </Grid>

      <Grid container spacing={2} justify={'flex-start'} style={{marginBottom: 20}}>
        <UpdateUserAction/>
      </Grid>
    </>
  )
};