import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    padding: 10,
  },

  count: {
    margin: 15,
    fontSize: 18,
    marginBottom: 15,
  },

  titles: {
    width: 200,
    overflowWrap: 'break-word',
  },

  items: {

  },

  container: {
    border: '1px solid #b8bde2',
    backgroundColor: '#fff',
    maxWidth: '100%',
    margin: 0,
  },

  item: {
    width: 300,
    padding: 10,
    // border: '1px solid #b8bde2',
    // backgroundColor: '#fff',
    minHeight: 64,
  },

  spacer: {
    width: 20,
  },

  flexer: {
    display: 'flex',
  },

  container2: {
    marginTop: 5,
    marginBottom: 5,
  },

  search: {
    marginBottom: 30,
    margintop: 15,
  },

  title: {
    padding: 10,
  },

  avatarLabel: {
    textAlign: 'center',
    paddingTop: 20,
  },

  avatarContainer: {
    background: 'linear-gradient(45deg, #374494ad 30%, #53ffc7b8 90%)',
    border: '1px solid #b8bde2',
    borderBottom: 'none',
    padding: 25,
    paddingTop: 40,
    marginBottom: 0,
  },

});