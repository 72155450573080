import request from 'axios'
import {auth_url} from '../utils/urls'
import {auth_config} from '../utils/configs'
import {auth_call, silent_auth_call} from '../utils/auth_calls'
import {handle_post_removed_success} from '../utils/success'

export const assert_access_level = silent_auth_call(async () => {
    await request.get(auth_url+'/assert_access_level', auth_config);
    return true;
});

export const admin_users_count = auth_call(async () => {
    const res = await request.get(auth_url+'/admin/users/count', auth_config);
    return res.data.users
});

export const admin_get_users = auth_call(async ({skip, limit, filter, search=''}) => {
    const res = await request.post(auth_url+'/admin/users', {
        skip,
        limit,
        filter,
        search,
    }, auth_config);

    return {
        users: res.data.users,
        next: res.data.next,
    };
});

export const admin_posts_count = auth_call(async () => {
    const res = await request.get(auth_url+'/admin/posts/count', auth_config);
    return res.data.posts
});

export const admin_get_posts = auth_call(async ({skip, limit, filter, search=''}) => {
    const res = await request.post(auth_url+'/admin/posts', {
        skip,
        limit,
        filter,
        search,
    }, auth_config);

    return {
        posts: res.data.posts,
        next: res.data.next,
    };
});

export const admin_remove_post = auth_call(async ({post_id, message}) => {
    await request.post(auth_url+'/admin/posts/remove', {
        post_id,
        message,
    }, auth_config);
    return handle_post_removed_success();
});

export const admin_get_all_user_points = auth_call(async ({username}) => {
    const r = await request.get(auth_url+'/admin/users/get_all_points/'+username, auth_config);
    return r.data.points;
});