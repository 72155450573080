import createStore, {UseStore} from 'zustand'
import {CreateFetcher} from "../utils/storeFetchFunctions";
import {projection} from "../variables/projections";
import produce from "immer";
import {useCallback, useContext, useEffect, useMemo} from "react";
import {useCreateUser} from "../api/auth/users";
import {useCreateDriver, useGetDrivers, useAdminEditDriver, useAdminEditDriverPhoto} from "../api/auth/drivers";
import {useCreateVehicle, useGetVehicles} from "../api/auth/vehicles";
import context from "../context";
import Swal from "sweetalert2";
import {text} from "../variables/text/UserEdit";
import {useRouteMatch} from 'react-router-dom'
import {register} from "./stores";


// improve performance by fetching state
// from dynamically created functions
// functions are created on store creation time
export const fetchStore = {
  // [state[key]]: state => state[state[key]],
};

export const default_data = () => ({
  username: '',
  service_types: [],
  order_types: [],
  email: '',
  password: '',
  country: 'Israel',
  region: '',
  city: '',
  full_name: '',
  phone_number: '',
  company: '',
  job: '',
  personal_id: '',
  news_letter: true,
  validated: true,
  blocked: false,
  access_key: null,
});

export const useNewUserStore: UseStore = createStore(
  (set, get) => CreateFetcher(fetchStore, {
    // data
    data: default_data(),
    loading: false,
    loaded: false,
    updating: false,

    // getting
    get,

    // setting
    set: fn => set(produce(fn)),
    set_field: (field:string, data:any) => get().set(state=>{state.data[field]=data}),
    useTextField: (field) => useCallback((event) => get().set_field(field, event.target.value), [field]),

    /////////////
    // actions //
    /////////////

    useSetUser: () => {
      const container = useMemo(()=>({}), []); // useCallback once
      container.call = useCreateUser();
      container.language = useContext(context.language).language;
      container.links = useContext(context.links);

      return useCallback(()=>{(async () => {
        const state = get();
        const data = state.data;
        const error = text[container.language.value].msg.error;

        const errorMsg = (text) => {
          Swal.fire({
            type: 'error',
            title: error.title,
            text,
            confirmButtonText: error.confirmButtonText,
          })
        };

        if (!data.username) return errorMsg(error.username_required);
        if (!data.email) return errorMsg(error.email_required);
        if (!data.password) return errorMsg(error.password_required);
        if (!data.country) return errorMsg(error.country_required);
        if (!data.full_name) return errorMsg(error.full_name_required);
        if (!data.phone_number) return errorMsg(error.phone_number_required);

        state.set(state => {state.updating = true});
        const user = await container.call({data: state.data});
        if (user) {
          container.links.go_to_edit_user(user._id);
          state.set(state => {state.data = default_data()});
          Swal.fire({
            type: 'success',
            title: text[container.language.value].update_success.title,
            text: text[container.language.value].update_success.text,
            confirmButtonText: text[container.language.value].update_success.confirmButtonText,
            timer: 6000,
          });
        }
        state.set(state => {state.updating = false});
      })()}, []);
    },

  })
);

register({fetchStore, useNewUserStore});