import React, {useState, useContext, useEffect} from "react";
import {Formik, Form, Field} from 'formik';
import Grid from '@material-ui/core/Grid';
import GradientButton from '../../custom/CustomButtons/GradientButton'
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Tooltip from '../../Tooltip';
import {LinearProgress} from '@material-ui/core';
import {account_setup} from '../../../api/auth/account'
import sleep from "../../../utils/sleep";
import context from '../../../context'
import Swal from 'sweetalert2';
import {Input} from '../../custom/CustomInput/FormikInput'
import {verifyPassword} from '../../../validations/formValidation'
import LockIcon from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import {has_password, change_password} from '../../../api/auth/account'
import email_icon from "../../../assets/images/icons/email.svg";
import email_confirm_icon from "../../../assets/images/icons/email_confirm.svg";

interface Values {
  email: string;
  old_password: string;
  new_password: string;
  confirm_new_password: string;
}

export default ({set_loading}) => {
  const {entered_page} = useContext(context.entered_page);
  const {set_user, user} = useContext(context.user);
  const is_logged: boolean = useContext(context.is_logged);
  const [old_password_exist, set_old_password_exist] = useState(null);
  const [show_old_pass, set_show_old_pass] = useState(false);
  const [show_new_pass, set_show_new_pass] = useState(false);
  const [show_con_pass, set_show_con_pass] = useState(false);

  useEffect(()=>{(async()=>{
    if (is_logged) {
      const res = await has_password({set_user});
      if (res) {
        set_old_password_exist(res.has_password);
      }
    }
  })()}, [is_logged]);

  const validate = values => {
    const errors: Partial<Values> = {};

    if (!values.email)
      errors.email = 'Required';
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Invalid email address';

    if (old_password_exist) {
      if (!values.old_password)
        errors.old_password = 'Required';
      else if (!verifyPassword(values.old_password))
        errors.old_password = 'Invalid password characters';
    }

    if (!values.new_password)
      errors.new_password = 'Required';
    else if (!verifyPassword(values.new_password))
      errors.new_password = 'Invalid password characters';

    if (!values.confirm_new_password)
      errors.confirm_new_password = 'Required';
    else if (values.confirm_new_password!==values.new_password)
      errors.confirm_new_password = 'Invalid new password confirmation';

    return errors;
  };

  const onSubmit = async (values, {setSubmitting}) => {
    set_loading(true);
    const res = await change_password({
      set_user,
      email: values.email,
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_new_password: values.confirm_new_password,
    });
    set_loading(false);
    if (res) {
      set_user({...user, ...res});
      await sleep(1000);
      Swal.fire({
        type: 'success',
        title: 'Password Updated',
        text: 'Your security is important! Happy GOtracking!',
        confirmButtonText: 'OK',
      });
      set_old_password_exist(true);
      await sleep(800);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          old_password: '',
          new_password: '',
          confirm_new_password: '',
        }}
        validate={validate}
        onSubmit={onSubmit}
        render={({submitForm, isSubmitting}) => {
          return (
            <Form>
              <Slide direction="right" timeout={1000} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={'verify your email address'}>
                      <img src={email_confirm_icon} alt="" style={{width: 20, height: 20, margin: 2}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="email address"
                      name="email"
                      type="email"
                    />
                  </Grid>
                </Grid>
              </Slide>

              {old_password_exist &&
              <Slide direction="right" timeout={1000} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={'enter your old password'}>
                      <LockIcon style={{color: 'rgb(203,186,187)'}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="old password"
                      name="old_password"
                      type={show_old_pass?"text":"password"}
                      InputProps={{
                        disabled: !old_password_exist,
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => set_show_old_pass(!show_old_pass)}
                              onMouseDown={event => event.preventDefault()}
                            >
                              {show_old_pass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ,
                      }}
                    />
                  </Grid>
                </Grid>
              </Slide>
              }

              <Slide direction="right" timeout={1400} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={'select a new password'}>
                      <LockIcon style={{color: 'rgb(231,88,79)'}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="new password"
                      name="new_password"
                      type={show_new_pass?"text":"password"}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => set_show_new_pass(!show_new_pass)}
                              onMouseDown={event => event.preventDefault()}
                            >
                              {show_new_pass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ,
                      }}
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Slide direction="right" timeout={1400} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 45}}>
                  <Grid item>
                    <Tooltip title={'re-type and confirm your new password'}>
                      <LockIcon style={{color: 'rgb(143,231,101)'}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="confirm new password"
                      name="confirm_new_password"
                      type={show_con_pass?"text":"password"}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => set_show_con_pass(!show_con_pass)}
                              onMouseDown={event => event.preventDefault()}
                            >
                              {show_con_pass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ,
                      }}
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Grid container justify='center'>
                <Fade in={entered_page} timeout={1800}>
                  <GradientButton
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Change Password
                  </GradientButton>
                </Fade>
              </Grid>
              {isSubmitting && <LinearProgress style={{marginTop:20}}/>}
            </Form>
          )
        }}
      />
    </div>
  )
};