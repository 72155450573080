import React, {useCallback, useContext, useState} from "react";
import {useAssignmentsStyles} from "./styles";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import storage_ids from "../../storage_ids";
import {getStore} from "../../hooks/useStore";
import {do_nothing_function} from "../../utils/do_nothing_function";
import {entity_types} from "./variables";
import uuid from "../../utils/uuid";
import Fade from "@material-ui/core/Fade/Fade";
import Grid from "@material-ui/core/Grid";
import {SelectStartAndEndTime} from "../custom/CustomDateTime/SelectStartAndEndTime";
import GoogleMapsInput from "../custom/CustomInput/GoogleMapsInput";
import {DistributionPoints} from "./DistributionPoints";
import {Service} from "./Service";
import {AddService} from "./AddService";
import {MissionSteps} from "./MissionSteps";
import {Notes} from "./Notes";
import {CreateAssigmentNameDialog} from './CreateAssigmentNameDialog'
import sleep from '../../utils/sleep'
import Swal from 'sweetalert2'
import context from '../../context'
import {CoolTitle} from "../CoolTitle";
import Typography from "@material-ui/core/Typography";
import {AssignmentActions, CreateAssignmentAction, CreateOrderAction, FindAmatchAction, ResetAction} from './AssignmentActions'
import {TimeAndPlace} from './TimeAndPlace'
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useTheme} from "@material-ui/core";
import Measure from 'react-measure'
import {useMyOrdersStore} from '../../stores/useOrdersStores'


export const NewAssignment = ({...props}) => {
  const width: number = useContext(context.size).width;
  const mobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  const [size, set_size] = useState({width:0, height: 0});
  const classes = useAssignmentsStyles({...useContext(context.rtl), mobile, width});

  const {user} = useContext(context.user);
  const {signin, set_signin} = useContext(context.signin);

  const assignments = getStore(storage_ids.assignments);
  const createOrder = useMyOrdersStore(state => state.createOrder);

  const [services, set_services] = useLocalStorage(storage_ids.new_assignment_services,[]);

  const notes = getStore(storage_ids.new_assignment_notes);
  const steps = getStore(storage_ids.new_assignment_steps);
  const distribution_points = getStore(storage_ids.new_assignment_distribution_points);

  const [start_time, set_start_time] = useLocalStorage(storage_ids.new_assignment_start_time, null);
  const [end_time, set_end_time] = useLocalStorage(storage_ids.new_assignment_end_time, null);
  const [from_location, set_from_location] = useLocalStorage(storage_ids.new_assignment_from_location, {description:''});
  const [to_location, set_to_location] = useLocalStorage(storage_ids.new_assignment_to_location, {description:''});
  const [new_assignment_name_dialog, set_new_assignment_name_dialog] = useState(false);


  const onReset = () => {
    set_services([]);
    steps.set([]);
    notes.set('');
    distribution_points.set([]);
    set_start_time(null);
    set_end_time(null);
  };

  const onCreateAssignment = useCallback(() => {set_new_assignment_name_dialog(true)}, [set_new_assignment_name_dialog]);
  const onCreateAssignmentNameCloseDialog = useCallback(() => {set_new_assignment_name_dialog(false)}, [set_new_assignment_name_dialog]);

  const onSaveNewAssignment = useCallback(async (name:string) => {
    if (!user) {
      set_signin({...signin, open: true});
      return false;
    }

    if (!name) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: `you must enter a name first`,
        confirmButtonText: 'OK',
      });
      return false;
    }

    if (name.length > 255) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: `name is too long`,
        confirmButtonText: 'OK',
      });
      return false;
    }

    if (assignments.data.find(assignment=>assignment.name===name)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: `assignment with the name ${name} already exist`,
        confirmButtonText: 'OK',
      });
      return false;
    }

    // TODO: create a limit for the number of assignments
    // if (assignments.data.length >= user.max_assignments) {
    //   Swal.fire({
    //     type: 'error',
    //     title: 'Oops...',
    //     text: `you can\'t save more than ${user.max_assignments} assignments`,
    //     confirmButtonText: 'OK',
    //   });
    //   return false;
    // }

    // api here
    await sleep(2000);
    const res = {_id: `${uuid()}`};

    if (res === false)
      return false;

    assignments.set([...assignments.data, {
      name,
      services,
      notes: notes.data,
      steps: steps.data,
      distribution_points: distribution_points.data,
      start_time,
      end_time,
      from_location,
      to_location,
      _id: res._id,
    }]);

    Swal.fire({
      type: 'success',
      title: 'מצוין!',
      text: `משימתך נשמרה בהצלחה, אתה יכול עכשיו ליצור הזמנות ולהתחיל לעבוד.`,
      confirmButtonText: 'OK',
      timer: 5000,
    });

    return true;
  });

  const onCreateOrder = async () => {
    if (!user) {
      set_signin({...signin, open: true});
      return false;
    }

    // validations here

    // api here
    await sleep(2000);
    const res = {_id: `${uuid()}`};

    createOrder({
      name: '', // name=''
      services,
      notes: notes.data,
      steps: steps.data,
      distribution_points: distribution_points.data,
      start_time,
      end_time,
      from_location,
      to_location,
      _id: res._id,
    });

    Swal.fire({
      type: 'success',
      title: 'מצוין!',
      text: `הזמנתך נשמרה בהצלחה, אתה יכול עכשיו לראות את סטטוס ההזמנה בדף הסטטוס של ההזמנות.`,
      confirmButtonText: 'OK',
    });

    return true;
  };

  const onAddService = (index) => {
    set_services([...services, {
      type: entity_types[index],
      index,
      id: uuid(),
      requirements: [],
    }])
  };

  const onDeleteService = index => () => {
    const array = [...services];
    array.splice(index, 1);
    set_services(array)
  };

  const onAddRequirement = service_index => value => {
    if (value) {
      const array = [...services];
      array[service_index].requirements.push(value);
      set_services(array)
    }
  };

  const onDeleteRequirement = service_index => req_index => {
    const array = [...services];
    array[service_index].requirements.splice(req_index, 1);
    set_services(array)
  };

  const onRequirementChange = service_index => (req_index, value) => {
    const array = [...services];
    array[service_index].requirements.splice(req_index, 1, value);
    set_services(array)
  };

  return (
    <>
      <CoolTitle className={classes.assignment_title}>
        <Typography variant="h5" gutterBottom className={classes.title_text2}>
          משימה חדשה
        </Typography>
      </CoolTitle>
      <Grid container justify={'flex-start'}>
        <Fade in={true} timeout={300}>
          <div style={{width:'100%'}}>
            <Measure bounds onResize={({bounds})=>set_size(bounds)}>{({measureRef})=>(
              <div className={classes.assignment_container} ref={measureRef} {...props}>
                <TimeAndPlace
                  size={size}
                  start_time={start_time} set_start_time={set_start_time}
                  end_time={end_time} set_end_time={set_end_time}
                  from_location={from_location} set_from_location={set_from_location}
                  to_location={to_location} set_to_location={set_to_location}
                />

                <DistributionPoints/>

                <Typography variant="h5" gutterBottom style={{textAlign:'start'}}>
                 הוסף שירות
                </Typography>
                <Grid container justify={'flex-start'} className={classes.entities} spacing={mobile?1:3}>
                  <AddService onAddEntity={onAddService}/>
                  <div style={{flexGrow: 1}}/>
                  <FindAmatchAction onFindAmatch={onCreateOrder} style={{height: 40, margin: 0, marginTop: mobile?12:20}}/>
                  <div style={{width: mobile?4:12}}/>
                </Grid>

                {services.map((service, index)=>
                  <Service
                    index={index}
                    service={service}
                    key={index}
                    onDeleteService={onDeleteService(index)}
                    onAddRequirement={onAddRequirement(index)}
                    onDeleteRequirement={onDeleteRequirement(index)}
                    onRequirementChange={onRequirementChange(index)}
                  />
                )}

                <MissionSteps/>
                <Notes/>

                <AssignmentActions>
                  <ResetAction onReset={onReset}/>
                  <CreateAssignmentAction onCreateAssignment={onCreateAssignment}/>
                  <CreateOrderAction onCreateOrder={onCreateOrder}/>
                  <FindAmatchAction onFindAmatch={onCreateOrder}/>
                </AssignmentActions>
              </div>
             )}</Measure>
            </div>
        </Fade>
      </Grid>
      <CreateAssigmentNameDialog
        open={new_assignment_name_dialog}
        onClose={onCreateAssignmentNameCloseDialog}
        onSave={onSaveNewAssignment}
      />
    </>
  )
};