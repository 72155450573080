import {useState, useEffect} from 'react'
import {set_local_storage, get_local_storage} from '../utils/handle_local_storage'


// uuid is unique, but needs to be consistent with each refresh
export const useLocalStorage = (uuid: string, initialState: any) => {
  try {
    initialState = get_local_storage(uuid)
  } catch (e) {}

  const [state, setState] = useState(initialState);

  useEffect(() => {
    set_local_storage(uuid, state)
  }, [state]);

  return [state, setState]
};
