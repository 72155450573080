import React, {useContext} from "react";
import context from "../../../context";
import {fetchStore, useEditUserStore} from "../../../stores/useEditUserStore";
import {useStyles} from "../../../assets/jss/useEditUserStyles";
import {CoolTitle} from "../../CoolTitle";
import Typography from "@material-ui/core/Typography";
import {text} from "../../../variables/text/UserEdit";
import Grid from "@material-ui/core/Grid";
import {ReadOnlyItem, TextItem} from "./Items";
import {AddVehicleAction} from "./Actions";
import type {Language} from "../../../types";

export const AddVehicle = () => {
  const language: Language = useContext(context.language).language;
  useEditUserStore(fetchStore.useGetVehicles)();
  const classes = useStyles();
  return (
    <>
      <CoolTitle style={{marginBottom: 40}}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {text[language.value].title_vehicle}
        </Typography>
      </CoolTitle>

      <Grid className={classes.container} container spacing={2}  justify={'flex-start'} style={{marginBottom: 20}}>
        <TextItem field={'username'}/>
        <TextItem field={'description'}/>

        <ReadOnlyItem field={'service_types'}/>
        <ReadOnlyItem field={'order_types'}/>

        <TextItem field={'email'} type={'email'}/>
        <TextItem field={'password'} type={'password'}/>
        <TextItem field={'country'}/>
        <TextItem field={'region'}/>
        <TextItem field={'city'}/>
        <TextItem field={'phone_number'}/>
        <TextItem field={'company'}/>
        <TextItem field={'job'}/>
        <TextItem field={'credits'} type={'number'}/>
        <TextItem field={'coins'} type={'number'}/>

        <ReadOnlyItem field={'package'}/>
        <ReadOnlyItem field={'news_letter'}/>
        <ReadOnlyItem field={'access_level'}/>
        <ReadOnlyItem field={'validated'}/>
        <ReadOnlyItem field={'blocked'}/>
        <ReadOnlyItem field={'access_key'}/>

      </Grid>

      <Grid className={classes.container} container spacing={2}  justify={'flex-start'} style={{marginBottom: 20}}>
        <AddVehicleAction/>
      </Grid>
    </>
  )
};