import React, {useState, useMemo, useEffect} from 'react'
import context from '../../context'
import { remove_auth_from_local_storage } from '../../api/utils/auth_handler'
import {
  default_snack,
  default_scroll_options,
  default_category_tab,
  default_search_dropdown,
} from '../../utils/create_default'
import useLinks from '../../hooks/useLinks'
import useLoadGoogleMaps from '../../hooks/useLoadGoogleMaps'
import {default_theme} from "../../assets/jss/theme";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from '@material-ui/core/styles';
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {RTL} from '../RTL'
import {useLocalStorage} from '../../hooks/useLocalStorage'
import storage_ids from '../../storage_ids'
// import ignoreAction from '../../utils/ignoreAction'
// import {search_categories} from "../../variables";
import {languages} from '../../variables/variables'

export default ({children}) => {
  const theme = useTheme();
  const mobile: boolean = useMediaQuery(theme.breakpoints.down('sm'));

  const [custom_theme, set_custom_theme] = useState(default_theme);
  const [language, set_language] = useLocalStorage(storage_ids.language, languages.heb);
  const [rtl, set_rtl] = useLocalStorage(storage_ids.rtl, false);
  const [user, set_user] = useLocalStorage(storage_ids.user, null);
  const [page_loading, set_page_loading] = useState(true);
  const [size, set_size] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [sidebar, set_sidebar] = useState(false);
  const [notifications, set_notifications] = useLocalStorage(storage_ids.notifications, []);
  const [messages, set_messages] = useLocalStorage(storage_ids.messages, []);
  const [signin, set_signin] = useLocalStorage(storage_ids.signin, {open: false});
  const [backdrop, set_backdrop] = useState(false);
  const [snackbar, set_snackbar] = useState(default_snack());
  const [scrollTo, set_scrollTo] = useState(default_scroll_options());
  const [share_buttons, set_share_buttons] = useState(false);
  const [category_tab, set_category_tab] = useState(default_category_tab());
  const [search_dropdown, set_search_dropdown] = useState(default_search_dropdown());
  const [spring_toast, set_spring_toast] = useState('');
  const [is_logged, set_is_logged] = useState(null);
  const [did_login, set_did_login] = useState(false);
  const [logged_out, set_logged_out] = useState(false);
  const [profile_complete, set_profile_complete] = useState(null);
  const [entered_page, set_entered_page] = useState(null);
  // const [search, set_search] = useState(default_search());
  const links = useLinks(set_entered_page, set_page_loading);
  const loaded_google_maps = useLoadGoogleMaps();

  const user_value = useMemo(() => ({ user, set_is_logged, set_user: data=>{
    set_user(data);
    if (data===null) {
      remove_auth_from_local_storage();
      set_notifications([]);
      set_messages([]);
      set_is_logged(false);
      set_profile_complete(null)
    }
    else {
      set_is_logged(true);
      if (!data.username) {
        set_profile_complete(false)
      } else {
        set_profile_complete(true)
      }
    }
  }}), [user, set_user, set_is_logged]);

  const rtl_value = useMemo(() => ({ rtl, set_rtl }), [rtl, set_rtl]);
  const custom_theme_value = useMemo(() => ({ custom_theme, set_custom_theme }), [custom_theme, set_custom_theme]);
  const language_value = useMemo(() => ({ language, set_language }), [language, set_language]);
  const snackbar_value = useMemo(() => ({ snackbar, set_snackbar }), [snackbar, set_snackbar]);
  const notifications_value = useMemo(() => ({ notifications, set_notifications }), [notifications, set_notifications]);
  const messages_value = useMemo(() => ({ messages, set_messages }), [messages, set_messages]);
  const size_value = useMemo(() => ({ size, set_size }), [size, set_size]);
  const sidebar_value = useMemo(() => ({ sidebar, set_sidebar }), [sidebar, set_sidebar]);
  const signin_value = useMemo(() => ({ signin, set_signin }), [signin, set_signin]);
  const page_loading_value = useMemo(() => ({ page_loading, set_page_loading }), [page_loading, set_page_loading]);
  const backdrop_value = useMemo(() => ({ backdrop, set_backdrop }), [backdrop, set_backdrop]);
  const scrollTo_value = useMemo(() => ({ scrollTo, set_scrollTo }), [scrollTo, set_scrollTo]);
  const share_buttons_value = useMemo(() => ({ share_buttons, set_share_buttons }), [share_buttons, set_share_buttons]);
  const category_tab_value = useMemo(() => ({ category_tab, set_category_tab }), [category_tab, set_category_tab]);
  const search_dropdown_value = useMemo(() => ({ search_dropdown, set_search_dropdown }), [search_dropdown, set_search_dropdown]);
  const spring_toast_value = useMemo(() => ({ spring_toast, set_spring_toast }), [spring_toast, set_spring_toast]);
  const is_logged_value = useMemo(() => (is_logged), [is_logged]);
  const did_login_value = useMemo(() => (did_login), [did_login]);
  const logged_out_value = useMemo(() => (logged_out), [logged_out]);
  const profile_complete_value = useMemo(() => ({ profile_complete, set_profile_complete }), [profile_complete, set_profile_complete]);
  const entered_page_value = useMemo(() => ({ entered_page, set_entered_page }), [entered_page, set_entered_page]);
  const links_value = useMemo(() => links, [links]);
  const loaded_google_maps_value = useMemo(() => loaded_google_maps, [loaded_google_maps]);

  // const search_value = useMemo(() => ({
  //   ...search,
  //   set_search,
  //     handleOpenDrawer: (event) => {if (ignoreAction(event)) return; set_search({...search, openDrawer: true})},
  //     handleCloseDrawer: (event) => {if (ignoreAction(event)) return; set_search({...search, openDrawer: false})},
  //     handleCategoryChange: (index:number) => set_search({...search, search_category: search_categories[index]}),
  //     handleOpenSearchResults: () => set_search({...search, open_search_results: true}),
  //     handleCloseSearchResults: () => set_search({...search, open_search_results: false}),
  //     loading_search_results: search.open_search_results && search.search_results_options.length === 0,
  //     handleSearchInputChange: (event, new_value:string) => set_search({...search, search_inputValue: new_value}),
  //     handleSearchValueChange: (event, new_value:string) => set_search({...search, search_value: new_value}),
  //     handleSearchResultsOptions: (options:Array<Object>) => set_search({...search, search_results_options: options}),
  // }), [search, set_search]);

  useEffect(()=>{
    if (is_logged) {
      set_did_login(true);
    }
  },[is_logged]);

  useEffect(()=>{
    set_logged_out(did_login && !is_logged);
  },[is_logged, did_login]);

  useEffect(()=>{
    set_sidebar(!mobile)
  }, [mobile]);

  useEffect(()=>{
    set_rtl(Boolean(language.rtl))
  }, [language]);

  return (
   <context.user.Provider value={user_value}>
   <context.snackbar.Provider value={snackbar_value}>
   <context.notifications.Provider value={notifications_value}>
   <context.messages.Provider value={messages_value}>
   <context.size.Provider value={size_value}>
   <context.sidebar.Provider value={sidebar_value}>
   <context.signin.Provider value={signin_value}>
   <context.page_loading.Provider value={page_loading_value}>
   <context.backdrop.Provider value={backdrop_value}>
   <context.scrollTo.Provider value={scrollTo_value}>
   <context.share_buttons.Provider value={share_buttons_value}>
   <context.search_dropdown.Provider value={search_dropdown_value}>
   <context.category_tab.Provider value={category_tab_value}>
   <context.spring_toast.Provider value={spring_toast_value}>
   <context.is_logged.Provider value={is_logged_value}>
   <context.profile_complete.Provider value={profile_complete_value}>
   <context.entered_page.Provider value={entered_page_value}>
   <context.links.Provider value={links_value}>
   <context.loaded_google_maps.Provider value={loaded_google_maps_value}>
   <context.did_login.Provider value={did_login_value}>
   <context.logged_out.Provider value={logged_out_value}>
   <context.rtl.Provider value={rtl_value}>
   <context.custom_theme.Provider value={custom_theme_value}>
   <context.language.Provider value={language_value}>

     {/*<state.search.Provider value={search_value}>*/}
     <ThemeProvider theme={custom_theme}>
       <MuiPickersUtilsProvider utils={DateFnsUtils}>
         {rtl ? <RTL>{children}</RTL> : children}
       </MuiPickersUtilsProvider>
     </ThemeProvider>
     {/*</state.search.Provider>*/}

   </context.language.Provider>
   </context.custom_theme.Provider>
   </context.rtl.Provider>
   </context.logged_out.Provider>
   </context.did_login.Provider>
   </context.loaded_google_maps.Provider>
   </context.links.Provider>
   </context.entered_page.Provider>
   </context.profile_complete.Provider>
   </context.is_logged.Provider>
   </context.spring_toast.Provider>
   </context.category_tab.Provider>
   </context.search_dropdown.Provider>
   </context.share_buttons.Provider>
   </context.scrollTo.Provider>
   </context.backdrop.Provider>
   </context.page_loading.Provider>
   </context.signin.Provider>
   </context.sidebar.Provider>
   </context.size.Provider>
   </context.messages.Provider>
   </context.notifications.Provider>
   </context.snackbar.Provider>
   </context.user.Provider>
  )
}
