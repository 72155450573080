import React, {useContext} from 'react'
import Grid from '@material-ui/core/Grid'
import Map_dark from '../../assets/images/home_page/Map_dark.svg'
import Map_light from '../../assets/images/home_page/Map_light.svg'
import destinations from '../../assets/images/home_page/destinations.svg'
import {mobile_size, small_mobile_size} from '../../variables/variables'
import context from '../../context'
import {headerStyles} from '../../assets/jss/textStyle'
import WorldMap from '../../components/WorldMap'
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'
import Grow from '@material-ui/core/Grow'
import Zoom from '@material-ui/core/Zoom'
import VisibilityTrigger, {Hide} from '../../components/VisibilityTrigger'
import AppsIcon from '@material-ui/icons/Apps'


export default () => {
  const classes = headerStyles();
  const width: number = useContext(context.size).size.width;

  const collapse = 917;
  const sh1 = {fontSize:44};
  const section_style = {margin:'auto', width:'100%', maxWidth:1100, marginBottom: 120};

  if (width < collapse) {
    section_style.marginBottom = 50;
    if (width <= small_mobile_size) {
      sh1.fontSize = 28;
      sh1.marginTop = 0;
    } else if (width <= mobile_size) {
      sh1.fontSize = 32;
    } else {
      sh1.fontSize = 36;
    }
  }
  const sh2 = {fontSize:sh1.fontSize-4, width: '100%', textAlign: 'center'};

  return (
    <Hide delay={4000}>
      <Grid container justify="space-evenly" alignItems="center" style={section_style}>
        <VisibilityTrigger>{({triggered}) =>
          <Grid container justify="center" style={{marginBottom: 40}}>

            <Slide direction="right" in={triggered} mountOnEnter unmountOnExit timeout={1500}>
              <div>
                <Fade in={triggered} timeout={2500}>
                  <h1 className={classes.Header} style={sh2}>
                    Optimize Your Traffic and reduce cost
                  </h1>
                </Fade>
              </div>
            </Slide>

            <Fade in={triggered} timeout={1000}>
              <div style={{position: 'relative', width: '100%'}}>
                <WorldMap style={{
                  transform: 'translateX(-50%)',
                  zIndex: -1,
                  top: 0,
                  left: '50%',
                  position: 'absolute',
                  maxWidth: width - 20
                }}/>
              </div>
            </Fade>

            {width > 916 && <VisibilityTrigger on={triggered} delay={1000}>{({triggered}) =>
              <Zoom in={triggered} timeout={600}>
                <img src={Map_dark} alt="" style={{width: 300}}/>
              </Zoom>
            }</VisibilityTrigger>}

            <VisibilityTrigger on={triggered} delay={1400}>{({triggered}) =>
              <Zoom in={triggered} timeout={600}>
                <img src={Map_light} alt="" style={{width: 200}}/>
              </Zoom>
            }</VisibilityTrigger>

            {width > 576 && <VisibilityTrigger on={triggered} delay={1800}>{({triggered}) =>
              <Zoom in={triggered} timeout={600}>
                <img src={destinations} alt="" style={{width: 300, marginLeft: 40, marginTop: 20}}/>
              </Zoom>
            }</VisibilityTrigger>}
          </Grid>
        }</VisibilityTrigger>

        <VisibilityTrigger>{({triggered}) =>
          <Slide direction="right" in={triggered} mountOnEnter unmountOnExit timeout={800}>
            <p className={classes.Paragraph}
               style={{fontWeight: 600, textAlign: 'center', width: '100%', paddingRight: 30, color: '#000'}}>
              <AppsIcon style={{position: 'relative', top: 7, marginRight: 5, fontSize: 30}}/> OUR AMAZING
              SERVICES<br/>
            </p>
          </Slide>
        }</VisibilityTrigger>
        <div style={{maxWidth: 800, margin: 'auto', paddingLeft: 40}}>
          <VisibilityTrigger delay={500}>{({triggered}) =>
            <div style={{maxWidth: 270}}>
              <VisibilityTrigger on={triggered}>{({triggered}) =>
                <Grow in={triggered} timeout={500}>
                  <p className={classes.Paragraph}>
                    <PersonPinCircleIcon style={{position: 'relative', top: 3, marginRight: 5}}/> DRIVERS FOR CARS<br/>
                  </p>
                </Grow>
              }</VisibilityTrigger>
              <VisibilityTrigger on={triggered} delay={100}>{({triggered}) =>
                <Grow in={triggered} timeout={500}>
                  <p className={classes.Paragraph}>
                    <EmojiTransportationIcon style={{position: 'relative', top: 3, marginRight: 5}}/> FREELANCERS
                    & SUPPLIERS<br/>
                  </p>
                </Grow>
              }</VisibilityTrigger>
              <VisibilityTrigger on={triggered} delay={200}>{({triggered}) =>
                <Grow in={triggered} timeout={500}>
                  <p className={classes.Paragraph}>
                    <LocalShippingIcon style={{position: 'relative', top: 3, marginRight: 5}}/> TRANSPORT &
                    LOGISTICS<br/>
                  </p>
                </Grow>
              }</VisibilityTrigger>
              <VisibilityTrigger on={triggered} delay={300}>{({triggered}) =>
                <Grow in={triggered} timeout={500}>
                  <p className={classes.Paragraph}>
                    <DriveEtaIcon style={{position: 'relative', top: 3, marginRight: 5}}/> CARS FOR DRIVERS<br/>
                  </p>
                </Grow>
              }</VisibilityTrigger>
            </div>
          }</VisibilityTrigger>
        </div>
      </Grid>
    </Hide>
  )
}

