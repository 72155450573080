import Grid from "@material-ui/core/Grid";
import {SelectStartAndEndTime} from "../custom/CustomDateTime/SelectStartAndEndTime";
import GoogleMapsInput from "../custom/CustomInput/GoogleMapsInput";
import React, {useContext} from "react";
import {useAssignmentsStyles} from "./styles";
import context from "../../context";

export const TimeAndPlace = (
  {
    size,
    start_time, set_start_time,
    end_time, set_end_time,
    from_location, set_from_location,
    to_location, set_to_location,
    ...props
  }) => {
  const classes = useAssignmentsStyles({...useContext(context.rtl), width: size.width});
  return (
    <form className={classes.new_assignment_text_input_root} noValidate autoComplete="off" {...props}>
      <Grid container justify='space-between' className={classes.fields_container}>
        <SelectStartAndEndTime
          size={size}
          start_time={start_time} set_start_time={set_start_time}
          end_time={end_time} set_end_time={set_end_time}
        />

        <Grid container direction='column' className={classes.field_container}>
          <label htmlFor="assignment-location-start">מוצא</label>
          <GoogleMapsInput initialValue={from_location.description} onSelect={set_from_location} TextFieldProps={{id: "assignment-location-start", variant:"filled"}}/>
        </Grid>

        <Grid container direction='column' className={classes.field_container}>
          <label htmlFor="assignment-location-end">יעד</label>
          <GoogleMapsInput initialValue={to_location.description} onSelect={set_to_location} TextFieldProps={{id: "assignment-location-end", variant:"filled"}}/>
        </Grid>
      </Grid>
    </form>
  )
};