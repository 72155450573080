import React, {useCallback, useMemo, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useLoadDot} from '../../hooks/useLoadDot'
import Grid from "@material-ui/core/Grid";

export const title = 'שמירת משימה';


export const LoadingDialog = ({title, open, loading, actions}) => {
  const load_dots = useLoadDot(loading);
  return (
    <Dialog open={open}>
      <DialogTitle style={{textAlign:'center'}}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          רק רגע{load_dots}
        </DialogContentText>
        <DialogContentText>
          בקשתך בטיפול
        </DialogContentText>
      </DialogContent>
      {actions}
    </Dialog>
  );
};

export const AssigmentDialogActions = ({onClose, onSave, loading}) => {
  return (
    <DialogActions>
      <Button disabled={loading} onClick={onClose} color="primary">
        בטל
      </Button>
      <Button disabled={loading} onClick={onSave} color="primary">
        שמור
      </Button>
    </DialogActions>
  )
};

export const CreateAssigmentNameDialog = ({open, onClose, onSave}) => {
  const [loading, set_loading] = useState('');
  const [name, set_name] = useState('');

  const handleSave = useCallback(async () => {
    set_loading(true);
    const saved: boolean = await onSave(name);
    set_loading(false);
    if (saved)
      onClose();
  }, [name]);

  const actions = useMemo(() => <AssigmentDialogActions
    onClose={onClose}
    onSave={handleSave}
    loading={loading}
  />, [handleSave, loading]);

  if (loading)
    return (
      <LoadingDialog
        title={title}
        open={open}
        loading={loading}
        actions={actions}
      />
    );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="create-assigment-name-form-dialog">
      <DialogTitle style={{textAlign:'center'}}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{textAlign:'right'}}>
          בבקשה תן שם למשימה שלך
        </DialogContentText>
        <Grid container alignItems="flex-start">
          <TextField
            disabled={loading}
            value={name}
            onChange={event => set_name(event.target.value)}
            autoFocus
            margin="dense"
            label="שם משימה"
            type="text"
            fullWidth
          />
        </Grid>
      </DialogContent>
      {actions}
    </Dialog>
  );
};