export const projection = {
  user: [
    'username',
    'image_url_hd',
    'image_url',
    'description',
    'service_types',
    'order_types',
    'email',
    'country',
    'region',
    'full_name',
    'city',
    'phone_number',
    'company',
    'job',
    'personal_id',
    'credits',
    'coins',
    'package',
    'news_letter',
    'access_level',
    'validated',
    'blocked',
    'access_key',

    'drivers',
    'vehicles',
  ],

  drivers: [
    '_id',
    'owner',
    'username',
    'image_url_hd',
    'image_url',
    'full_name',
    'description',
    'operative_radius_from_current_location',
    'current_address_location',
    'current_geospatial_location',
    'current_location_last_update',
    'operative_locations',
    'created',
    'last_update',
    'likes',
    'views',
    'followers',
    'following',
    'active_orders',
    'active_services',
    'finished_orders',
    'finished_services',
    'canceled_orders',
    'canceled_services',
    'failed_orders',
    'failed_services',
    'rank',
    'votes',
    'stars',
    'home_country',
    'home_region',
    'home_city',
    'personal_id',
    'personal_id_frontside_link',
    'personal_id_backside_link',
    'personal_id_attachment_link',
    'birthdate',
    'licenses',
    'permits',
    'skills',
    'arrival_ability',
    'work_history',
    'workplace_radius',
    'preferred_services',
    'unacceptable_services',
    'experienced_with_vehicles',
    'cv_link',
    'traffic_violation_record',
    'orders_limit',
    'services_limit',
    'starting_price',
    'points',
    'score',
    'email',
    'phone_number',
    'phone_number2',
    'company',
    'job',
    'last_orders',
    'max_orders',
    'orders_cooldown',
    'last_services',
    'max_services',
    'services_cooldown',
    'services_limit',
    'news_letter',
    'validated',
    'blocked',
    'removed',
    'requirements',
    'questionnaire',
  ],

  vehicles: [
    '_id',
  ],
};