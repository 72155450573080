import {User} from '../../types'
import {
  save_auth_in_local_storage,
  remove_auth_from_local_storage
} from './auth_handler'
import Swal from "sweetalert2";
import style_vars from '../../assets/jss/style_vars'

export const handle_login_success = (response, set_user, set_snackbar) => {
  const user: User = response.data;
  save_auth_in_local_storage(response);
  set_user(user);
  set_snackbar({
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
    autoHideDuration: 6000,
    open: true,
    message: `Welcome ${user.full_name===null?'':' '+user.full_name}`,
    variant: "success",
  });
  return true
};

export const handle_register_success = () => {
  Swal.fire(
    'Thank you for Joining',
    'Please check your email to complete your registration',
    'success'
  );
  return true
};

export const handle_logout_success = (set_snackbar) => {
  set_snackbar({
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
    autoHideDuration: 6000,
    open: true,
    message: 'logged out successfully',
    variant: "info",
  });
};

export const handle_logout_after_refresh = (set_user, set_snackbar) => {
  remove_auth_from_local_storage();

  set_user(null);
  set_snackbar({
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
    autoHideDuration: 6000,
    open: true,
    message: 'session expired... logged out',
    variant: "warning",
  });
  return true
};

export const handle_upload_new_post_success = () => {
  Swal.fire({
    type: 'success',
    title: 'Sweet!',
    text: 'POST Upload Success',
    confirmButtonText: 'OK',
    timer: 2000,
  });
  return true
};

export const handle_new_driver_success = () => {
  Swal.fire({
    type: 'success',
    title: 'Driver Profile Uploaded',
    text: 'make sure you complete your driving profile by adding your driving license and operative locations',
    confirmButtonText: 'OK',
  });
  return true
};

export const handle_promoting_success = (username) => {
  Swal.fire({
    type: 'success',
    title: 'Congratulations',
    html:
      `<div>
         <h3>for your new blog!</h3>
          <h4>you just earned</h4>
          <h2 style="margin-bottom: 5px">5</h2>
          <h3 style="margin-top: 0px">points</h3>
          <p style="margin: auto; max-width: 250px; font-size: 13px; margin-bottom: 7px">
            <b>
              Complete the cycle by promoting:<br>
              <span style="color:${style_vars.colors.btn_red_focused}">gotrack.com/user/${username}</span><br>
              on Instagram.
            </b>
          </p>
          <p style="margin: auto; max-width: 200px; font-size:13px; color: ${style_vars.colors.btn_green}">
            And remember, you need to have at least <b>10 points</b> to get promoted.
          </p>
      </div>
    `,
    confirmButtonText: 'OK',
  });
  return true
};

export const handle_set_post_shop = () => {
  Swal.fire({
    type: 'success',
    title: 'Sweet!',
    text: 'POST Shop Setup Success',
    confirmButtonText: 'OK',
    timer: 2000,
  });
  return true
};

export const handle_is_logged_success = (set_snackbar, user) => {
  set_snackbar({
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
    autoHideDuration: 4000,
    open: true,
    message: `Welcome${!user.full_name?'':' '+user.full_name}`,
    variant: "success",
  });
  return true
};

export const handle_post_removed_success = () => {
  Swal.fire({
    type: 'success',
    title: 'Post Deleted!',
    text: 'POST Deletion Success',
    confirmButtonText: 'OK',
    timer: 2000,
  });
  return true
};