import React, {useState, useContext, useEffect} from "react";
import context from "../context";
import LoadPage from "../views/OtherPages/LoadPage";
import NoMatch from "../views/OtherPages/NoMatch";
import routes from '../routes'
import {useHistory} from 'react-router-dom'
import AppBar from '../components/navbars/AppBar'
import Footer from '../components/footers/Footer'
import PageLayout from '../components/layout/PageLayout'
import SignInToView from '../views/OtherPages/SignInToView'
import SideBar, {useSideBar} from '../components/navbars/SideBar'
import {animated} from 'react-spring'
import {useOpenSpring} from '../hooks/useOpenSpring'
import Measure from 'react-measure'
import {assert_access_level} from '../api/auth/admin'
import GenericPageMessage from '../components/popups/GenericPageMessage'


export const PublicView = ({children}) => {
  const {page_loading} = useContext(context.page_loading);

  if (page_loading)
    return <LoadPage/>;
  return children;
};


export const UserView = ({children}) => {
  const {user} = useContext(context.user);
  const {page_loading} = useContext(context.page_loading);

  if (page_loading)
    return <LoadPage/>;
  if (user === null)
    return <NoMatch/>;
  return children;
};


export const RestrictView = ({children}) => {
  const {user} = useContext(context.user);
  const {page_loading} = useContext(context.page_loading);
  const history = useHistory();

  useEffect(() => user === null && history.push(routes.home), [page_loading]);

  if (page_loading)
    return <LoadPage/>;
  if (user === null) {
    return null;
  }
  return children;
};


export const AuthPage = ({...props}) => {
  const {user} = useContext(context.user);
  const {page_loading} = useContext(context.page_loading);
  const {signin, set_signin} = useContext(context.signin);
  const {profile_complete} = useContext(context.profile_complete);
  const links = useContext(context.links);

  // const history = useHistory();

  useEffect(() => {
    if (user === null) {
      // history.push(routes.home);
      set_signin({...signin, open: true});
    }
  }, [page_loading]);


  useEffect(() => {
    if (profile_complete===false)
      links.go_to_my_account_setup(100);
  }, [profile_complete]);

  if (page_loading)
    return <Page><LoadPage/></Page>;

  if (user===null) return (
    <Page>
      <SignInToView
      head_section_title={
        'This content is available for Authorized users'
      }
      head_section_text={
        'sign in and get access in oder to view this content '
      }
    />
    </Page>
  );

  return <Page {...props}/>;
};

// export const Page = ({children=null, ...props}) => {
//   return (
//     <PageLayout {...props}>
//       {children}
//       <div style={{flexGrow: 1}}/>
//       <Footer/>
//     </PageLayout>
//   )
// };
export const Page = ({children}) => children;


export const AdminAccessPage = ({children}) => {
  const {user, set_user} = useContext(context.user);
  const {signin, set_signin} = useContext(context.signin);
  const is_logged = useContext(context.is_logged);
  const {backdrop, set_backdrop} = useContext(context.backdrop);
  const [access_granted, set_access_granted] = useState(null);

  const openSignin = () => {
    set_signin({...signin, open: true})
  };

  useEffect(() => {(async () => {
    if (is_logged) {
      set_backdrop(true);
      const res = await assert_access_level({set_user});
      set_access_granted(res);
      if (!res)
        set_user(null);
      set_backdrop(false);
    }
    else if (is_logged===false) {
      openSignin()
    }
  })()}, [is_logged]);

  if (is_logged===null)
    return (
      <GenericPageMessage onClick={openSignin}>
        validating user access...
      </GenericPageMessage>
    );

  if (!user)
    return (
      <GenericPageMessage onClick={openSignin}>
        Access Denied <br/>
        SIGN IN
      </GenericPageMessage>
    );

  if (backdrop)
    return (
      <GenericPageMessage>
        validating user access...
      </GenericPageMessage>
    );

  if (!access_granted)
    return (
      <GenericPageMessage>
        Access Denied
      </GenericPageMessage>
    );

  return children
};

export const AppPage = ({children=null, ...props}) => {
  const [size, set_size]= useState({
    width: 0,
    height: 0,
  });

  const {rtl} = useContext(context.rtl);
  const width: number = useContext(context.size).size.width;

  const reduce: number = useSideBar().width;
  const maxWidth = `calc(100% - ${reduce}px)`;
  const { animaStyle, style, set_style } = useOpenSpring({
    open: reduce > 0,
    openStyle: rtl ? {minWidth: 0, width: maxWidth, marginRight: reduce, from: {minWidth: 0, marginRight: 0}} : {minWidth: 0, width: maxWidth, marginLeft: reduce, from: {marginLeft: 0}},
    closeStyle: rtl ? {minWidth: 0, width: maxWidth, marginRight: 0, from: {minWidth: 0, marginRight: reduce}} : {minWidth: 0, width: maxWidth, marginLeft: 0, from: {marginLeft: reduce}},
  });

  useEffect(()=> {
    set_style({...style, minWidth: size.width})
  }, [size]);

  return (
    <AdminAccessPage>
      <AppBar/>
      <SideBar height={size.height}/>
      <animated.div style={animaStyle}>
        <Measure bounds onResize={contentRect => set_size(contentRect.bounds)}>{({ measureRef }) =>
          <PageLayout {...props} ref={measureRef} style={{maxWidth: width-reduce}}>
            {children}
          </PageLayout>
        }</Measure>
      </animated.div>
      <Footer style={{minWidth: size.width+reduce}}/>
    </AdminAccessPage>
  )
};