export const storage_ids = {
  user: 'user',
  assignments: 'assignments',
  new_assignment: 'new_assignment',
  new_assignment_start_time: 'new_assignment_start_time',
  new_assignment_end_time: 'new_assignment_end_time',
  new_assignment_from_location: 'new_assignment_from_location',
  new_assignment_to_location: 'new_assignment_to_location',
  new_assignment_services: 'new_assignment_services',
  new_assignment_selected_new_service: 'new_assignment_selected_new_service',
  new_assignment_steps: 'new_assignment_steps',
  new_assignment_notes: 'new_assignment_notes',
  new_assignment_distribution_points: 'new_assignment_distribution_points',
  new_assignment_new_distribution_point: 'new_assignment_new_distribution_point',
  rtl: 'rtl',
  notifications: 'notifications',
  messages: 'messages',
  signin: 'signin',
  language: 'language',
  sidebar: 'sidebar',
  my_orders: 'my_orders',
};

export default storage_ids;