import request from 'axios'
import { DriverIdApiCall } from '../../types'
import {auth_url} from '../utils/urls'
import {auth_config} from '../utils/configs'
import {useAuthCall} from '../utils/auth_calls'
import {useContext} from "react";
import type {User} from "../../types";
import context from '../../context'


export const useRemoveDriver = ():DriverIdApiCall => {
  return useAuthCall(async ({driver_id}): boolean => {
    await request.post(`${auth_url}/driver/${driver_id}/remove`, {}, auth_config);
    return true;
  });
};

export const useUnremoveDriver = ():DriverIdApiCall => {
  return useAuthCall(async ({driver_id}): boolean => {
    await request.post(`${auth_url}/driver/${driver_id}/unremove`, {}, auth_config);
    return true;
  });
};

export const useDeleteDriver = ():DriverIdApiCall => {
  const {user, set_user} = useContext(context.user);
  return useAuthCall(async ({driver_id}): boolean => {
    await request.post(`${auth_url}/driver/${driver_id}/delete`, {}, auth_config);
    set_user({...user, drivers: user.drivers-1});
    return true;
  });
};
