import React, {useContext} from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import context from '../../../../context'
import {user_logout} from '../../../../api/public/logout'
import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
  paper: {
    marginTop: 10,
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    fontSize: 14,
    width: 160,
  },
})(MenuItem);

export default ({isMenuOpen, anchorEl, handleMenuClose}) => {
  const {user, set_user} = useContext(context.user);
  const links = useContext(context.links);

  const onPageClick = () => {
    handleMenuClose();
    user.username
      ? links.go_to_profile(user.username)
      : links.go_to_my_account_setup()
  };

  const onAccountClick = () => {
    handleMenuClose();
    links.go_to_my_account();
  };

  const onAssetsClick = () => {
    handleMenuClose();
    links.go_to_my_assets();
  };

  const onLogoutClick = () => {
    handleMenuClose();
    user_logout(set_user)
  };

  const onAboutClick = () => {
    handleMenuClose();
    links.go_to_about();
  };

  const onTermsClick = () => {
    handleMenuClose();
    links.go_to_terms();
  };

  const onPrivacyClick = () => {
    handleMenuClose();
    links.go_to_privacy();
  };

  const onContactClick = () => {
    handleMenuClose();
    links.go_to_contact();
  };

  return (
    <StyledMenu
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <StyledMenuItem onClick={onPageClick}>
        My Profile
      </StyledMenuItem>
      <StyledMenuItem onClick={onAccountClick}>
        My Account
      </StyledMenuItem>
      <StyledMenuItem onClick={onAssetsClick}>
        My Assets
      </StyledMenuItem>
      <StyledMenuItem onClick={onLogoutClick}>
        Logout
      </StyledMenuItem>

      <Divider style={{margin:10}}/>

      <StyledMenuItem onClick={onAboutClick}>
        About
      </StyledMenuItem>
      <StyledMenuItem onClick={onTermsClick}>
        Terms of use
      </StyledMenuItem>
      <StyledMenuItem onClick={onPrivacyClick}>
        Privacy Policy
      </StyledMenuItem>
      <StyledMenuItem onClick={onContactClick}>
        Contact
      </StyledMenuItem>
    </StyledMenu>
  )
}