import React, {useContext, useEffect, useState} from "react";
import {Formik, Form} from 'formik';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid';
import sleep from "../../../utils/sleep";
import ids from "../../../ids";
import type {User} from "../../../types";
import {validate} from './licences/validate'
import {useStyles} from './licences/styles'
import Slide from '@material-ui/core/Slide';
import Tooltip from '../../Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Typography from "@material-ui/core/Typography";
import AddLicense from './licences/AddLicense'
import License from './licences/License'
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import type {License as LicenseType, Function} from '../../../types'
import {useEditDriverLicense} from '../../../api/auth/drivers'
import {handle_new_driver_success} from "../../../api/utils/success";
import Swal from 'sweetalert2'
import context from "../../../context";

export default ({template, onSuccess, open, set_formProps}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {set_backdrop} = useContext(context.backdrop);

  const edit_license:Function = useEditDriverLicense();

  const [loading, set_loading] = useState(false);
  const [errors, set_errors] = useState({});
  const [license_list, set_license_list] = useState([]);

  const maxWidth = mobile ? 280 : 400;

  const [onLoad, set_onLoad] = useState(true);

  const [licenses, set_licenses] = useState(
    template && template.licenses
      ? template.licenses
      : {}
      );

  const [last_license, set_last_license] = useState(null);

  const validate_licenses = ():string => {
    const new_errors = validate({licenses});
    set_errors(new_errors);
    return !new_errors.licenses;
  };

  const onSubmit = async (values, {setSubmitting}) => {

    if (!validate_licenses()) {
      setSubmitting(false);
      return;
    }


    if (template.driver_created === false) {
      setSubmitting(false);
      return Swal.fire({
        type: 'warning',
        title: 'Cannot Update Driver License',
        text: 'make sure you complete your driving profile in step 1 by adding your driver\'s full name',
        confirmButtonText: 'OK',
      });
    }
    // let res;

    // license_list.forEach(item => {
    //   const license = item.license;
    //   edit_license({
    //     driver_id: template._id,
    //     license: {
    //
    //     }
    //   })
    // });

    //   res = await create_driver({
    //     full_name: values.full_name,
    //     description,
    //   });
    //   console.log('image', image);
    //   if (!!res) {
    //     handle_new_driver_success();
    //
    //     if (image.blob !== null) {
    //       const {image_url_hd, image_url} = await edit_photo({driver_id: res._id, file:image.blob});
    //       res.image_url_hd = image_url_hd;
    //       res.image_url = image_url;
    //     }
    //     else if (image.url !== null && typeof image.url === 'string') {
    //       if (image.url.startsWith('https://')) {
    //         const {image_url_hd, image_url} = await edit_photo_url({
    //           driver_id: res._id,
    //           url: image.url,
    //         });
    //         res.image_url_hd = image_url_hd;
    //         res.image_url = image_url;
    //       } else if (image.url.startsWith('data:')) {
    //         //  TODO: maybe handle this... might be difficult
    //       }
    //     }
    //
    //     set_user({...user, drivers:user.drivers+1});
    //   }
    // }
    // else {
    //   res = await edit_profile({
    //     full_name: values.full_name,
    //     description,
    //   });
    // }
    // setSubmitting(false);
    // if (!!res) {
    //   set_template({...template, driver_created: true, ...res});
      onSuccess();
    // }
  };

  useEffect(() => {
    if (onLoad)
      return set_onLoad(false);
    validate_licenses()
  }, [licenses]);

  useEffect(() => {
    const license_list = Object.keys(licenses);
    if (license_list.length > 0)
      set_last_license(licenses[license_list[license_list.length-1]]);
    else
      set_last_license(null);
  }, [licenses]);

  useEffect(() => {
    set_license_list(
      Array.from(
        Object.keys(licenses).map((license_type) => ({
          license: licenses[license_type],
          remove_license: async () => {
            set_backdrop(true);
            const update = {...licenses};
            delete update[license_type];
            set_licenses(update);
            set_backdrop(false);
            return true;
          },
          update_license: async (license, new_license: LicenseType) => {
            set_backdrop(true);
            const update = {...licenses};
            delete update[license_type];
            update[new_license.type] = new_license;
            set_licenses(update);
            set_backdrop(false);
            return true;
          },
        }))
      )
    )
  }, [licenses]);

  return (
    <Formik
      initialValues={{}}
      validate={()=>({})}
      onSubmit={onSubmit}
    >{({submitForm, isSubmitting, values, setFieldValue, handleChange, handleBlur}) => {

      const classes = useStyles();

      useEffect(() => {
        set_formProps({submitForm, isSubmitting});
      }, [submitForm, isSubmitting]);

      useEffect(() => {
        const root = document.getElementById(ids.new_driver_profile_form_root);
        open
          ? root.className = classes.open
          : root.className = classes.close;
      }, [open]);

      return (
        <div id={ids.new_driver_profile_form_root} style={{width:'100%', transition: 'max-height 0.5s ease-in-out'}}>
          <Grid container justify='center'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form style={{paddingTop: 10, width: maxWidth}}>

              <div style={{width:'100%', height:0, position:'relative'}}>
                <span style={{position:'absolute', left:0}}>
                  <Tooltip title={
                    <p style={{paddingLeft:10, paddingRight:10, maxWidth:250, fontSize:14, fontWeight:300}}>
                      upload your driving license<br/>
                      get validated<br/>
                      &<br/>
                      get driving<br/>
                    </p>
                  }>
                    <InfoIcon fontSize='small'/>
                  </Tooltip>
                </span>
              </div>

              <Slide direction="right" timeout={1200} in={open} mountOnEnter unmountOnExit>
                <div style={{marginTop:15}}>
                  {license_list.map(({license, remove_license, update_license}, index) =>
                    <License
                      key={index}
                      open={open}
                      license={license}
                      remove_license={remove_license}
                      update_license={update_license}
                    />
                  )}

                  <div style={{height:25}}/>

                  <AddLicense
                    template={template}
                    last_license={last_license}
                    licenses={licenses}
                    set_licenses={set_licenses}
                  />

                  <div style={{height:25}}/>

                  <div style={{maxWidth:200}}>
                    {Object.keys(errors).map((error, index) =>
                      <div key={index} style={{color:'red', marginBottom:10, fontSize:12}}>
                        {errors[error]}
                      </div>
                    )}
                  </div>
                  {Object.keys(errors).length > 0 && <div style={{height:25}}/>}
                </div>
              </Slide>

            </Form>
            </MuiPickersUtilsProvider>
          </Grid>
        </div>
      )
    }}</Formik>
  )
};