import request from 'axios'
import {auth_url} from '../utils/urls'
import {auth_config} from '../utils/configs'
import {useAuthCall, useSilentAuthCall} from '../utils/auth_calls'


export const useUsersCount = () => useAuthCall(async () => {
  return (await request.get(auth_url+'/users/count', auth_config)).data;
});

export const useGetUsers = () => useAuthCall(async ({
  page_number, items_per_page,
  search, projection, sort
}) => {
  const res = await request.get(auth_url+'/users', {...auth_config, params: {
    skip: (page_number-1)*items_per_page,
    limit: items_per_page,
    search,
    projection: JSON.stringify(projection),
    sort,
  }});

  // return {
  //   data: res.data.data,
  //   next: res.data.next,
  // };
  return res.data.data;
});

export const useGetUser = () => useAuthCall(async ({user_id, projection}) => {
  return (await request.get(`${auth_url}/user/${user_id}`, {...auth_config, params: {
    projection: JSON.stringify(projection),
  }})).data;
});

export const useCreateUser = () => useAuthCall(async ({data}) => {
  return (await request.post(`${auth_url}/users`, {data}, auth_config)).data;
});

export const useSetUser = () => useAuthCall(async ({user_id, data}) => {
  return (await request.patch(`${auth_url}/user/${user_id}`, {data}, auth_config)).data;
});

export const useEditUserPhoto = () => useSilentAuthCall(async ({user_id, file}) => {
  const data = new FormData();
  data.append('file', file);
  await request.post(`${auth_url}/user/${user_id}/photo/edit`, data, auth_config);
  return true;
});