import React, {useContext, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "../Tooltip";
import {BlueButton, GreenButton, GreyButton, OrangeButton, RedButton, PurpleButton} from "../custom/CustomButtons/Buttons";
import {useAssignmentsStyles} from "./styles";
import context from "../../context";


export const ResetAction = ({onReset}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  return (
    <Tooltip title={'אפס הגדרות משימה'}>
      <GreyButton onClick={onReset} className={classes.new_assignment_actions}>
        איפוס
      </GreyButton>
    </Tooltip>
  )
};


export const CancelAction = ({onCancel}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  return (
    <Tooltip title={'אני מתחרט, לא רוצה ליצור משימה חדשה'}>
      <RedButton onClick={onCancel} className={classes.new_assignment_actions}>
        בטל פעולה
      </RedButton>
    </Tooltip>
  )
};

export const DeleteAssignmentAction = ({onRemove}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  return (
    <Tooltip title={'מחק את המשימה לצמיתות'}>
      <RedButton onClick={onRemove} className={classes.new_assignment_actions}>
        מחק משימה
      </RedButton>
    </Tooltip>
  )
};

export const CopyAndCreateNewAssignmentAction = ({onCopy}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  return (
    <Tooltip title={'צור משימה חדשה ע"י שכפול משימה קיימת'}>
      <PurpleButton onClick={onCopy} className={classes.new_assignment_actions}>
        שכפל משימה
      </PurpleButton>
    </Tooltip>
  )
};

export const CreateAssignmentAction = ({onCreateAssignment}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  return (
    <Tooltip title={'הוסף את המשימה לרשימת המשימות שלך, אח"כ תוכל ליצור הזמנות מתוך הרשימה'}>
      <BlueButton onClick={onCreateAssignment} className={classes.new_assignment_actions}>
        שמור משימה
      </BlueButton>
    </Tooltip>
  )
};

export const UpdateAssignmentAction = ({onUpdateAssignment}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  return (
    <Tooltip title={'עדכן את פרטי המשימה שלך'}>
      <BlueButton onClick={onUpdateAssignment} className={classes.new_assignment_actions}>
        עדכן משימה
      </BlueButton>
    </Tooltip>
  )
};

export const CreateOrderAction = ({onCreateOrder}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  const [loading, set_loading] = useState(false);

  const handleClick = async () => {
    set_loading(true);
    await onCreateOrder();
    set_loading(false);
  };

  return (
    <Tooltip title={'צור משימה חדשה ופרסם הזמנה למשימה שלך'}>
      <GreenButton disabled={loading} onClick={handleClick} className={classes.new_assignment_actions}>
        פרסם הזמנה
      </GreenButton>
    </Tooltip>
  )
};


export const FindAmatchAction = ({onFindAmatch, ...props}) => {
  const classes = useAssignmentsStyles(useContext(context.rtl));
  return (
    <Tooltip title={'חפש ספק שירות שעונה על דרישות המשימה'}>
      <OrangeButton onClick={onFindAmatch} className={classes.new_assignment_actions} {...props}>
        חפש התאמה
      </OrangeButton>
    </Tooltip>
  )
};


export const AssignmentActions = ({children=null, ...props}) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" {...props}>
      {React.Children.map(children, (child, index) =>
        <Zoom in={true} timeout={500+index*200}>
          <div key={index}>
            {child}
          </div>
        </Zoom>
      )}
    </Grid>
  )
};