export const text = {
  eng: {
    title: 'Edit User',
    title_driver: 'New Driver',
    title_edit_driver: 'Edit Driver',
    title_edit_driver_licenses: 'Edit Driver Licenses',
    title_vehicle: 'New Vehicle',
    title_drivers: 'Drivers',
    title_vehicles: 'Vehicles',
    title_new_user: 'New User',

    loading: 'Please wait...',
    loaded_failed: 'Could not fetch data',

    data: {
      username: 'username',
      image_url_hd: 'user image',
      description: 'description',
      service_types: 'supplying services',
      order_types: 'requiring services',
      email: 'email',
      password: 'password',
      country: 'country',
      region: 'region',
      city: 'city',
      full_name: 'full name',
      phone_number: 'phone number',
      company: 'company',
      job: 'company role',
      personal_id: 'personal id',
      credits: 'credits',
      coins: 'coins',
      package: 'package',
      news_letter: 'news letter',
      access_level: 'access level',
      validated: 'validated',
      blocked: 'blocked',
      access_key: 'access key',
    },

    submit: 'Update User',
    submit_new_user: 'Create New User',
    submit_driver: 'Update Driver',

    update_success: {
      title: 'Sweet!',
      text: 'user updated',
      confirmButtonText: 'OK'
    },
    update_driver_success: 'driver updated',

    add_driver: 'Add Driver',
    add_driver_success: {
      title: 'Sweet!',
      text: 'added new driver',
      confirmButtonText: 'OK'
    },

    add_vehicle: 'Add Vehicle',
    add_vehicle_success: {
      title: 'Sweet!',
      text: 'added new vehicle',
      confirmButtonText: 'OK'
    },

    tabs: (data) => ([`(${data.drivers}) drivers`, `(${data.vehicles}) vehicles`]),

    driver: {
      _id: ' id',
      owner: 'owner',
      username: 'username',
      image_url_hd: 'driver image',
      image_url: 'small image',
      full_name: 'full name',
      description: 'description',
      operative_radius_from_current_location: 'operative radius from current location',
      current_address_location: 'current address location',
      current_geospatial_location: 'current geospatial location',
      current_location_last_update: 'current location last update',
      operative_locations: 'operative locations',
      created: 'created',
      last_update: 'last update',
      likes: 'likes',
      views: 'views',
      followers: 'followers',
      following: 'following',
      active_orders: 'active orders',
      active_services: 'active services',
      finished_orders: 'finished orders',
      finished_services: 'finished services',
      canceled_orders: 'canceled orders',
      canceled_services: 'canceled services',
      failed_orders: 'failed orders',
      failed_services: 'failed services',
      rank: 'rank',
      votes: 'votes',
      stars: 'stars',
      home_country: 'home country',
      home_region: 'home region',
      home_city: 'home city',
      personal_id: 'personal id',
      personal_id_frontside_link: 'personal id frontside picture',
      personal_id_backside_link: 'personal id backside picture',
      personal_id_attachment_link: 'personal id attachment picture',
      birthdate: 'birthdate',
      licenses: 'licenses',
      permits: 'permits',
      skills: 'skills',
      arrival_ability: 'arrival ability',
      work_history: 'work history',
      workplace_radius: 'workplace radius',
      prefered_services: 'prefered services',
      unacceptable_services: 'unacceptable services',
      experienced_with_vehicles: 'experienced with vehicles',
      cv_link: 'cv',
      traffic_violation_record: 'traffic violation record',
      orders_limit: 'orders limit',
      services_limit: 'services limit',
      starting_price: 'starting price',
      points: 'points',
      score: 'score',
      email: 'email',
      phone_number: 'phone number',
      phone_number2: 'phone number2',
      company: 'company',
      job: 'company role',
      last_orders: 'last orders',
      max_orders: 'max orders',
      orders_cooldown: 'orders cooldown',
      last_services: 'last services',
      max_services: 'max services',
      services_cooldown: 'services cooldown',
      news_letter: 'newsletter',
      validated: 'validated',
      blocked: 'blocked',
      removed: 'removed',
    },

    driver_actions: {
      title: 'Select an action',
      edit: 'edit',
      create: 'create',
      block: 'block',
      remove: 'remove',
    },

    msg: {
      error: {
        title: 'Oops...',
        confirmButtonText: 'OK',
        username_required: 'you need a username to proceed',
        email_required: 'you need a email to proceed',
        password_required: 'you need a password to proceed',
        country_required: 'you need a country to proceed',
        full_name_required: 'you need a full name to proceed',
        phone_number_required: 'you need a phone number to proceed',
      }
    },

  },

  heb: {
    title: 'ערוך משתמש',
    title_driver: 'נהג חדש',
    title_edit_driver: 'עריכת נהג',
    title_edit_driver_licenses: 'עריכת רישיונות נהג',
    title_vehicle: 'רכב חדש',
    title_drivers: 'נהגים',
    title_vehicles: 'רכבים',
    title_new_user: 'משתמש חדש',

    loading: 'רק רגע...',
    loaded_failed: 'הבקשה נכשלה',

    data: {
      username: 'שם משתמש',
      image_url_hd: 'תמונת משתמש',
      description: 'תיאור',
      service_types: 'מספק שירותים',
      order_types: 'מקבל שירותים',
      email: 'אימייל',
      password: 'סיסמא',
      country: 'ארץ',
      region: 'אזור',
      city: 'עיר',
      full_name: 'שם מלא',
      phone_number: 'מספר טלפון',
      company: 'חברה',
      job: 'תפקיד בחברה',
      personal_id: 'תעודת זהות',
      credits: 'קרדיט',
      coins: 'מטבעות',
      package: 'סוג מנוי',
      news_letter: 'מקבל הודעות',
      access_level: 'הרשאה',
      validated: 'נבדק ואושר',
      blocked: 'חסום',
      access_key: 'מפתח גישה',
    },

    submit: 'עדכן משתמש',
    submit_new_user: 'צור משתמש חדש',
    submit_driver: 'עדכן נהג',

    update_success: {
      title: 'מצוין!',
      text: 'המשתמש עודכן בהצלחה',
      confirmButtonText: 'אוקיי'
    },
    update_driver_success: 'הנהג עודכן בהצלחה',

    add_driver: 'הוסף נהג',
    add_driver_success: {
      title: 'מצוין!',
      text: 'נהג נרשם בהצלחה',
      confirmButtonText: 'אוקיי'
    },

    add_vehicle: 'הוסף רכב',
    add_vehicle_success: {
      title: 'מצוין!',
      text: 'רכב נרשם בהצלחה',
      confirmButtonText: 'אוקיי'
    },

    tabs: (data) => ([`(${data.drivers}) נהגים`, `(${data.vehicles}) רכבים`]),

    driver: {
      _id: 'מס זיהוי',
      owner: 'תחת בעלות',
      username: 'שייך למשתמש',
      image_url_hd: 'תמונת נהג',
      image_url: 'תמונה קטנה',
      full_name: 'שם מלא',
      description: 'תיאור',
      operative_radius_from_current_location: 'מרחק תפעולי ממיקום נוכחי',
      current_address_location: 'כתובת מיקום נוכחי',
      current_geospatial_location: 'מיקום נוכחי',
      current_location_last_update: 'עדכון מיקום אחרון',
      operative_locations: 'אזורים תפעולים',
      created: 'נוצר',
      last_update: 'עדכון אחרון',
      likes: 'לייקים',
      views: 'צפיות',
      followers: 'עוקבים',
      following: 'עוקב',
      active_orders: 'הזמנות פתוחות',
      active_services: 'שירותים פתוחים',
      finished_orders: 'הזמנות סגורות',
      finished_services: 'שירותים סגורים',
      canceled_orders: 'הזמנות שבוטלו',
      canceled_services: 'שירותים שבוטלו',
      failed_orders: 'הזמנות שנכשלו',
      failed_services: 'שירותים כושלים',
      rank: 'דירוג',
      votes: 'הצבעות',
      stars: 'כוכבים',
      home_country: 'ארץ מגורים',
      home_region: 'אזור מגורים',
      home_city: 'עיר מגורים',
      personal_id: 'תעודת זהות',
      personal_id_frontside_link: 'תמונת תעודת זהות קדמית',
      personal_id_backside_link: 'תמונת תעודת זהות אחורית',
      personal_id_attachment_link: 'תמונת ספח תעודת זהות',
      birthdate: 'תאריך לידה',
      licenses: 'רשיונות',
      permits: 'היתרים',
      skills: 'כישורים',
      arrival_ability: 'יכולת הגעה',
      work_history: 'עבודות קודמות',
      workplace_radius: 'מרחק מגורים לעבודה',
      prefered_services: 'שירותים מועדפים',
      unacceptable_services: 'שירותים לא נתמכים',
      experienced_with_vehicles: 'ניסיון עם כלי רכב',
      cv_link: 'קורות חיים',
      traffic_violation_record: 'דוח תעבורה',
      orders_limit: 'מגבלת הזמנות',
      services_limit: 'מגבלת שירותים',
      starting_price: 'מחיר התחלתי',
      points: 'נקודות',
      score: 'ציון',
      email: 'אימייל',
      phone_number: 'מס טלפון',
      phone_number2: 'טלפון נוסף',
      company: 'חברה',
      job: 'תפקיד בחברה',
      last_orders: 'הזמנות אחרונות',
      max_orders: 'מס הזמנות מקסימלי',
      orders_cooldown: 'זמן מנוחה בין הזמנות',
      last_services: 'שירותים אחרונים',
      max_services: 'מס שירותים מקסימלי',
      services_cooldown: 'זמן מנוחה בין שירותים',
      news_letter: 'מקבל הודעות',
      validated: 'מאושר',
      blocked: 'חסום',
      removed: 'נמחק',
    },

    driver_actions: {
      title: 'בחר פעולה',
      edit: 'ערוך',
      create: 'צור חדש',
      block: 'חסום',
      remove: 'מחק',
    },

    msg: {
      error: {
        title: 'אופס...',
        confirmButtonText: 'הבנתי',
        username_required: 'אתה צריך שם משתמש על מנת להמשיך',
        email_required: 'אתה צריך אימייל על מנת להמשיך',
        password_required: 'אתה צריך סיסמא על מנת להמשיך',
        country_required: 'אתה צריך ארץ על מנת להמשיך',
        full_name_required: 'אתה צריך שם מלא על מנת להמשיך',
        phone_number_required: 'אתה צריך מס טלפון על מנת להמשיך',
      }
    },
  },
};