import {useEffect, useContext} from 'react'
import context from '../context'
import {is_logged} from "../api/public/login"

export default () => {
  const { user, set_user } = useContext(context.user);
  const { set_snackbar } = useContext(context.snackbar);
  const { page_loading, set_page_loading } = useContext(context.page_loading);
  const { set_is_logged } = useContext(context.user);

  // code to run on component mount
  useEffect(() => {(async () => {
    try {
      set_is_logged(await is_logged(user, set_user, set_snackbar)!==false)
    }
    catch (e) {
      console.log(e)
    }
    if (page_loading)
      set_page_loading(false);
  })()}, []);
}
