import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import {colors} from './style_vars'

// A custom theme for this app

export const theme_data = {
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: colors.background,
    },
    default: {
      main: colors.default,
    }
  },
};

export const default_theme = createMuiTheme(theme_data);
