// import React, {useContext, useEffect, createRef, useState, forwardRef} from "react";
// import context from '../../context'
// import {Page} from '../../components/view_wrappers'
// import SignInToView from '../OtherPages/SignInToView'
// import MaterialTable from 'material-table';
// import Typography from '@material-ui/core/Typography';
// import AddBox from '@material-ui/icons/AddBox';
// import ArrowDownward from '@material-ui/icons/ArrowDownward';
// import Check from '@material-ui/icons/Check';
// import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import ChevronRight from '@material-ui/icons/ChevronRight';
// import Clear from '@material-ui/icons/Clear';
// import DeleteOutline from '@material-ui/icons/DeleteOutline';
// import Edit from '@material-ui/icons/Edit';
// import FilterList from '@material-ui/icons/FilterList';
// import FirstPage from '@material-ui/icons/FirstPage';
// import LastPage from '@material-ui/icons/LastPage';
// import Remove from '@material-ui/icons/Remove';
// import SaveAlt from '@material-ui/icons/SaveAlt';
// import Search from '@material-ui/icons/Search';
// import ViewColumn from '@material-ui/icons/ViewColumn';
// import Refresh from '@material-ui/icons/Refresh';
//
// import { makeStyles } from '@material-ui/core/styles';
// import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
// import useTheme from "@material-ui/core/styles/useTheme";
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     padding: '20px 30px 20px 20px',
//     // mobile
//     [theme.breakpoints.down('xs')]: {
//       padding: 0,
//     },
//   },
//   paper: {
//     // width: '100%',
//     // marginBottom: theme.spacing(2),
//     paddingLeft: 10,
//     paddingRight: 10,
//     background: '#ffffff',
//     // border: '1px solid #AAA',
//   },
//   table: {
//     minWidth: 750,
//   },
//   visuallyHidden: {
//     border: 0,
//     clip: 'rect(0 0 0 0)',
//     height: 1,
//     margin: -1,
//     overflow: 'hidden',
//     padding: 0,
//     position: 'absolute',
//     top: 20,
//     width: 1,
//   },
//   mobile_title: {
//     // textAlign: 'center',
//     paddingLeft: 20,
//     paddingTop: 20,
//     paddingBottom: 20,
//     background: '#ffffff',
//     margin: 0,
//   },
// }));
//
// const title = 'Search term selection';
//
// const tableIcons = {
//   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//   Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//   DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//   Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//   Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
//   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//   ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//   ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
//   Retry: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
// };
//
// const picture = () => {
//   return {
//     title: 'Picture',
//     field: 'picture',
//     render: rowData => (
//       <img
//         style={{ height: 150}}
//         src={rowData.picture}
//       />
//     ),
//   }
// };
//
// export default function MaterialTableDemo() {
//   const links = useContext(context.links);
//   const theme = useTheme();
//   const mobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const {user} = useContext(context.user);
//   const {profile_complete} = useContext(context.profile_complete);
//   const classes = useStyles();
//   const tableRef = createRef();
//   const [selectedRow, setSelectedRow] = useState(null);
//   const [table, set_table] = useState({
//     // columns: [
//     //   { title: 'Name', field: 'name' },
//     //   { title: 'Surname', field: 'surname' },
//     //   { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
//     //   {
//     //     title: 'Birth Place',
//     //     field: 'birthCity',
//     //     lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
//     //   },
//     // ],
//     columns: [
//       {
//         title: 'Avatar',
//         field: 'avatar',
//         render: rowData => (
//           <img
//             style={{ height: 36, borderRadius: '50%' }}
//             src={rowData.avatar}
//           />
//         ),
//       },
//       { title: 'Id', field: 'id' },
//       { title: 'First Name', field: 'first_name' },
//       { title: 'Last Name', field: 'last_name' },
//     ],
//
//     data: [
//       { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
//       {
//         name: 'Zerya Betül',
//         surname: 'Baran',
//         birthYear: 2017,
//         birthCity: 34,
//       },
//     ],
//   });
//
//   const options = {
//     exportButton: true,
//     selection: true,
//     // filtering: true,
//     headerStyle: {
//       backgroundColor: 'rgb(255, 226, 236)',
//       color: '#ff4d4d'
//     },
//     rowStyle: rowData => ({
//       backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
//     }),
//   };
//
//   const actions = [
//     {
//       icon: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
//       tooltip: 'Refresh',
//       isFreeAction: true,
//       onClick: () => tableRef.current && tableRef.current.onQueryChange(),
//     },
//
//     {
//       tooltip: 'Remove All Selected Items',
//       icon: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//       onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
//     },
//
//   ];
//
//   const fetch_data = query =>
//     new Promise((resolve, reject) => {
//       let url = 'https://reqres.in/api/users?'
//       url += 'per_page=' + query.pageSize
//       url += '&page=' + (query.page + 1)
//       fetch(url)
//         .then(response => response.json())
//         .then(result => {
//           resolve({
//             data: result.data,
//             page: result.page - 1,
//             totalCount: result.total,
//           })
//         })
//     });
//
//   const onSelectionChange = (rows) => {
//     // alert('You selected ' + rows.length + ' rows')
//   };
//
//   useEffect(() => {
//     if (profile_complete===false)
//       links.go_to_my_account_setup(100);
//   }, [profile_complete]);
//
//   if (user===null) return (
//     <SignInToView
//       head_section_title='Create Assignments'
//       head_section_text={'customize your demands and profile unique assignments with prerequisites'}
//     />
//   );
//
//   return (
//     <Page>
//       <div className={classes.root}>
//         {mobile &&
//         <Typography variant="h5" gutterBottom className={classes.mobile_title}>
//           {title}
//         </Typography>
//         }
//         <MaterialTable
//           title={mobile ? '' : title}
//           tableRef={tableRef}
//           icons={tableIcons}
//           options={options}
//           columns={table.columns}
//           actions={actions}
//           onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
//           onSelectionChange={onSelectionChange}
//
//           components={{
//             Container: props =>
//               <div className={classes.paper}>
//                 {props.children}
//               </div>,
//             //Container: React.Fragment
//           }}
//
//           data={query =>
//             new Promise((resolve, reject) => {
//               let url = 'https://reqres.in/api/users?'
//               url += 'per_page=' + query.pageSize
//               url += '&page=' + (query.page + 1)
//               fetch(url)
//                 .then(response => response.json())
//                 .then(result => {
//                   resolve({
//                     data: result.data,
//                     page: result.page - 1,
//                     totalCount: result.total,
//                   })
//                 })
//             })
//           }
//
//           // data={table.data}
//
//           // components={{
//           //   Action: props =>
//           //     <Checkbox
//           //       indeterminate={numSelected > 0 && numSelected < rowCount}
//           //       checked={rowCount > 0 && numSelected === rowCount}
//           //       onChange={onSelectAllClick}
//           //       inputProps={{ 'aria-label': 'select all desserts' }}
//           //     />
//           // }}
//
//           editable={{
//             onRowAdd: (newData) =>
//               new Promise((resolve) => {
//                 setTimeout(() => {
//                   resolve();
//                   set_table((prevState) => {
//                     const data = [...prevState.data];
//                     data.push(newData);
//                     return { ...prevState, data };
//                   });
//                 }, 600);
//               }),
//             // onRowUpdate: (newData, oldData) =>
//             //   new Promise((resolve) => {
//             //     setTimeout(() => {
//             //       resolve();
//             //       if (oldData) {
//             //         set_table((prevState) => {
//             //           const data = [...prevState.data];
//             //           data[data.indexOf(oldData)] = newData;
//             //           return { ...prevState, data };
//             //         });
//             //       }
//             //     }, 600);
//             //   }),
//             // onRowDelete: (oldData) =>
//             //   new Promise((resolve) => {
//             //     setTimeout(() => {
//             //       resolve();
//             //       set_table((prevState) => {
//             //         const data = [...prevState.data];
//             //         data.splice(data.indexOf(oldData), 1);
//             //         return { ...prevState, data };
//             //       });
//             //     }, 600);
//             //   }),
//           }}
//
//           // detailPanel={[
//           //   {
//           //     tooltip: 'Show Name',
//           //     render: rowData => {
//           //       return (
//           //         <div
//           //           style={{
//           //             fontSize: 100,
//           //             textAlign: 'center',
//           //             color: 'white',
//           //             backgroundColor: '#43A047',
//           //           }}
//           //         >
//           //           {rowData.name}
//           //         </div>
//           //       )
//           //     },
//           //   },
//           //   {
//           //     icon: 'account_circle',
//           //     tooltip: 'Show Surname',
//           //     render: rowData => {
//           //       return (
//           //         <div
//           //           style={{
//           //             fontSize: 100,
//           //             textAlign: 'center',
//           //             color: 'white',
//           //             backgroundColor: '#E53935',
//           //           }}
//           //         >
//           //           {rowData.surname}
//           //         </div>
//           //       )
//           //     },
//           //   },
//           //   {
//           //     icon: 'favorite_border',
//           //     openIcon: 'favorite',
//           //     tooltip: 'Show Both',
//           //     render: rowData => {
//           //       return (
//           //         <div
//           //           style={{
//           //             fontSize: 100,
//           //             textAlign: 'center',
//           //             color: 'white',
//           //             backgroundColor: '#FDD835',
//           //           }}
//           //         >
//           //           {rowData.name} {rowData.surname}
//           //         </div>
//           //       )
//           //     },
//           //   },
//           // ]}
//         />
//       </div>
//     </Page>
//   );
// }

import React, {useContext} from 'react'
import context from "../../context";
import {useAssignmentsStyles} from "../../components/assignments/styles";
import {useStoreStorage} from "../../hooks/useStore";
import storage_ids from "../../storage_ids";
import SignInToView from "../OtherPages/SignInToView";
import {AuthPage} from "../../wrappers/view_wrappers";
import {NewAssignment} from "../../components/assignments/NewAssignment";
import {CoolTitle} from "../../components/CoolTitle";
import Typography from "@material-ui/core/Typography";
import {title} from "../../components/assignments/variables";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade/Fade";
import {Assignment} from "../../components/assignments/Assignment";
import {useMyOrdersStore} from '../../stores/useOrdersStores'

export const Order = ({data}) => {
  return (
    <div>{data._id}</div>
  )
};

export default () => {
  // const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const classes = useAssignmentsStyles(useContext(context.rtl));
  const orders = useMyOrdersStore(state => state.orders);

  return (
    <AuthPage>
      <div className={classes.root}>

        <CoolTitle className={classes.assignment_title}>
          <Typography variant="h5" gutterBottom className={classes.title_text2}>
            ההזמנות שלי
          </Typography>
        </CoolTitle>

        <Grid container justify={'flex-start'}>
          {Object.values(orders).map((data, index)=>
            <Fade in={true} timeout={300+index*200}>
              <Order key={data._id} data={data}/>
            </Fade>
          )}
        </Grid>
      </div>
    </AuthPage>
  );
}