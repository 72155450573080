import React, {useEffect, useState, useContext} from "react";
import {Formik, Form, Field} from 'formik';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import FaceIcon from '@material-ui/icons/Face';
import Tooltip from '../../Tooltip';
import sleep from "../../../utils/sleep";
import {Input} from '../../custom/CustomInput/FormikInput'
import Avatar from "../../custom/CustomUpload/Avatar";
import ids from "../../../ids";
import type {Function, User, Driver} from "../../../types";
import placeholder_avatar from '../../../assets/images/avatars/driver-svgrepo-com.svg'
import CustomInput from "../../custom/CustomInput/CustomInput";
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import {useNewDriver, useEditDriverPhoto, useEditDriver, useEditDriverPhotoUrl} from '../../../api/auth/drivers'
import {NewDriverFormProps} from '../../../interface'
import {handle_new_driver_success} from "../../../api/utils/success";
import context from '../../../context'

export const useStyles = makeStyles({
  close: {maxHeight: 0},
  open: {maxHeight: 10000},
});

interface Values {
  full_name: string;
  country: string;
  region: string;
  city: string;
  phone: string;
  company: string;
  job: string;
}

const validate = values => {
  const errors: Partial<Values> = {};

  if (!values.full_name)
    errors.full_name = 'Required';

  return errors;
};

export default (props:NewDriverFormProps) => {
  const template = props.template;
  const set_template = props.set_template;
  const onSuccess = props.onSuccess;
  const open = props.open;
  const set_formProps = props.set_formProps;

  const {user, set_user} = useContext(context.user);

  const theme = useTheme();
  const mobile: boolean = useMediaQuery(theme.breakpoints.down('xs'));

  const create_driver: Function = useNewDriver();
  const edit_photo: Function = useEditDriverPhoto();
  const edit_profile: Function = useEditDriver();
  const edit_photo_url: Function = useEditDriverPhotoUrl();

  const [image, set_image] = useState(template.image_url_hd
    ? {url: template.image_url_hd, blob: null}
    : {url: null, blob: null}
  );
  const [description, set_description] = useState(template.description?template.description:'');

  const maxWidth = mobile ? 280 : 400;

  const initialValues = {
    full_name: template.full_name?template.full_name:'',
  };

  const onSubmit = async (values, {setSubmitting}) => {
    let res;
    if (template.driver_created === false) {
      res = await create_driver({
        full_name: values.full_name,
        description,
      });

      if (!!res) {
        handle_new_driver_success();

        if (image.blob !== null) {
          const {image_url_hd, image_url} = await edit_photo({driver_id: res._id, file:image.blob});
          res.image_url_hd = image_url_hd;
          res.image_url = image_url;
        }
        else if (image.url !== null && typeof image.url === 'string') {
          if (image.url.startsWith('https://')) {
            const {image_url_hd, image_url} = await edit_photo_url({
              driver_id: res._id,
              url: image.url,
            });
            res.image_url_hd = image_url_hd;
            res.image_url = image_url;
          } else if (image.url.startsWith('data:')) {
          //  TODO: maybe handle this... might be difficult
          }
        }

        set_user({...user, drivers:user.drivers+1});
      }
    }
    else {
      res = await edit_profile({
        driver_id: template._id,
        full_name: values.full_name,
        description,
      });
    }
    setSubmitting(false);
    if (!!res) {
      set_template({...template, driver_created: true, ...res});
      onSuccess();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >{({submitForm, isSubmitting, values, setFieldValue, handleChange, handleBlur}) => {

      const classes = useStyles();

      useEffect(() => {
        set_formProps({submitForm, isSubmitting});
      }, [submitForm, isSubmitting]);

      useEffect(() => {
        const root = document.getElementById(ids.new_driver_profile_form_root);
        open
          ? root.className = classes.open
          : root.className = classes.close;
      }, [open]);

      return (
        <div id={ids.new_driver_profile_form_root} style={{width:'100%', transition: 'max-height 0.5s ease-in-out'}}>

          <div style={{width:'100%', height:0, position:'relative'}}>
            <span style={{position:'absolute', left:0}}>
              <Tooltip title={
                <div style={{paddingLeft:10, paddingRight:10, maxWidth:250, fontSize:14, fontWeight:300}}>
                  <p>
                    setup your driver profile<br/>
                  </p>
                  <p>
                    other users will be able<br/>
                    to view your profile photo<br/>
                    and about section
                  </p>
                  <p>
                    as well as your<br/>
                    operating locations<br/>
                    and current location.
                  </p>
                </div>
              }>
                <InfoIcon fontSize='small'/>
              </Tooltip>
            </span>
          </div>

          <Avatar
            id={ids.upload_profile_photo}
            direction='down'
            timeout={1500}
            open={open}
            justify='center'
            image={image.url ? image.url : placeholder_avatar}
            onImage={(url: string, blob: Blob) => {
              set_image({url, blob});
              if (template.driver_created === true) {
                edit_photo({driver_id: template._id, file:blob});
              }
            }}
          />

          <Grid container justify='center'>
            <Form style={{paddingTop: 10, width: maxWidth}}>
              <Slide direction="right" timeout={1000} in={open} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={'fill in your first and last name'}>
                      <FaceIcon style={{color: 'rgb(203, 139, 201)'}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="full name*"
                      name="full_name"
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Slide direction="right" timeout={1000} in={open} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item style={{position: 'relative', bottom: 55}}>
                    <Tooltip
                      title={
                        <div>
                          tell us about yourself<br/>
                          your clients want to know you better
                        </div>
                      }
                    >
                      <InfoIcon style={{color: 'rgb(124,133,203)'}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <CustomInput
                      id='about-me'
                      labelText="tell us about yourself"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: description,
                        onChange: event => {
                          event.target.value.length < 501
                          && set_description(event.target.value)
                        },
                        multiline: true,
                        rows: 3
                      }}
                    />
                  </Grid>
                </Grid>
              </Slide>
            </Form>
          </Grid>
        </div>
      )
    }}</Formik>
  )
};