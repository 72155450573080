import React, {useState} from "react";
import {addDays} from "../../../utils/clock";
import {DateTimePicker} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import EventIcon from "@material-ui/icons/Event";

export const FilledDateTime = ({id, label, value, onChange, ...props}) => {
  const [minDate] = useState(new Date());
  const [maxDate] = useState(addDays(minDate, 365));
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <DateTimePicker
        // autoOk={true}
        clearable={true}
        // onError={console.log}
        minDate={minDate}
        maxDate={maxDate}
        inputVariant="filled"
        format="dd/MM/yyyy hh:mm a"
        id={id}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{'aria-label': 'change date'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </>
  )
};