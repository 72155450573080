import React, {useContext} from "react";
import Grid from '@material-ui/core/Grid'
import context from "../../context";
import {useStyles} from '../../assets/jss/useEditUserStyles'
import {NewUserForm} from '../../components/pages/UserNew/NewUserForm'


export default () => {
  const width: number = useContext(context.size).size.width;
  const classes = useStyles();

  return (
   <div className={classes.root} style={{maxWidth: width}}>
     <Grid container justify={'flex-start'} direction={'column'}>
       <NewUserForm/>
     </Grid>
   </div>
  )
}