import React, {useContext} from "react";
import CardAvatar from "../cards/CardAvatar";
import avatar_placeholder from '../../assets/images/icons/avatar_placeholder.jpg'
import {boxShadow} from "../../assets/jss/material-dashboard-pro-react";
import {makeStyles} from "@material-ui/core/styles";
import context from '../../context'

const gravatar_size = 150;
const avatar_size = { width: gravatar_size, height: gravatar_size, cursor: 'pointer' };

const useStyles = makeStyles({
  photo: {
    transition: "all 300ms linear",
    overflow: "hidden",
    float: "left",
    zIndex: "5",
    borderRadius: "50%",
    ...boxShadow
  },
  avatarImg: {
    width: "100%",
    verticalAlign: "middle",
    border: "0"
  },
  MyAvatar: {
    WebkitFontSmoothing: 'antialiased',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    boxSizing: 'inherit',
    padding: '0',
    overflow: 'hidden',
    boxShadow: '0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    margin: '0px auto',
    width: gravatar_size,
    height: gravatar_size,
  },
});

export const AppBarAvatar = ({gravatar_link}) => {
  const classes = useStyles();
  const size = 34;
  return (
    <div className={classes.photo} style={{width:size,height:size}}>
      <img src={gravatar_link} className={classes.avatarImg} alt="..." />
    </div>
  )
};

export const SmallAvatar = ({gravatar_link, username, size}) => {
  const classes = useStyles();
  const links = useContext(context.links);
  return (
    <div className={classes.photo} style={{width:size,height:size}}>
      <img onClick={()=>links.go_to_profile(username)} src={gravatar_link} className={classes.avatarImg} alt="..." />
    </div>
  )
};

export const MyAvatar = ({image_url_hd, username, ...props}) => {
  const links = useContext(context.links);
  const onClick = () => username?links.go_to_instagram(username):()=>{};
  const classes = useStyles();
  return (
    <div className={classes.MyAvatar} {...props}>
      <img
        onClick={onClick}
        src={image_url_hd?image_url_hd:avatar_placeholder}
        style={avatar_size}
        alt="profile pic"
      />
    </div>
  );
};

export const UserAvatar = ({gravatar_link, username}) => {
  const links = useContext(context.links);
  return (
    <CardAvatar profile>
      <img onClick={()=>links.go_to_profile(username)} src={gravatar_link} alt="..." style={avatar_size} />
    </CardAvatar>
  );
};