import React, {useContext} from "react";
import context from "../../../context";
import {fetchStore, useEditDriverStore} from "../../../stores/useEditDriverStore";
import Grid from "@material-ui/core/Grid";
import Submit from "../../custom/CustomButtons/Submit";
import {text} from "../../../variables/text/UserEdit";
import type {Language} from "../../../types";
import {useParams} from 'react-router-dom'

export const UpdateDriverAction = () => {
  const language: Language = useContext(context.language).language;
  const updating = useEditDriverStore(fetchStore.updating);
  const onSubmit = useEditDriverStore(fetchStore.useSetDriver)();
  return (
    <Grid item>
      <Submit style={{width: 200}} loading={updating} onSubmit={onSubmit} >
        {text[language.value].submit_driver}
      </Submit>
    </Grid>
  )
};

export const EditUserAction = () => {
  const language: Language = useContext(context.language).language;
  const links = useContext(context.links);
  const updating = useEditDriverStore(fetchStore.updating);
  const {user_id} = useParams();
  return (
    <Grid item>
      <Submit style={{width: 200}} loading={updating} onSubmit={()=>links.go_to_edit_user(user_id)} color={'orange'} >
        {text[language.value].title}
      </Submit>
    </Grid>
  )
};