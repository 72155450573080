import React, {useEffect, useState, useContext} from "react";
import {Formik, Form, Field} from 'formik';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid';
import flag from '../../assets/images/icons/flag.svg'
import city from '../../assets/images/icons/city.svg'
import region_icon from '../../assets/images/icons/region.svg'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import GradientButton from '../custom/CustomButtons/GradientButton'
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import FaceIcon from '@material-ui/icons/Face';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {useSpring, animated} from 'react-spring'
import Tooltip from '../Tooltip';
import BusinessIcon from '@material-ui/icons/Business';
import {LinearProgress} from '@material-ui/core';
import {account_setup, edit_photo as edit_photo_api} from '../../api/auth/account'
import sleep from "../../utils/sleep";
import context from '../../context'
import Swal from 'sweetalert2';
import {Input} from '../custom/CustomInput/FormikInput'
import {edit_account_info} from '../../api/auth/account'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "../custom/CustomUpload/Avatar";
import ids from "../../ids";
import type {Function, User, Driver} from "../../types";
import DialogActions from '@material-ui/core/DialogActions';
import placeholder_avatar from '../../assets/images/avatars/driver-svgrepo-com.svg'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Save from "../custom/CustomButtons/Save";
import Cancel from "../custom/CustomButtons/Cancel";
import CustomInput from "../custom/CustomInput/CustomInput";
import Edit from "../custom/CustomButtons/Edit";
import NewDriverForm from '../forms/new_driver/Profile'
import DriverSteps from '../steps/DriverSteps'
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

interface Values {
  full_name: string;
  country: string;
  state: string;
  city: string;
  phone: string;
  company: string;
  job: string;
}

const validate = values => {
  const errors: Partial<Values> = {};

  if (!values.full_name)
    errors.full_name = 'Required';

  return errors;
};

export default ({open, set_open, template, set_template}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const links = useContext(context.links);
  const user: User = useContext(context.user).user;
  const set_user: Function = useContext(context.user).set_user;

  const [actions, set_actions] = useState(null);
  const [stepProps, set_stepProps] = useState(null);

  const handleClose = () => set_open(false);

  const Actions = () => {
    if (stepProps === null)
      return null;

    const {
      isSubmitting,
      submitForm,
      activeStep,
      steps,
      classes,
      handleReset,
      handleBack,
      allStepsCompleted,
    } = stepProps;

    if (allStepsCompleted())
      return (
        <div style={{width:'100%'}}>
          <DialogActions style={{padding: 0, paddingTop: 10}}>
            <Grid container justify='center'>
              <Typography style={{width:'100%', textAlign:'center'}}>
                Your Driver is ready
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={links.go_to_new_orders}
                className={classes.button}
              >
                Let's Drive
              </Button>
            </Grid>
          </DialogActions>
          <div style={{marginTop:20, minHeight:4, width:'100%'}}>
            {isSubmitting && <LinearProgress style={{marginTop:20}}/>}
          </div>
        </div>
      );

    return (
      <div style={{width:'100%'}}>
        <DialogActions style={{padding: 0, paddingTop: 10, paddingRight: 20}}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            Back
          </Button>
          <Button
            disabled={isSubmitting}
            variant="contained"
            color="primary"
            onClick={submitForm}
            className={classes.button}
          >
            {activeStep === steps.length-1 ? 'Submit' : 'Next'}
          </Button>

        </DialogActions>
        <div style={{marginTop:20, minHeight:4, width:'100%'}}>
          {isSubmitting && <LinearProgress style={{marginTop:20}}/>}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={mobile}
      style={{zIndex:1111}}
      // PaperProps={{ style: mobile ? {paddingLeft:0, paddingRight: 0} : {} }}
    >
      <IconButton
        color="default"
        aria-label="cancel"
        style={{position:'absolute', right: 25, top: mobile ? 20 :15, zIndex: 1200}}
        onClick={handleClose}
      >
        <CancelIcon/>
      </IconButton>

      <DialogContent style={mobile?{paddingLeft:0, paddingRight: 0}:{}}>
        <DriverSteps set_stepProps={set_stepProps} template={template} set_template={set_template}/>
      </DialogContent>

      <Actions/>
    </Dialog>
  )
};