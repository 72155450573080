import {useStoreStorage} from "../../hooks/useStore";
import storage_ids from "../../storage_ids";
import Grid from "@material-ui/core/Grid";
import CommentIcon from "@material-ui/icons/Comment";
import TextField from "@material-ui/core/TextField/TextField";
import React from "react";

export const Notes = () => {
  const [notes, set_notes] = useStoreStorage(storage_ids.new_assignment_notes,'');
  return (
    <div style={{marginBottom: 20}}>
      <Grid container justify='flex-start' alignItems='center'>
        <CommentIcon/>
        <div style={{fontSize: 20, padding: '0 10px'}}>הערות:</div>
      </Grid>
      <div style={{fontSize: 16, padding: '10px 32px'}}>
        <TextField
          fullWidth={true}
          multiline
          rowsMax={8}
          value={notes}
          onChange={event=>set_notes(event.target.value)}
        />
      </div>
    </div>
  )
};